import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPhoneAlt, FaEnvelope, FaClock } from "react-icons/fa";

const Contact = () => {
  const googleMapsUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.727433339723!2d77.53809697330252!3d12.925232015896768!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3e3674eb788b%3A0x90e613651cf9b0a7!2sMake%20My%20Documents%E2%84%A2%20%7C%20Visa%20%7C%20Passport%20%7C%20Pan%20Card%20%7C%20Senior%20Citizen%20Card%20%7C%20Insurance%20%7C%20PCC%20%7C%20PCV!5e0!3m2!1sen!2sin!4v1734347576342!5m2!1sen!2sin`;

  return (
    <div className="py-5" style={styles.container}>
      <div className="container" style={{ marginTop: "6%" }}>
        <div className="row">
          {/* Google Map */}
          <div className="col-md-6 mb-4">
            <iframe
              width="100%"
              height="400"
              style={{ borderRadius: "10px" }}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              src={googleMapsUrl}
              allowFullScreen
              title="Google Map"
            ></iframe>
          </div>

          {/* Contact Details */}
          <div className="col-md-6 mb-1 d-flex flex-column justify-content-center" style={{marginTop:'-16%'}}>
     

      {/* Mobile */}
      <div className="d-flex align-items-center mb-4">
        <FaPhoneAlt style={{ fontSize: "20px", marginRight: "15px", color: "#1A76D8" }} />
        <p className="mb-0">
         +91 942 969 0973
        </p>
      </div>

      {/* Email */}
      <div className="d-flex align-items-center mb-4">
        <FaEnvelope style={{ fontSize: "20px", marginRight: "15px", color: "#1A76D8" }} />
        <p className="mb-0">
           support@makemydocuments.com
        </p>
      </div>

      {/* Timings */}
      <div className="d-flex align-items-center mb-4">
        <FaClock style={{ fontSize: "20px", marginRight: "15px", color: "#1A76D8" }} />
        <p className="mb-0" style={{ whiteSpace: "nowrap" }}>
          <strong>Mon - Fri:</strong> 10am - 05pm <strong>Sat:</strong> 10am - 01pm
        </p>
      </div>
    </div>
        </div>
      </div>
      <br/>
    </div>

   
  );
};

const styles = {
  container: {
    background: "#FEA858", 
    color: "black",
  },
};

export default Contact;
