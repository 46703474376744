import React, { useState } from 'react';
import './InsurancePage.css'; 
import checklistIcon from '../images/notebook.svg';
import vehiclesIcon from '../images/four_wheeler_image.png';
import circleIcon from '../images/circle1.svg'; 
import documentsIcon from '../images/documents.svg'; 
import howIcon from '../images/how.svg'; 
import thirdImage from '../images/third.svg'; 
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';


const stateData = [
  {
    state: "Andhra Pradesh",
    districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
  },
  {
    state: "Arunachal Pradesh",
    districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
  },
  {
    state: "Assam",
    districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
  },
  {
    state: "Bihar",
    districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  },
  {
    state: "Chhattisgarh",
    districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
  },
  {
    state: "Goa",
    districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  },
  {
    state: "Haryana",
    districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
  },
  {
    state: "Himachal Pradesh",
    districts: ["Shimla", "Manali", "Kullu", "Kangra"],
  },
  {
    state: "Jharkhand",
    districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
  },
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },
  {
    state: "Kerala",
    districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
  },
  {
    state: "Madhya Pradesh",
    districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
  },
  {
    state: "Maharashtra",
    districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  },
  {
    state: "Manipur",
    districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
  },
  {
    state: "Meghalaya",
    districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
  },
  {
    state: "Mizoram",
    districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
  },
  {
    state: "Nagaland",
    districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
  },
  {
    state: "Odisha",
    districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
  },
  {
    state: "Punjab",
    districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
  },
  {
    state: "Rajasthan",
    districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
  },
  {
    state: "Sikkim",
    districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
  },
  {
    state: "Tamil Nadu",
    districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
  },
  {
    state: "Telangana",
    districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
  },
  {
    state: "Tripura",
    districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
  },
  {
    state: "Uttar Pradesh",
    districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
  },
  {
    state: "Uttarakhand",
    districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
  },
  {
    state: "West Bengal",
    districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
  },
  {
    state: "Andaman and Nicobar Islands",
    districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
  },
  {
    state: "Chandigarh",
    districts: ["Chandigarh"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    districts: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Lakshadweep",
    districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
  },
  {
    state: "Delhi",
    districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
  },
  {
    state: "Puducherry",
    districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  },
];

const FourWheeler = () => {

  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');

const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');

const [selectedState, setSelectedState] = useState('');

const [userData, setUserData] = useState(null); 
const [error, setError] = useState("");
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };


    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
 
    const [policyOption, setPolicyOption] = useState("");
    const [policyOptionError, setPolicyOptionError] = useState("");
    
    const [registrationNumber, setRegistrationNumber] = useState("");
    const [registrationNumberError, setRegistrationNumberError] = useState("");
    
    const [registrationDate, setRegistrationDate] = useState("");
    const [registrationDateError, setRegistrationDateError] = useState("");
    const [fullNameError, setFullNameError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [emailIdError, setEmailIdError] = useState("");
    const [villageTownCityError, setVillageTownCityError] = useState("");
    const [selectedStateError, setSelectedStateError] = useState("");
    const [selectedDistrictError, setSelectedDistrictError] = useState("");
    const handleStateChange = (e) => {
      const state = e.target.value;
      setSelectedState(state);
      setSelectedDistrict(""); 
      setSelectedStateError(""); 
      setSelectedDistrictError(""); 
    };
    const handleDistrictChange = (e) => {
      setSelectedDistrict(e.target.value);
      setSelectedDistrictError(""); 
    };
    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };

    const selectedStateData = stateData.find(
      (stateObj) => stateObj.state === selectedState
    );
    const districts = selectedStateData ? selectedStateData.districts : [];
    const [isResending, setIsResending] = useState(false);
    const [applyingfor, setApplyingFor]=useState('');
    // Navigate steps
    const nextStep = () => {
      if (currentStep < 3) setCurrentStep(currentStep + 1);
    };
  
    const prevStep = () => {
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };
    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };

    const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
    
    const handleSendOtp = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
          mobilenumber: formattedNumber,
        });
    
        if (response.status === 200) {
          console.log("API Response:", response.data);
          if (response.data.status === "success") {
            // Remove the alert and just set the state
            setOtpSent(true);
            setResendCountdown(30); // Reset countdown to 30 seconds
          } else {
            alert(response.data.message || "Error sending OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      }
    };
    
    React.useEffect(() => {
      if (resendCountdown > 0 && otpSent) {
        const timer = setInterval(() => {
          setResendCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else if (resendCountdown === 0) {
        setOtpSent(false); // Reset otpSent when countdown reaches zero
      }
    }, [resendCountdown, otpSent]);


    
    const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          setUserData(response.data.data[0]); // Save the first record from the response
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const handleVerify = async () => {
      try {
          let formattedNumber = mobileNumber.trim();
  
          // Validate the mobile number format (10 digits, no country code check here)
          if (!/^\d{10}$/.test(formattedNumber)) {
              alert("Please enter a valid 10-digit mobile number.");
              return;
          }
  
          // Add country code (only if needed)
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
  
          // Validate OTP
          const enteredOtp = otp.join("").trim();
          if (!enteredOtp || enteredOtp.length !== 4) {
              alert("Please enter a valid 4-digit OTP.");
              return;
          }
  
          // Make the API request to verify OTP
          const response = await axios.post(
              "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
              { mobilenumber: formattedNumber, otp: enteredOtp }
          );
  
          // Handle the response
          if (response.status === 200) {
              console.log("OTP Verification Response:", response.data);
              if (response.data.status === "success") {
                  alert("OTP Verified Successfully!");
                  setShowOtpSection(false); // Hide OTP section
                  await fetchUserDetails(formattedNumber); // Fetch user details after success
                  
                  // After OTP verification, call the message API
                  await sendMessage(formattedNumber);
              } else {
                  alert(response.data.message || "Error verifying OTP.");
              }
          } else {
              throw new Error(`Unexpected response status: ${response.status}`);
          }
      } catch (error) {
          console.error("Error verifying OTP:", error);
          alert("An error occurred while verifying OTP. Please try again.");
      }
  };
  
  // Function to send message after OTP verification
  const sendMessage = async (formattedNumber) => {
      try {
          const name = fullName || "User"; // Assuming `fullName` is available in your state
          const url = `https://makemydocuments.nakshatranamahacreations.in/message-insurance.php?mobile=${formattedNumber}&name=${encodeURIComponent(name)}`;
          
          const response = await axios.get(url);
          
          if (response.status === 200) {
              console.log("Message sent successfully:", response.data);
          } else {
              console.error("Error sending message:", response.data);
          }
      } catch (error) {
          console.error("Error while sending message:", error);
          alert("An error occurred while sending the message. Please try again.");
      }
  };
    
    
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };
   
    const handlePincodeChange = (e) => setPincode(e.target.value);
const [pincodError , setPincodeError] = useState("");
    const validateForm = () => {
      let isValid = true;
    
      // Step 1: Validate policy option
      if (currentStep === 1) {
          if (!policyOption) {
              setPolicyOptionError("Please select an option");
              isValid = false;
          } else {
              setPolicyOptionError("");
          }
      }
    
      // Step 2: Validate registration details
      if (currentStep === 2) {
          if (!registrationNumber) {
              setRegistrationNumberError("Please enter registration number");
    
              isValid = false;
          } else {
              setRegistrationNumberError("");
          }
    
          if (!registrationDate) {
              setRegistrationDateError("Please enter registration date");
              isValid = false;
          } else {
              setRegistrationDateError("");
          }
      }
    
      // Step 3: Validate contact details
      if (currentStep === 3) {
          if (!fullName) {
              setFullNameError("Please enter your name");
              isValid = false;
          } else {
              setFullNameError("");
          }
    
          if (!mobileNumber) {
              setMobileNumberError("Please enter your mobile number");
              isValid = false;
          } else if (!/^[0-9]{10}$/.test(mobileNumber)) {
              setMobileNumberError("Please enter a valid 10-digit mobile number");
              isValid = false;
          } else {
              setMobileNumberError("");
          }
    
          if (!emailId) {
              setEmailIdError("Please enter your email ID");
              isValid = false;
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId)) {
              setEmailIdError("Please enter a valid email address");
              isValid = false;
          } else {
              setEmailIdError("");
          }
    
          if (!villageTownCity) {
              setVillageTownCityError("Please enter your address");
              isValid = false;
          } else {
              setVillageTownCityError("");
          }
    
          if (!selectedState) {
            setSelectedStateError("Please select a state");
            isValid = false;
        } else {
            setSelectedStateError("");
        }
        
          if (!selectedDistrict) {
              setSelectedDistrictError("Please select a district");
              isValid = false;
          } else {
              setSelectedDistrictError("");
          }
          if (!pincode) {
            setPincodeError("Please enter the Pincode");
            isValid = false;
        } else if (!/^[0-9]{6}$/.test(pincode)) { // Corrected regex for 6-digit pincode
            setPincodeError("Please enter a valid 6-digit Pincode");
            isValid = false;
        } else {
            setPincodeError("");
        }
      }
    
      return isValid;
    };

    
// Example handlers for radio buttons and inputs
const handlePolicyOptionChange = (e) => {
  setPolicyOption(e.target.value);
  setPolicyOptionError("");
};

const handleRegistrationNumberChange = (e) => {
  setRegistrationNumber(e.target.value);
  setRegistrationNumberError("");
};

const handleRegistrationDateChange = (e) => {
  setRegistrationDate(e.target.value);
  setRegistrationDateError("");
};


    const [selectedDistrict, setSelectedDistrict] = useState("");
    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        applyingfor: applyingfor || "",
        registration_date : registrationDate || "",
        insurance_registration_number :registrationNumber ||"",
        email: emailId || "",
        services: selectedOption || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        paidamount: "0.00",
        // qualification: "", 
       
        gender: selectedGender || "", 
        // fathername: fatherName || "", 
        // mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "Four Wheeler Insurance",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };
  


    const faqs = [
        {
            question: "What is Car Insurance?",
            answer: "Car Insurance not only provides financial protection to the car but also provides cover for injuries to driver, passengers or pedestrians, and their property. It pays for damages to your car due to accidents, vandalism, theft, fire, man-made/ natural disasters, and third-party liability. Considering the exorbitant repair costs these days even a minor damage can cost a fortune. The Motor Vehicles Act also requires every vehicle on the road to at least have a valid third-party liability cover, i.e. cover that pays for bodily injury, loss of life and damage to property of a third person that was caused by an accident with your car.",
        },
        {
            question: "Which Documents are Required to buy a car insurance plan?",
            answer: "To buy the policy individuals would have to submit the RC book of the car, invoice of the new car or existing policy document for an old car, No claim bonus certificate of the existing policy if required.",
        },
        {
            question: "What is Covered Under car insurance?",
            answer: "The IRDAI revises premium every year, considering the ratio of claims made and loss for providers. Because of this, third party insurance rates for cars and bikes have become costly from April 1st, with the insurance rate rising up to 40%.  ",
        },
        {
            question: "What is not Covered Under car insurance?",
            answer: "Normal wear and tear and general ageing of the insured vehicle.",
        },
        {
            question: "What are the benefits of buying car insurance plans?",
            answer: "A car insurance policy is not only legally mandatory, it is beneficial too. Here’s how",
        },
        {
          question: "How much will I get paid for a car damage claim?",
          answer: `
            Once your claim is accepted, you will be paid the approved claim amount minus certain deductions which you will have to pay out from your pocket:
            
            Depreciation: Standard insurance pays for the actual cash value of your damaged or destroyed vehicle part. However, since the part was already in use, its value will be less than or depreciated in comparison to a new replacement part. This will be 30% for fibre components and 50% for plastics and rubber. If you do not want to bear the depreciation cost, you can buy a zero depreciation add-on, which is available typically for cars not older than 3 years.
            
            Standard or Voluntary Deductible: This is a fixed amount that you have to bear before the policy comes into force. For private cars, this amount currently is Rs. 1,000.
          `,
        },      
        {
          question: "What is IDV?",
          answer: (
            <div>
              <p>
                The Insured Declared Value (IDV) represents the actual sum insured of the car insurance policy. It is calculated by deducting the age-based depreciation from the market value of the car. The applicable depreciation rates depend on the age of the car, as shown in the table below:
              </p>
              <table border="1" style={{ width: '100%', borderCollapse: 'collapse', margin: '10px 0' }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Age of the car</th>
                    <th style={{ padding: '8px', textAlign: 'left' }}>Applicable depreciation</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: '8px' }}>Less than 6 months</td>
                    <td style={{ padding: '8px' }}>5%</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px' }}>More than 6 months but less than a year</td>
                    <td style={{ padding: '8px' }}>15%</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px' }}>More than a year but less than 2 years</td>
                    <td style={{ padding: '8px' }}>20%</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px' }}>More than 2 years but less than 3 years</td>
                    <td style={{ padding: '8px' }}>30%</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px' }}>More than 3 years but less than 4 years</td>
                    <td style={{ padding: '8px' }}>40%</td>
                  </tr>
                  <tr>
                    <td style={{ padding: '8px' }}>More than 4 years but less than 5 years</td>
                    <td style={{ padding: '8px' }}>50%</td>
                  </tr>
                </tbody>
              </table>
              <p>
                If the car is more than 5 years old, the IDV is mutually decided between the insurance company and the policyholder. The IDV is paid as a claim if the car is damaged beyond repair or if it is stolen.
              </p>
            </div>
          ),
        },
        
        {
            question: "For how many times can I claim for roadside assistance cover?",
            answer: "You can claim the roadside assistance cover for a maximum of four times during the entire policy period.",
        },
        {
          question:"What is CTL?",
          answer:"CTL stands for Constructive Total Loss of the car. A loss if declared to be a CTL if the estimated repair costs exceed the IDV of the policy. In such cases, the IDV is paid as claim."
        },
        {
          question:"Can the car insurance policy be transferred to another individual?",
          answer:"Yes, if you are selling your car to another individual you can also transfer the insurance policy of the car to the buyer. While the policy ownership would change, the no claim bonus would remain with you and you can use the bonus to claim a premium discount in another car insurance policy which you buy."
        }
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); 
    };


    return (
        <div
            className="container-fluid"
            style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
            }}
        >
            {/* Top Section */}
            <div className="row justify-content-start align-items-center" style={{ minHeight: '40vh' }}>
                {/* Left side: Text content */}
                <div className="col-12 col-md-6 text-left">
                    <div className="text-content">
                        <h1>Protect Your Four-Wheeler with Ease</h1>
                        <p style={{ fontWeight: '500' }}>Insurance applications made simple and stress-free.</p>
                    </div>
                    <img src={checklistIcon} alt="Checklist Icon" className="img-fluid checklist-icon" />
                </div>

                {/* Right side: Vehicles and Phone Icon */}
                <div className="col-12 col-md-6 text-center">
                    <img src={vehiclesIcon} alt="Vehicles and Phone" className="img-fluid" />
                </div>
            </div>

            {/* Content Section */}
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '14% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-25%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center">
                            <div style={{ position: 'relative' }}>
                    
                                <img
                                    src={thirdImage}
                                    alt="Third Section Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '60%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                           
                        </div>
                       
            
                    </div>
                </div>
             <>
                <div className="mb-5"   style={{
                        marginTop: '-38%',
                        marginLeft: '71%',
                    }}>
                     <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Prevents text from wrapping
        }}
    >Documents Required For Four-Wheeler Insurance</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8', whiteSpace: 'nowrap', fontSize:'bold' }}>
                    <li style={{ padding: '0px', marginBottom: '0px' }}>Registration Copy</li>
                    <li style={{ padding: '0px', marginBottom: '0px' }}>Old Policy Details If It's Renew</li>
                    </ul>
                </div>

                {/* How It Works Section */}
                <div style={{marginLeft:'72%',marginTop:'33%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  padding: '0px', marginBottom: '0px'  }}>Register Online</li>
        <li style={{  padding: '0px', marginBottom: '0px'  }}>Get Quotation Via E-mail / WhatsApp</li>
        <li style={{  padding: '0px', marginBottom: '0px'  }}>Compare Policies</li>
        <li style={{  padding: '0px', marginBottom: '0px'  }}>Make Payment</li>
        <li style={{  padding: '0px', marginBottom: '0px'  }}>Download Your Policy Instantly</li>
                    </ul>
                </div>
                </>
            </div>
     <div>
      {/* Get Quotes Button */}
      <div style={{ textAlign: "center", marginTop: "-10%" }}>
        <button
          style={{
            backgroundColor: "#FCA505",
            color: "#000000",
            padding: "12px 50px",
            border: "none",
            borderRadius: "30px",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "16px",
            marginRight:"40%",
            marginTop:"-30%",
          }}
          onClick={() => setShowPopup(true)}
        >
          Get Quotes
        </button>
      </div>

      {/* Modal Popup */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "40px",
              borderRadius: "28px",
              width: "70%",
              maxHeight: "90%", // Maximum height of the popup
              overflowY: "auto", // Enable scrolling if content overflows
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            {/* Stepper */}

            {!isCompleted ? (
              <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 1 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "60px",
                    fontWeight: "bold",
                  }}
                >
                  1
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 1</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  2
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 2</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 3 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep === 3 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  3
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 3</span> */}
              </div>
            </div>

            {/* Popup Content Based on Step */}
            <div style={{ marginBottom: "20px" }}>
            {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <p style={{ fontWeight: "600" }}>Buy / Renew Car insurance without inspection or paperwork</p>

    <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Want to
      <span style={{ color: "red" }}>*</span>
    </h4>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="buyNewPolicy"
          checked={policyOption === "buyNewPolicy"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        Buy New Policy
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="renewExistingPolicy"
          checked={policyOption === "renewExistingPolicy"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        Renew Existing Policy
      </label>
    </div>
    {policyOptionError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {policyOptionError}
        </p>
      )}
  </div>
)}

{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 2 Heading */}
    <p>Buy Insurance, the smart way</p>

    {/* Subheading */}
    <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
      Enter Your Car Details
    </h3>

    {/* Registration Input */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="registrationNumber"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Registration
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="registrationNumber"
        // onChange={handleRegistrationNumberChange}
        value={registrationNumber}
        placeholder="Enter Registration Number"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
        onChange={(e) => setRegistrationNumber(e.target.value)}
      />
      {registrationNumberError && (
        <p style={{ color: "red", fontSize: "14px" ,textAlign:'left'}}>
          {registrationNumberError}
        </p>
      )}
    </div>

    {/* Registration Date Input */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="registrationDate"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Registration Date
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        id="registrationDate"
        value={registrationDate}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
        onChange={(e) => setRegistrationDate(e.target.value)}
      />
      {registrationDateError && (
        <p style={{ color: "red", fontSize: "14px", textAlign:'left' }}>
          {registrationDateError}
        </p>
      )}
    </div>
  </div>
)}

{currentStep === 3 && (
  <div style={{ textAlign: "center" }}>
    <p>Buy Insurance, the smart way</p>

    <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
      Contact Details
    </h3>

    {/* Form Fields */}
    {/* Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="name"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Name<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="name"
        value={fullName}
        onChange={handleFullNameChange}
        placeholder="Enter Your Name"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
     
    </div>
    {fullNameError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {fullNameError}
        </p>
      )}
    {/* Mobile Number */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="mobileNumber"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Mobile Number<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="mobileNumber"
        value={mobileNumber}
        onChange={handleMobileNumberChange}
        placeholder="Enter Your Mobile Number"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    
    </div>
    {mobileNumberError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {mobileNumberError}
        </p>
      )}

    {/* Email ID */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="email"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Email ID<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="email"
        id="email"
        value={emailId}
        onChange={handleEmailIdChange}
        placeholder="Enter Your Email ID"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
     
    </div>
    {emailIdError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {emailIdError}
        </p>
      )}

    {/* Address */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="address"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Address<span style={{ color: "red" }}>*</span>
      </label>
      <textarea
        id="address"
        value={villageTownCity}
        onChange={handleVillageTownCityChange}
        placeholder="Enter Your Address"
        style={{
          width: "100%",
          height: "70px",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      />
    
    </div>
    {villageTownCityError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {villageTownCityError}
        </p>
      )}


    <div>
      {/* State Dropdown */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="state"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          State<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="state"
          value={selectedState}
          onChange={handleStateChange}
          style={{
            width: "100%",
            height: "45px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <option value="">Select State</option>
          {stateData.map((stateObj, index) => (
            <option key={index} value={stateObj.state}>
              {stateObj.state}
            </option>
          ))}
        </select>
       
      </div>
      {selectedStateError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {selectedStateError}
        </p>
      )}

      {/* District Dropdown */}
      {selectedState && (
        <div style={{ marginBottom: "20px", textAlign: "left" }}>
          <label
            htmlFor="district"
            style={{
              display: "block",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            District<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="district"
            value={selectedDistrict}
            onChange={handleDistrictChange}
            style={{
              width: "100%",
              height: "45px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <option value="">Select District</option>
            {districts.map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </select>
          {selectedDistrictError && (
            <p style={{ color: "red", fontSize: "14px" }}>{selectedDistrictError}</p>
          )}
        </div>
      )}
    </div>
    <div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="pincode"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Pin Code <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="pincode"
                            placeholder="Enter Pin Code"
                            value={pincode}
                            onChange={handlePincodeChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
    
                        {pincodError && (
        <p style={{ color: "red", fontSize: "14px"  , textAlign:'left', }} >
          {pincodError}
        </p>
      )}
  </div>
)}

            </div>

            {/* Stepper Navigation */}
            <div  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>
            <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
              {currentStep < 3 ? (
                <button
                onClick={() => {
                
                  if (!validateForm()) {
                    setError("Please fill all required fields.");
                    return;
                  }
              
                  nextStep(); 
                }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Next
                </button>
              ) : (
                <button
                onClick={() => {
                 
                  if (!validateForm()) {
                    // If form is not valid, do not proceed
                    setError("Please fill all required fields.");
                    return;
                  }
                  handleSendOtp(); 
                  finishSubmission();
                }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  SUBMIT
                </button>
              )} 
            </div>
            </>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
              {showOtpSection ? (
             <div>
             <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
               OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
             </h4>
           
             <div style={{ margin: "20px 0" }}>
               <label style={{ fontWeight: "500", marginBottom: "10px" }}>
                 Enter OTP <span style={{ color: "red" }}>*</span>
               </label>
               <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                 {otp.map((digit, index) => (
                   <input
                     key={index}
                     id={`otp-input-${index}`} // Fixed the template literal syntax
                     type="text"
                     maxLength="1"
                     value={digit}
                     onChange={(e) => handleChange(e.target.value, index)}
                     onKeyDown={(e) => handleBackspace(e, index)}
                     style={{
                       width: "50px",
                       height: "50px",
                       textAlign: "center",
                       fontSize: "18px",
                       border: "1px solid #ccc",
                       borderRadius: "5px",
                     }}
                   />
                 ))}
               </div>
               {otpSent && (
                 <h5 style={{ color: "red" }}>
                   Resend OTP in {resendCountdown} seconds
                 </h5>
               )}
               {resendCountdown === 0 && (
                 <span
                   onClick={handleSendOtp} // Make the text clickable
                   style={{
                     color: "#FFA500",
                     cursor: "pointer",
                     textDecoration: "underline",
                     marginTop: "10px",
                   }}
                 >
                   Resend OTP
                 </span>
               )}
             </div>
             <button
               onClick={handleVerify}
               style={{
                 backgroundColor: "#FFA500",
                 color: "#000",
                 border: "none",
                 borderRadius: "5px",
                 padding: "10px 20px",
                 fontSize: "16px",
                 cursor: "pointer",
               }}
             >
               VERIFY
             </button>
           </div>
              ) : (
                <div style={{ marginTop: "20px", textAlign: "center" }}>
      {/* Success Message with Checkmark */}
      <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // Optional: light green background
      }}
    >
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "#28a745",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          animation: "circleAnimation 1s ease-in-out",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          stroke="white"
          viewBox="0 0 24 24"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20 6L9 17l-5-5"
          />
        </svg>
      </div>
      {/* <span style={{ marginLeft: "15px", color: "#28a745" }}>Success!</span> */}
    </div>
    <br/>
    <p style={{fontSize:'30px'}}> Thank You!</p>
      {/* Confirmation Text */}
      <p>Your request  has been Submitted.</p>
      <p>We appreciate your interest and will get back to you as soon as possible.</p>
      {/* {redirecting && <p>Redirecting to homepage...</p>} */}
    </div>
              )}
            </div>
            )}

            {/* Close Button */}
            <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
          </div>
        </div>
      )}
    </div>

                       {/* FAQ Section */}
 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

        </div>
    );
};

export default FourWheeler;
