import React, { useState } from "react";
// import './InsurancePage.css'; 
import { Link } from 'react-router-dom';
import vehiclesIcon from '../../images/Visa_image1.svg';
import oneImage from '../../images/1.svg';
import twoImage from '../../images/2.svg';
import threeImage from '../../images/3.svg';
import visaImage2 from '../../images/Visa_image2.svg';
import '../visa/TravelVisa.css';
import singapurImage from '../../images/singapure_image.png'
import arabImage1 from "../../images/arab1stimage.png";
import ukImage from "../../images/uk-image.png"
import australiaimage from "../../images/australia_image.png"
import malysiaimage from "../../images/malaysia_image.png"
import egyptimage from "../../images/egypt_image.png"
import vietnamimage from "../../images/vietnm_image.png"
import hongkong from "../../images/hongkong_image.png"
import indonesiimage from "../../images/indonesia_image.png"
import azerbaijanimage from "../../images/azerbijan_image.png"
import omanimage from "../../images/oman_image.png"
import moroccoimage from "../../images/moroco_image.png"
import baharinimage from "../../images/baharin_image.png"
import qatarimage from "../../images/qatar_image.png"
import russiaimage from "../../images/russia_image.png"
import uzbekistanimage from "../../images/uzerbekstan_image.png"




const visadata = [
  {
    name: "United Arab Emirated",
    routeName: "dubai_visa",
    img: arabImage1, // Use the imported image variable
  },
  {
    name: "Singapore",
    routeName: "singapure_visa",
    img: singapurImage, // Use the imported image variable
  },
  {
    name: "United Kingdom",
    routeName:"uk-visa",
    img: ukImage,
  },
  {
    name: "Australia",
    routeName:"australia-visa",
    img: australiaimage,
  },
  {
    name: "Malaysia",
    routeName:"malaysia-visa",
    img: malysiaimage,
  },
  {
    name: "Egypt",
    routeName:"egypet-visa",
    img: egyptimage,
  },
  {
    name: "Vietnam",
    routeName:"vietnam-visa",
    img: vietnamimage,
  },
  {
    name: "Hong Kong",
    routeName:"hongkong-visa",
    img: hongkong,
  },
  {
    name: "Indonesia",
    routeName:"indonesia-visa",
    img: indonesiimage,
  },
  {
    name: "Azerbaijan",
    routeName:"azerbaijan-visa",
    img: azerbaijanimage,
  },
  {
    name: "OmanImage",
    routeName:"oman-visa",
    img: omanimage,
  },
  {
    name: "Morocco",
    routeName:"morocco-visa",
    img: moroccoimage,
  },
  {
    name: "Bahrain",
    routeName:"bahrain-visa",
    img: baharinimage,
  },
  {
    name: "Qatar",
    routeName:"qatar-visa",
    img: qatarimage,
  },
  {
    name: "Russia",
    routeName:"russia-visa",
    img: russiaimage,
  },
  {
    name: "Uzbekistan",
    routeName:"uzbekistan-visa",
    img: uzbekistanimage,
  },
];


const TravelVisa = () => {


  const [searchText, setSearchText] = useState("");

  // Filtered visa data based on search text
  const filteredVisadata = visadata.filter((visa) =>
    visa.name.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div
      className="container-fluid"
      style={{
        background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
        minHeight: '65vh',
        position: 'relative',
      }}
    >
   <div
  className="row justify-content-start align-items-center"
  style={{ minHeight: "100%" }}
>
  <div className="col-12 col-md-6 text-left">
    <div className="text-content">
      <h1 style={{ color: "#1A76D8" }}>We Make Visas Easy For You</h1>
      
      {/* Search Input Box */}
      <div style={{ marginTop: "20px" }}>
        <input
          type="text"
          placeholder="Search for your visa or destination..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{
            width: "100%",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "5px",
          }}
        />
      </div>
    </div>
  </div>

  <div className="col-12 col-md-6 text-center">
    <img
      src={vehiclesIcon}
      alt="Vehicles and Phone"
      className="img-fluid"
      style={{ maxWidth: "82%" }}
    />
    <img
      src={visaImage2}
      alt="Visa Image 2"
      className="img-fluid"
      style={{ marginTop: "-145px", maxWidth: "81%" }}
    />
  </div>
</div>


      {/* Destination Cards */}
      <div className="destination-cards-container row justify-content-center" style={{ marginTop: "28px" }}>
        {filteredVisadata.length > 0 ? (
          filteredVisadata.map((visa, index) => (
            <div
              key={index}
              className="destination-card col-12 col-md-4 text-center"
              style={{ position: "relative", marginBottom: "20px" }}
            >
              <Link to={`/${visa.routeName}`}>
                <img
                  src={visa.img}
                  alt={visa.name}
                  style={{ width: "100%", borderRadius: "8px" }}
                />
              </Link>
              <p style={{ marginTop: "10px", fontWeight: "bold" }}>
                {visa.name}
              </p>
            </div>
          ))
        ) : (
          <p style={{ textAlign: "center", fontSize: "18px", marginTop: "20px" }}>
            No results found.
          </p>
        )}
      </div>

      {/* Why Choose Section */}
      <div className="why-make-my-documents text-center my-10">
        <h3
          className="mb-4"
          style={{ color: '#007BFF', fontWeight: 'bold' }}
        >
          Why Make My Documents?
        </h3>
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 text-center">
            <img
              src={oneImage}
              alt="Complete Online Process"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>COMPLETE ONLINE PROCESS</h5>
            <p>Upload the documents as mentioned and leave the rest to us.</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src={twoImage}
              alt="Dedicated Team"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>DEDICATED TEAM</h5>
            <p>Prompt notifications on every stage of visa.</p>
          </div>
          <div className="col-12 col-md-4 text-center">
            <img
              src={threeImage}
              alt="99% Approval Rate"
              className="mb-3 feature-img img-fluid"
            />
            <h5 style={{ fontWeight: 'bold' }}>99% APPROVAL RATE</h5>
            <p>Based on visas processed so far.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TravelVisa;
