import React, { useEffect, useState,  } from "react";
import circleIcon from '../src/images/circle1.svg'; 
import documentsIcon from '../src/images/documents.svg'; 
import TimeIcon from '../src/images/Time.svg'; 
import Price from './images/Price Tag.svg';
import touristImage from './images/image-tourist.svg';
import visaImage from './images/Rectangle 25-visa.svg';
import { FaArrowLeft } from "react-icons/fa";
import howIcon from './images/how.svg';
import { useParams } from "react-router-dom";
import axios from "axios";
import UAEImage from "../src/images/UAE.jpg"
import Singapureimage from "../src/images/Singapore_image_banner.jpg"
import Ukimage from "../src/images/United Kingdom_image_banner.jpg"
import Australiaimage from "../src/images/Australia_image_banner.jpg"
import MalysiaImage from "../src/images/Malaysia_image_banner.jpg";
import Egyptimage from "../src/images/Egypt_image_banner.jpg";
import vietnamImage from "../src/images/Vietnam_image_banner.jpg";
import HongkongImage from "../src/images/Hong Kong_banner_image.jpg";
import IndonsiaImage from "../src/images/Indonesia_image_banner.jpg";
import azerbaijanImage from "../src/images/Azerbaijan_image_banner.jpg";
import OmanImage from "../src/images/Oman_image_banner.jpg";
import MoroccoImage from "../src/images/Morocco_image_banner.jpg";
import BaharinImage from "../src/images/Bahrain_image_banner.jpg";
import QatarIamage from "../src/images/Qatar_image_banner.jpg";
import RussiaImage from "../src/images/Russia_image_banner.jpg";
import UzbekistanImage from "../src/images/Uzbekistan_image_banner.jpg"



const stateData = [
    {
      state: "Andhra Pradesh",
      districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
    },
    {
      state: "Arunachal Pradesh",
      districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
    },
    {
      state: "Assam",
      districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
    },
    {
      state: "Bihar",
      districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
    },
    {
      state: "Chhattisgarh",
      districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
    },
    {
      state: "Goa",
      districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
    },
    {
      state: "Gujarat",
      districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
    },
    {
      state: "Haryana",
      districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
    },
    {
      state: "Himachal Pradesh",
      districts: ["Shimla", "Manali", "Kullu", "Kangra"],
    },
    {
      state: "Jharkhand",
      districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
    },
    {
      state: "Karnataka",
      districts: ["Bagalkot",
        "Ballari (Bellary)",
        "Belagavi (Belgaum)",
        "Bengaluru (Bangalore) Rural",
       "Bengaluru (Bangalore) Urban", 
        "Bidar",
        "Chamarajanagar",
        "Chikballapur",
        "Chikkamagaluru (Chikmagalur)",
        "Chitradurga",
        "Dakshina Kannada",
        "Davangere",
        "Dharwad",
        "Gadag",
        "Hassan",
        "Haveri",
       "Kalaburagi (Gulbarga)",
        "Kodagu",
        "Kolar",
        "Koppal",
        "Mandya",
        "Mysuru (Mysore)",
        "Raichur",
        "Ramanagara",
        "Shivamogga (Shimoga)",
        "Tumakuru (Tumkur)",
        "Udupi",
       " Uttara Kannada (Karwar)",
       " Vijayapura (Bijapur)",
        "Yadgir"],
    },
    {
      state: "Kerala",
      districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
    },
    {
      state: "Madhya Pradesh",
      districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
    },
    {
      state: "Maharashtra",
      districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
    },
    {
      state: "Manipur",
      districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
    },
    {
      state: "Meghalaya",
      districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
    },
    {
      state: "Mizoram",
      districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
    },
    {
      state: "Nagaland",
      districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
    },
    {
      state: "Odisha",
      districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
    },
    {
      state: "Punjab",
      districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
    },
    {
      state: "Rajasthan",
      districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
    },
    {
      state: "Sikkim",
      districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
    },
    {
      state: "Tamil Nadu",
      districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
    },
    {
      state: "Telangana",
      districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
    },
    {
      state: "Tripura",
      districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
    },
    {
      state: "Uttar Pradesh",
      districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
    },
    {
      state: "Uttarakhand",
      districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
    },
    {
      state: "West Bengal",
      districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
    },
    {
      state: "Andaman and Nicobar Islands",
      districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
    },
    {
      state: "Chandigarh",
      districts: ["Chandigarh"],
    },
    {
      state: "Dadra and Nagar Haveli and Daman and Diu",
      districts: ["Daman", "Diu", "Silvassa"],
    },
    {
      state: "Lakshadweep",
      districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
    },
    {
      state: "Delhi",
      districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
    },
    {
      state: "Puducherry",
      districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
    },
  ];

const visadata = [
    {
      name: "United Arab Emirated",
      routeName: "dubai_visa",
      img: "arabImage1",
      bannerimage:UAEImage,
      data: [
        {
          title: "Documents Required For Visa",
          desc: `
            <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Scan clear color copy of Passport (first and last page)</li>
              <li>01 color passport-size photo</li>
              <li>PAN Card</li>
             
            </ul>
          `,
        },
        {
          title: "How It Works",
          desc: `
            <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Register Online</li>
              <li>Upload Documents</li>
              <li>Documents Verfication</li>
               <li>Payment</li>
                <li>Receive your E-Visa via E-mail</li>
            </ul>
          `,
        },
        {
          title: "Time Duration",
          desc: `
            
           <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 05 working days</li>
              
            </ul>
          `,
        },
        {
          title: "Charges",
          desc: `
            <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>UAE 30 Days Single Entry E-Visa <strong> Rs: 7854</strong></li>
              <li>UAE 30 Days Multiple Entry E-Visa  <strong> Rs: 14,600 </strong></li>
             <li>UAE 60 Days Single Entry E-Visa <strong> Rs: 12,446   </strong></li>
             <li>UAE 60 Days Multiple Entry E-Visa  <strong>  Rs: 19,118 </strong></li>
             <li><strong>Rs. 99 </strong>  as booking fee. Need to pay while submitting online form (This fee is non-refundable and will be adjusted in the total bill.)</li>
            </ul>
          `,
        },
      ],
    },
    {

        name: "Singapore",
        routeName: "singapure_visa",
        img:"singapurImage",
        bannerimage:Singapureimage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Original Passport</li>
                    <li>Visa from</li>
                    <li>Photo (3nos mate finish photo with 80% face covering and with white back ground)</li>
                    <li>Covering letter (if you’re doing own business need to mention the monthly</li>
                     <li>income , business details and letter should be in letter head )</li>
                      <li>Hotel voucher</li>
                      <li>Air Ticket</li>
                      <li>Educational certificate</li>
                      <li>Last Month's Pay slip (if your employee )</li>
                      <li>Office ID card copy.(if your employee )</li>
                      <li>Visiting Card (if your own business )</li>
                       <li>School ID card copy(if your student)</li>
                        <li>Last 3 months Bank statement</li>
                  </ul>
                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Door Step Documents Pickup & Verification</li>
                    <li>Payment</li>
                     <li>We Submit Your Documents To Embassy</li>
                      <li>Your Visa Deliver To Your Doorstep</li>
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>07 - 10 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Rs. 3,500</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {

        name: "United Kingdom",
        routeName: "uk-visa",
        img:"ukImage",
        bannerimage:Ukimage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                 <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Original passport with 06 months validity from the date of travel. + Old Passports if any.</li>
  <li>02 Colour photographs with white background, matte finish size 35 mm X 45 mm 80% face size.</li>
  <li>Covering letter mentioning your travel details and stay details while in the UK.</li>
  <li>Original Bank statement for the last 6 months updated with healthy and sufficient balance.</li>
  <li>Income tax / Form 16 for the last 3 years.</li>
  <li>Salary Slips for the last 6 months - if employed.</li>
  <li>Original Leave letter from employer/school/college.</li>
  <li>Retirement Letter if retired.</li>
  <li>School / College ID proof - if student.</li>
  <li>Other financial papers (NSC, PPF, Bonds, FD, shares, property papers, etc.)</li>
  <li>Minor travelers:</li>
  <ul>
  <li>
    a minor accompanied by one parent shall provide an original notarized NOC by the other parent,<br />
    plus copies of parents' passports or ID.
  </li>
  <li>
    a minor traveling alone shall provide an original notarized NOC by both parents/legal guardians, <br />
    plus copies of parents' passports or ID.
  </li>
</ul>

  <li>Visiting Card (if your own business)</li>
  <li>School ID card copy (if your student)</li>
  <li>Last 3 months Bank statement.</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Door Step Documents Pickup & Verification</li>
                    <li>Payment</li>
                     <li>Get Appointment</li>
                      <li>Visit For Documents Verification</li>
                       <li>Get Delivered</li>
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>15-20 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Rs. 12,499</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Australia",
        routeName: "australia-visa",
        img:"australiaimage",
        bannerimage: Australiaimage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Original passport</li>
  <li>Aadhar card</li>
  <li>02 photos with white background and no border (35mm X 45mm)</li>
  <li>Invitee passport & visa copy</li>
  <li>Invitee's one residence proof</li>
  <li>Applicant's 6 months bank statement (with stamp & signature and sufficient funds)</li>
  <li>3 months pay slips</li>
  <li>2-3 years Income Tax Return (ITR)</li>
  <li>Previous travel passport stamped copy</li>
  <li>Any investment documents like fixed deposits or property documents (optional)</li>
  <li>Flight booking confirmation (optional)</li>
  <li>Covering letter (Given by us)</li>
  <li>Company leave approval letter</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>20-25 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Rs. 10,999</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
      {

        name: "Malaysia",
        routeName: "malaysia-visa",
        img:"malysiaimage",
        bannerimage: MalysiaImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Flight and Hotel Details</li>
  <li>Flight Number</li>
  <li>Hotel Name</li>
  <li>Passport</li>
  
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>03 - 04 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Malaysia Electronic Travel Authorization Rs. 999</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This fee is non-refundable and will be adjusted in the total bill.)</li>
             
            </ul>
                `,
              },
        ]
    },
    {

        name: "Egypt",
        routeName: "egypet-visa",
        img:"egyptimage",
        bannerimage: Egyptimage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
  
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>07 - 10 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Egypt 30days Single Entry E-Visa Rs. 4,798
</li>
              <li>Egypt 30 days Multiple Entry E-Visa Rs. 6,580</li>
                <li>Rs. 99 as booking fee. Need to pay while submitting online form (This fee is non-refundable and will be adjusted in the total bill.)
</li>
             
            </ul>
                `,
              },
        ]
    },
    {

        name: "Vietnam",
        routeName:"vietnam-visa",
    img: "vietnamimage",
    bannerimage: vietnamImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Passport</li>
    <li>Traveler Photo</li>
  
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>07 - 10 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Vietnam 30 Days Singal Entry E-Visa Rs. 4,371</li>
              <li>Vietnam 30 Days Multiple Entry E-Visa Rs. 12492</li>
                <li>Vietnam 90 Days Multiple Entry E-Visa Rs. 14573</li>
                <li>Rs. 99 as booking fee. Need to pay while submitting online form (This fee is non-refundable and will be adjusted in the total bill.)</li>
            </ul>
                `,
              },
        ]
    },
    {

        name: "Hong Kong",
    routeName:"hongkong-visa",
        img:"hongkong",
        bannerimage: HongkongImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
  
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>02 - 03 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Multiple Entry 14 Days Hong Kong E-Visa Rs. 1332</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Indonesia",
        routeName:"indonesia-visa",
        img:"indonesiimage",
        bannerimage: IndonsiaImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
  <li>Hotel Address Information</li>
  <li>Return Flight Ticket</li>
  <li>Traveler Photo</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>05 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Indonesia 30 Days Single Entry E-Visa Rs. 5109</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Azerbaijan",
        routeName:"azerbaijan-visa",
        img:"azerbaijanimage",
        bannerimage: azerbaijanImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
 
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>07 - 10 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Azerbaijan 30 days Single Entry E-Visa Rs. 4498</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Oman",
        routeName:"oman-visa",
        img:"omanimage",
        bannerimage: OmanImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Traveler Photo</li>
 
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Oman 10 Day E-Visa Rs. 4,015</li>
               <li>Oman 30 Day E-Visa Rs. 7,776</li>
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Morocco",
        routeName:"morocco-visa",
        img:"moroccoimage",
        bannerimage: MoroccoImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Traveler Photo</li>
 
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Morocco 30 Days Single Entry E-Visa Rs. 9,053</li>
              
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Bahrain",
        routeName:"bahrain-visa",
        img:"baharinimage",
        bannerimage: BaharinImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Clear scanned photo with white background</li>
    <li>Flight and Hotel Details</li>
       <li>Last 3 month bank statement</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Bahrain E-Visa Rs. 3,850</li>
              
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Qatar",
    routeName:"qatar-visa",
        img:"qatarimage",
        bannerimage: QatarIamage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Clear scanned photo with white background</li>
    <li>Flight and Hotel Details</li>
       <li>Last 3 month bank statement</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Qatar E-Visa Rs. 3,850</li>
              
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Russia",
        routeName:"russia-visa",
        img:"russiaimage",
        bannerimage: RussiaImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Clear scanned photo with white background</li>
    <li>Flight and Hotel Details</li>
       <li>Last 3 month bank statement</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Russia E-Visa Rs. 3,850</li>
              
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
    {
        name: "Uzbekistan",
    routeName:"uzbekistan-visa",
        img:"uzbekistanimage",
        bannerimage: UzbekistanImage,
        data:[
            {
                title: "Documents Required For Visa",
                desc: `
                <ul
  style="
    display: grid;
    list-style-type: none; 
    padding-left: 20px;
    white-space: nowrap;
    margin: 20px 0;
  "
>
  <li>Clear scanned copy of passport front and back copy in colour scanner</li>
   <li>Clear scanned photo with white background</li>
    <li>Flight and Hotel Details</li>
       <li>Last 3 month bank statement</li>
</ul>

                `,
              },
              {
                title: "How It Works",
                desc: `
                  <ul
                    style="
                      display: grid;
                      list-style-type: none; 
                      padding-left: 20px;
                      white-space: nowrap;
                      margin: 20px 0;
                    "
                  >
                    <li>Register Online</li>
                    <li>Upload Documents</li>
                    <li>Documents Verfication</li>
                     <li>Payment</li>
                      <li>Receive your E-Visa via E-mail</li>
                    
                  </ul>
                `,
              },
              {
                title: "Time Duration",
                desc: `
                 <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>04 - 07 working days</li>
              
            </ul>
                `,
              },
              {
                title: "Charges",
                desc: `
                   <ul
              style="
                display: grid;
                list-style-type: none; 
                padding-left: 20px;
                white-space: nowrap;
                margin: 20px 0;
              "
            >
              <li>Uzbekistan E-Visa Rs. 3,850</li>
              
              <li>Rs. 99 as booking fee. Need to pay while submitting online form (This amount will be adjusted in total bill)</li>
             
            </ul>
                `,
              },
        ]
    },
  ];

 
const Services = () => {



const { services } = useParams();
  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [gender, setGender] = useState("");

const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [dateOfBirth, setDateOfBirth] = useState("");
const [travellingDate, setTravellingDate] = useState("");
const [returningDate, setReturningDate] = useState("");
const [errors, setErrors] = useState({});

const [selectedGender, setSelectedGender] = useState('');
const [selectedAffidavit, setSelectedAffidavit] = useState('');
const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');
const [selectedVisa, setSelectedVisa] = useState(visadata[0]);
const [selectedState, setSelectedState] = useState('');

  const [selectedDestination, setSelectedDestination] = useState(null);

  const handleClick = (destination) => {
    setSelectedDestination(destination);
  };
const [otpSent, setOtpSent] = useState(false);
const [userDetails, setUserData] = useState(null); // Holds user details
  
const [selectedDistrict, setSelectedDistrict] = useState("");

const [error, setError] = useState("");
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };


    
const [registrationNumber, setSeletedRegistrationNumber]=useState('');

React.useEffect(() => {
    if (resendCountdown > 0 && otpSent) {
      const timer = setInterval(() => {
        setResendCountdown((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (resendCountdown === 0) {
      setOtpSent(false); // Reset otpSent when countdown reaches zero
    }
  }, [resendCountdown, otpSent]);


    window.scrollTo({
      top: 0,
      behavior: 'smooth', 
    })
  

    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
    const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);

    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };

    const handleAffidavitChange = (e) => {
      setSelectedAffidavit(e.target.value);
      setError(''); // Clear error when a selection is made
    };
  
    const handleContinue = () => {
      if (!selectedAffidavit) {
        setError("Please select an affidavit.");
        return;
      }
      setShowPopup(true);
    };
  

    const handlePincodeChange = (e) => setPincode(e.target.value);
    // Navigate steps
    const nextStep = () => {
        let validationErrors = {};
      
        if (currentStep === 1) {
          // Step 1 Validation
          if (!fullName.trim()) {
            validationErrors.fullName = "Full Name is required.";
          }
          if (!gender) {
            validationErrors.gender = "Gender is required.";
          }
          if (!travellingDate) {
            validationErrors.travellingDate = "Travelling Date is required.";
          }
          if (!returningDate) {
            validationErrors.returningDate = "Returning Date is required.";
          }
        } else if (currentStep === 2) {
          // Step 2 Validation
          if (!mobileNumber.trim()) {
            validationErrors.mobileNumber = "Mobile Number is required.";
          } else if (!/^\d{10}$/.test(mobileNumber)) {
            validationErrors.mobileNumber = "Mobile Number must be 10 digits.";
          }
      
          if (!emailId.trim()) {
            validationErrors.emailId = "Email ID is required.";
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailId)) {
            validationErrors.emailId = "Please enter a valid Email ID.";
          }
      
          if (!villageTownCity.trim()) {
            validationErrors.villageTownCity = "Address is required.";
          }
          if (!selectedState) {
            validationErrors.selectedState = "State is required.";
          }
          if (!selectedDistrict) {
            validationErrors.selectedDistrict = "District is required.";
          }
      
          // Pincode Validation
          const pincodeRegex = /^[0-9]{6}$/; // Pincode should be exactly 6 digits.
          if (!pincode.trim()) {
            validationErrors.pincode = "Pincode is required.";
          } else if (!pincodeRegex.test(pincode)) {
            validationErrors.pincode = "Pincode must be exactly 6 digits.";
          }
        }
      
        // If there are validation errors, set them and stop navigation
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
          return;
        }
      
        // Clear errors and move to the next step
        setErrors({});
        if (currentStep < 2) {
          setCurrentStep(currentStep + 1);
        }
      };
      
      
    const prevStep = () => {
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };
    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };

    const selectedStateData = stateData.find(
        (stateObj) => stateObj.state === selectedState
      );
      const districts = selectedStateData ? selectedStateData.districts : [];
  
 

   // To track if OTP has been sent
    
    const handleSendOtp = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
          mobilenumber: formattedNumber,
        });
    
        if (response.status === 200) {
          console.log("API Response:", response.data);
          if (response.data.status === "success") {
            // Remove the alert and just set the state
            setOtpSent(true);
            setResendCountdown(30); // Reset countdown to 30 seconds
          } else {
            alert(response.data.message || "Error sending OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      }
    };
    
   

    
    const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };
   
const [isResending, setIsResending] = useState(false);
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    const [orderid, setOrderID] = useState(() => generateOrderId()); 
    
    
    // Handle Proceed to Pay API call
    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; 
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; 
      const requestBody = {
        ORDER_ID: orderid, 
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Check if payment URL is provided
          if (response.data?.paymentUrl) {
            // Redirect to payment gateway
            window.location.href = response.data.paymentUrl;
          } else {
            alert("Payment request was successful, but no redirect URL was provided.");
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };
  


    const handleVerify = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        
        // Validate the mobile number format (10 digits, no country code check here)
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        
        // Add country code (only if needed)
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        // Validate OTP
        const enteredOtp = otp.join("").trim();
        if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
          alert("Please enter a valid 4-digit OTP.");
          return;
        }
    
        // Make the API request to verify OTP
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
          { mobilenumber: formattedNumber, otp: enteredOtp }
        );
    
        // Handle the response
        if (response.status === 200) {
          console.log("OTP Verification Response:", response.data);
          if (response.data.status === "success") {
            alert("OTP Verified Successfully!");
            setShowOtpSection(false); // Hide OTP section
            await fetchUserDetails(formattedNumber); // Fetch user details after success
          } else {
            alert(response.data.message || "Error verifying OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        alert("An error occurred while verifying OTP. Please try again.");
      }
    };
    
    


const filteredData = visadata?.find((item) => item.routeName === services);


  



   
    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        email: emailId || "",
        services: selectedAffidavit || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        insurance_registration_number: registrationNumber || "",
        paidamount: "0.00",
        // qualification: "", 
        applyingfor:"",
        gender: selectedGender || "", 
        // fathername: fatherName || "", 
        // mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "Travel Visa ",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };
  


    

    if (!filteredData) {
      return <p>No data found for this service!</p>;
    }

 

 
      const faqs = [
        {
            question: "When is the best time to apply?",
            // answer: (
            //   <ul style={{ listStyleType: 'disc' }}>
            //     <li>Minimizes the conflicts between a tenant and the owner</li>
            //     <li>Rental/lease agreement acts as an address proof</li>
            //     <li>Acts as a proof for Bank loans</li>
            //     <li>Helps in investment & loan</li>
            //     <li>Vehicle registration</li>
            //   </ul>
            // ),
            answer:"Make My Documents will recommend to apply visa 20 days before of your departure.",
          },
        {
            question: "Do I need to visit any office to submit and documents?",
            answer: "No its completely online process you can share your documents through WhatsApp or Email.",
        },
        {
            question: "Do I need to share any original documents to get UAE visa?",
            answer: "No, original documents is not required for processing. Share only soft copies of the documents and get your visa.",
        },
        {
            question: "How will I get my Visa?",
            answer: "UAE issues e-visa, so we will mail/WhatsApp you once your visa is approved.",
        },
        {
            question: "What will happen if I stay beyond the validity of visa?",
            answer: "If you stay beyond your validity you will be penalized with 1000 AED.",
        },
        {
            question: "Can I cancel my Visa?",
            answer: "Cancellation can only be done till the time documents are not submitted to embassy. Post submission there is no cancellation and refund",
        },
        {
            question: "Will I get a refund if I cancel my visa?",
            answer: "If the application is not submitted to embassy and cancellation is done, we will only refund visa embassy fee.",
        },
        {
            question: "In case there is a rejection, can I get a refund?",
            answer: "No, you will not be able to cancel your visa and hence there would be no refund.",
        },
        {
            question: "Can I reapply if my visa is rejected?",
            answer: "You can re-apply,however chances of approval would not be that high. We usually don’t reprocess the applications which are rejected previously.",
        },
        {
            question: "What all documents are required at Immigration?",
            answer: "Below are the list of documents required at immigration: - Original passport. - Print out of your e-visa.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <div style={{ marginTop:'8%'}} >
<div className='headsection'>
<div
      style={{
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "55vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        overflow: "hidden", // Prevent content from overflowing the container
      }}
    >
      {/* Container for Image */}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: 1, // Keep image behind the text
        }}
      >
        <img
          src={filteredData.bannerimage} // Dynamically set image based on route
          alt="Travel Visa"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </div>

      {/* Container for Text */}
      <div
        style={{
          position: "relative",
          zIndex: 2, // Keep text on top of the image
          textAlign: "center",
          color: "#ffffff", // White text for better visibility on most backgrounds
        }}
      >
        {/* <h2
          style={{
            position: "relative", // Ensure the overlay is positioned correctly
            fontWeight: "800",
            color: "#ffffff",
            fontFamily: "Poppins",
            fontSize: "30px",
            margin: 0,
          }}
        >
          {filteredData.name} - Apply Online Tourist Visa
          <span
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "#000000",
              opacity: 0.6, // Adjust the opacity of the black overlay
              zIndex: -1, // Ensure the overlay is behind the text
            }}
          />
        </h2> */}
      </div>
    </div>
</div>



<div
  className="content-section"
  style={{
    backgroundColor: "#ffffff",
    padding: "30px 15px",
    borderRadius: "10px",
    margin: "-1% auto",
    marginRight: "72%",
  }}
>
  <div className="row justify-content-center">
    {/* Main Column for Vertical Layout */}
    <div className="col-12 col-md-8 position-relative">
      {/* First Section: Documents */}
      <div className="text-center mb-5">
        <div style={{ position: "relative" }}>
          <img src={circleIcon} alt="Circle Background" className="img-fluid" />
          <img
            src={documentsIcon}
            alt="Documents Icon"
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              justifyContent: "center",
              alignItems: "center",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        
        {/* Documents Content */}
        {filteredData.data.map((item, index) => {
          if (item.title === "Documents Required For Visa") {
            return (
              <div
                key={index}
                className="mb-5"
                style={{
                  marginTop: "-30%",
                  marginLeft: "75%",

                }}
              >
                <h4
                  style={{
                    color: "#007BFF",
                    fontWeight: "bold",
                    textAlign: "left", 
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.title}
                </h4>
                <div  style={{
        textAlign: "left", // Ensure content below the title also aligns to the left
      }} dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          }
          return null;
        })}
      </div>

      {/* Second Section: How It Works */}
      <div className="text-center mb-5">
        <div style={{ position: "relative" }}>
          <img src={circleIcon} alt="Circle Background" className="img-fluid" />
          <img
            src={howIcon}
            alt="How It Works Icon"
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        {/* How It Works Content */}
        {filteredData.data.map((item, index) => {
          if (item.title === "How It Works") {
            return (
              <div
                key={index}
                style={{ marginLeft: "80%", marginTop: "-30%" }}
              >
                <h4
                  style={{
                    color: "#007BFF",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.title}
                </h4>
                <div  style={{
        textAlign: "left", // Ensure content below the title also aligns to the left
      }} dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          }
          return null;
        })}
      </div>

      {/* Third Section: Time Duration */}
      <div className="text-center mb-5">
        <div style={{ position: "relative" }}>
          <img src={circleIcon} alt="Circle Background" className="img-fluid" />
          <img
            src={TimeIcon}
            alt="Time Duration Icon"
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        {/* Time Duration Content */}
        {filteredData.data.map((item, index) => {
          if (item.title === "Time Duration") {
            return (
              <div
                key={index}
                style={{ marginLeft: "80%", marginTop: "-25%" }}
              >
                <h4
                  style={{
                    color: "#007BFF",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.title}
                </h4>
                <div  style={{
        textAlign: "left", // Ensure content below the title also aligns to the left
      }} dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          }
          return null;
        })}
      </div>

      {/* Fourth Section: Charges */}
      <div className="text-center mb-5">
        <div style={{ position: "relative" }}>
          <img src={circleIcon} alt="Circle Background" className="img-fluid" />
          <img
            src={Price}
            alt="Charges Icon"
            style={{
              position: "absolute",
              top: "45%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        </div>
        {/* Charges Content */}
        {filteredData.data.map((item, index) => {
          if (item.title === "Charges") {
            return (
              <div
                key={index}
                style={{ marginLeft: "80%", marginTop: "-25%" }}
              >
                <h4
                  style={{
                    color: "#007BFF",
                    fontWeight: "bold",
                    whiteSpace: "nowrap",
                  }}
                >
                  {item.title}
                </h4>
                <div  style={{
        textAlign: "left", // Ensure content below the title also aligns to the left
      }}  dangerouslySetInnerHTML={{ __html: item.desc }} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </div>
  </div>
</div>

<div>   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
         position: "fixed",
         top: "100px",
         left: "0",
         width: "100%",
         height: "86%",
         backgroundColor: "rgba(26, 118, 216, 0.9)",
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 2 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        
      </div>

      
      {index < 1 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
         {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4>

    <div style={{ width: "100%", textAlign: "left" }}>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        Travelling Date<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        value={travellingDate}
        onChange={(e) => setTravellingDate(e.target.value)}
        style={{
          width: "100%",
          padding: "8px",
          marginTop: "5px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
          position: "relative",
          zIndex: 1,
        }}
      />
      {errors.travellingDate && (
        <p style={{ color: "red", fontSize: "14px" }}>{errors.travellingDate}</p>
      )}
    </div>
    <br />
    <div style={{ width: "100%", textAlign: "left" }}>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        Returning Date<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        value={returningDate}
        onChange={(e) => setReturningDate(e.target.value)}
        style={{
          width: "100%",
          padding: "8px",
          marginTop: "5px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
          position: "relative",
          zIndex: 1,
        }}
      />
      {errors.returningDate && (
        <p style={{ color: "red", fontSize: "14px" }}>{errors.returningDate}</p>
      )}
    </div>
    <br />
    <div style={{ marginBottom: "33px", textAlign: "left" }}>
      <label
        htmlFor="fullName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Name
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={fullName}
        onChange={handleFullNameChange}
        id="fullName"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
        }}
      />
      {errors.fullName && (
        <p style={{ color: "red", fontSize: "14px" }}>{errors.fullName}</p>
      )}
    </div>

    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginRight: "auto",
        width: "fit-content",
        gap: "20px",
        fontWeight: "700",
      }}
    >
      <label
        htmlFor="gender"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Gender
        <span style={{ color: "red" }}>*</span>
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="gender"
          value="Male"
          checked={gender === "Male"}
          onChange={(e) => setGender(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        Male
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="gender"
          value="Female"
          checked={gender === "Female"}
          onChange={(e) => setGender(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        Female
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="gender"
          value="Transgender"
          checked={gender === "Transgender"}
          onChange={(e) => setGender(e.target.value)}
          style={{ marginRight: "10px" }}
        />
        Transgender
      </label>
      {errors.gender && (
        <p style={{ color: "red", fontSize: "14px" }}>{errors.gender}</p>
      )}
    </div>
  </div>
)}

{currentStep === 2 && (
 <div style={{ textAlign: "center" }}>
   <p style={{ color: "#1A76D8", fontWeight: "600" }}>
     Contact Details<span style={{ color: "red" }}>*</span>
   </p>

   {/* Mobile Number */}
   <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Mobile Number<span style={{ color: "red" }}>*</span>
      </label>
      <input
        value={mobileNumber}
        onChange={handleMobileNumberChange}
        type="text"
        id="mobileNumber"
        placeholder="Enter your mobile number"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
        }}
      />
      {!mobileNumber && <p style={{ color: "red", fontSize: "14px" }}>Mobile number is required.</p>}
   </div>

   {/* Email ID */}
   <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="emailId"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Email ID<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="emailId"
        value={emailId}
        onChange={handleEmailIdChange}
        placeholder="Enter your email address"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
        }}
      />
      {!emailId && <p style={{ color: "red", fontSize: "14px" }}>Email ID is required.</p>}
   </div>

   {/* Address */}
   <div style={{ marginBottom: "20px", textAlign: "left" }}>
     <label
       style={{
         display: "block",
         marginBottom: "10px",
         fontSize: "16px",
         fontWeight: "500",
       }}
     >
       Address<span style={{ color: "red" }}>*</span>
     </label>
     <input
       type="text"
       value={villageTownCity}
       onChange={(e) => setVillageTownCity(e.target.value)}
       placeholder="Enter your address"
       style={{
         width: "100%",
         height: "45px",
         padding: "10px",
         fontSize: "16px",
         border: "2px solid #FCA505",
         borderRadius: "10px",
       }}
     />
     {!villageTownCity && <p style={{ color: "red", fontSize: "14px" }}>Address is required.</p>}
   </div>

   {/* State Selection */}
   <div style={{ marginBottom: "20px", textAlign: "left" }}>
     <label
       htmlFor="state"
       style={{
         display: "block",
         marginBottom: "10px",
         fontSize: "16px",
         fontWeight: "500",
       }}
     >
       State<span style={{ color: "red" }}>*</span>
     </label>
     <select
       id="state"
       value={selectedState}
       onChange={handleStateChange}
       style={{
         width: "100%",
         height: "45px",
         fontSize: "16px",
         border: "2px solid #FCA505",
         borderRadius: "10px",
       }}
     >
       <option value="">Select State</option>
       {stateData.map((stateObj, index) => (
         <option key={index} value={stateObj.state}>
           {stateObj.state}
         </option>
       ))}
     </select>
     {!selectedState && <p style={{ color: "red", fontSize: "14px" }}>State selection is required.</p>}
   </div>

   {/* District Selection */}
   {selectedState && (
     <div style={{ marginBottom: "20px", textAlign: "left" }}>
       <label
         htmlFor="district"
         style={{
           display: "block",
           marginBottom: "10px",
           fontSize: "16px",
           fontWeight: "500",
         }}
       >
         District<span style={{ color: "red" }}>*</span>
       </label>
       <select
         id="district"
         value={selectedDistrict}
         onChange={handleDistrictChange}
         style={{
           width: "100%",
           height: "45px",
           fontSize: "16px",
           border: "2px solid #FCA505",
           borderRadius: "10px",
         }}
       >
         <option value="">Select District</option>
         {districts.map((district, index) => (
           <option key={index} value={district}>
             {district}
           </option>
         ))}
       </select>
       {!selectedDistrict && <p style={{ color: "red", fontSize: "14px" }}>District selection is required.</p>}
     </div>
   )}

<div
                          style={{ marginBottom: "20px", textAlign: "left" }}
                        >
                          <label
                            htmlFor="pincode"
                            style={{
                              display: "block",
                              marginBottom: "10px",
                              fontSize: "16px",
                              fontWeight: "500",
                            }}
                          >
                            Pin Code <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            type="text"
                            id="pincode"
                            placeholder="Enter Pin Code"
                            value={pincode}
                            onChange={handlePincodeChange}
                            style={{
                              width: "100%",
                              height: "45px",
                              padding: "10px",
                              fontSize: "16px",
                              border: "2px solid #FCA505",
                              borderRadius: "4px",
                            }}
                          />
                        </div>
                        {!pincode && <p style={{ color: "red", fontSize: "14px" }}>Enter the Pincode</p>}
                        <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          By clicking submit, you agree to our{" "}
                          <a
                            href="/terms-conditions"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>

 </div>

)}







         </div>
         <div
         style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}>
          {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
           )}
           {currentStep < 2 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
                Next
             </button>
           ) : (
            <button
            onClick={() => {
              if (!fullName) {
                setError("Name is required.");
                return;
              }
              if (!mobileNumber) {
                setError("Mobile number is required."); 
                return;
              }
              if (!emailId) {
                setError("Email ID is required.");
                return;
              }
              if (!villageTownCity) {
                setError("Address is required.");
                return;
              }
              if (!selectedState) {
                setError("State is required.");
                return;
              }
              if (!selectedDistrict) {
                setError("District is required.");
                return;
              }
              handleSendOtp(); 
              finishSubmission();
            }}
              style={{
                padding: "10px 20px",
                backgroundColor: "#FCA505",
                color: "#000000",
                border: "none",
                alignContent:'center',
                alignItems:'center',
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              SUBMIT
            </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
           <div>
           <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
             OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
           </h4>
           <div style={{ margin: "20px 0" }}>
             <label style={{ fontWeight: "500", marginBottom: "10px" }}>
               Enter OTP <span style={{ color: "red" }}>*</span>
             </label>
             <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
               {otp.map((digit, index) => (
                 <input
                   key={index}
                   id={`otp-input-${index}`}
                   type="text"
                   maxLength="1"
                   value={digit}
                   onChange={(e) => handleChange(e.target.value, index)}
                   onKeyDown={(e) => handleBackspace(e, index)}
                   style={{
                     width: "50px",
                     height: "50px",
                     textAlign: "center",
                     fontSize: "18px",
                     border: "1px solid #ccc",
                     borderRadius: "5px",
                   }}
                 />
               ))}
             </div>
           </div>
         
           <div style={{ marginTop: "20px", textAlign: "center" }}>
         
             <p style={{ fontSize: "14px", color: "#888" }}>
                 {resendCountdown > 0 ? (
                   <>Not Received? Resend in {resendCountdown}s</>
                 ) : (
                   <a
                     href="#"
                     onClick={handleResend}
                     style={{
                       textDecoration: "none",
                       color: isResending ? "#ccc" : "#007BFF",
                       pointerEvents: isResending ? "none" : "auto",
                     }}
                   >
                     Resend OTP
                   </a>
                 )}
               </p>
           </div>
         
           <button
             onClick={handleVerify}
             style={{
               backgroundColor: "#FFA500",
               color: "#000",
               border: "none",
               borderRadius: "5px",
               padding: "10px 20px",
               fontSize: "16px",
               cursor: "pointer",
             }}
           >
             VERIFY
           </button>
         </div>
          ) : (
            <>
            <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
            <div style={styles.infoBox}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Name:</label>
                <input
                  type="text"
                  value={userDetails?.name || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
             
              <div style={styles.inputGroup}>
                <label style={styles.label}>Mobile Number:</label>
                <input
                  type="text"
                  value={userDetails?.mobilenumber || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Order ID:</label>
                <input
                  type="text"
                  value={orderid}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Services:</label>
                <input
                  type="text"
                  value="Travel Visa"
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Payment Amount:</label>
                <input
                  type="text"
                  value="₹99"
                  readOnly
                  style={styles.input}
                />
              </div>
            </div>
            <button
              onClick={handleProceedToPay}
              style={styles.proceedButton}
            >
              Proceed to Pay
            </button>
          {/* // </div> */}
          </>
          )}
        </div>
         )}

         {/* Close Button */}
         <button
                        onClick={closePopup}
                        style={{
                          position: "absolute",
                          top: "25px",
                          left: "8%",
                          background: "#000000",
                          border: "1px solid #FCA505",
                          fontSize: "20px",
                          padding: "8px", 
                          borderRadius: "5px",
                          cursor: "pointer",
                          color: "#fff", 
                        }}
                      >
                        <FaArrowLeft /> 
                      </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
        
        </div>

        
        
        </div>
    );
};

const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    padding: '8px', 
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px', 
    // marginLeft: '10px',
    width: '200px', // Set the desired width here
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default Services;

