import React, { useState } from 'react';
import Image30 from '../../images/msme-image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import TimeIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';
import howIcon from '../../images/how.svg';

const stateData = [
  {
    state: "Andhra Pradesh",
    districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
  },
  {
    state: "Arunachal Pradesh",
    districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
  },
  {
    state: "Assam",
    districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
  },
  {
    state: "Bihar",
    districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  },
  {
    state: "Chhattisgarh",
    districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
  },
  {
    state: "Goa",
    districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  },
  {
    state: "Haryana",
    districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
  },
  {
    state: "Himachal Pradesh",
    districts: ["Shimla", "Manali", "Kullu", "Kangra"],
  },
  {
    state: "Jharkhand",
    districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
  },
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },
  {
    state: "Kerala",
    districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
  },
  {
    state: "Madhya Pradesh",
    districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
  },
  {
    state: "Maharashtra",
    districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  },
  {
    state: "Manipur",
    districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
  },
  {
    state: "Meghalaya",
    districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
  },
  {
    state: "Mizoram",
    districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
  },
  {
    state: "Nagaland",
    districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
  },
  {
    state: "Odisha",
    districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
  },
  {
    state: "Punjab",
    districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
  },
  {
    state: "Rajasthan",
    districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
  },
  {
    state: "Sikkim",
    districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
  },
  {
    state: "Tamil Nadu",
    districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
  },
  {
    state: "Telangana",
    districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
  },
  {
    state: "Tripura",
    districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
  },
  {
    state: "Uttar Pradesh",
    districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
  },
  {
    state: "Uttarakhand",
    districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
  },
  {
    state: "West Bengal",
    districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
  },
  {
    state: "Andaman and Nicobar Islands",
    districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
  },
  {
    state: "Chandigarh",
    districts: ["Chandigarh"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    districts: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Lakshadweep",
    districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
  },
  {
    state: "Delhi",
    districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
  },
  {
    state: "Puducherry",
    districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  },
];

const Msme = () => {
    
   
  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');
const [errors, setErrors] = useState({});
const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');

const [selectedState, setSelectedState] = useState('');


const [error, setError] = useState("");
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };
const [registrationNumber, setSeletedRegistrationNumber]=useState('');

    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
    const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);
    const [aadhaarNumber, setAadhaarNumber] = useState("");
    const [businessName, setBusinessName] = useState("");
    const [organisationType, setOrganisationType] = useState("");
    const [dateOfIncorporation, setDateOfIncorporation] = useState("");
    const [panNumber, setPanNumber] = useState("");

    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; 
      const firstTwo = number.slice(0, 2); 
      const lastDigit = number.slice(-1);
      const masked = `${firstTwo}******${lastDigit}`; 
      return masked;
    };
    const [pinCode, setPinCode] = useState("");

    const selectedStateData = stateData.find(
      (stateObj) => stateObj.state === selectedState
    );
    const districts = selectedStateData ? selectedStateData.districts : [];


    const validateStep1 = () => {
      const errors = {};
  
      if (!fullName.trim()) {
        errors.fullName = "Name is required.";
      }
      if (!aadhaarNumber.trim()) {
        errors.aadhaarNumber = "Aadhaar number is required.";
      } else if (!/^\d{12}$/.test(aadhaarNumber)) {
        errors.aadhaarNumber = "Aadhaar number must be 12 digits.";
      }
      if (!businessName.trim()) {
        errors.businessName = "Name of Enterprise/Business is required.";
      }
      if (!dateOfIncorporation) {
        errors.dateOfIncorporation = "Date of Incorporation / Registration is required.";
      } else if (new Date(dateOfIncorporation) > new Date()) {
        errors.dateOfIncorporation = "Incorporation date cannot be in the future.";
      }
      if (!organisationType.trim()) {
        errors.organisationType = "Type of Organisation is required.";
      }
  
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };
    
    
    const validateStep2 = () => {
      const errors = {};
  
      if (!villageTownCity.trim()) {
        errors.villageTownCity = "Address is required.";
      }
      if (!selectedState.trim()) {
        errors.selectedState = "State is required.";
      }
      if (!selectedDistrict.trim()) {
        errors.selectedDistrict = "District is required.";
      }
      if (!pinCode.trim()) {
        errors.pinCode = "Pin code is required.";
      } else if (!/^\d{6}$/.test(pinCode)) {
        errors.pinCode = "Pin code must be 6 digits.";
      }
      if (!mobileNumber.trim()) {
        errors.mobileNumber = "Mobile number is required.";
      } else if (!/^\d{10}$/.test(mobileNumber)) {
        errors.mobileNumber = "Mobile number must be 10 digits.";
      }
      if (!emailId.trim()) {
        errors.emailId = "Email ID is required.";
      } else if (!/\S+@\S+\.\S+/.test(emailId)) {
        errors.emailId = "Email ID is invalid.";
      }
  
      setErrors(errors);
      return Object.keys(errors).length === 0;
    };
    
    const handleSubmit = () => {
      if (currentStep === 1 && validateStep1()) {
        handleNext(); // Proceed to the next step (Step 2)
      } else if (currentStep === 2 && validateStep2()) {
        handleSendOtp(); // Send OTP
        finishSubmission(); // Final submission after sending OTP
        alert("Form submitted successfully!");
      }
    };
    
    
  
    const handleNext = () => {
      setCurrentStep((prevStep) => prevStep + 1);
    };
    
  
    const prevStep = () => {
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };
    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };


    const [otpSent, setOtpSent] = useState(false);


    const handleSendOtp = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
          mobilenumber: formattedNumber,
        });
    
        if (response.status === 200) {
          console.log("API Response:", response.data);
          if (response.data.status === "success") {
            // Remove the alert and just set the state
            setOtpSent(true);
            setResendCountdown(30); // Reset countdown to 30 seconds
          } else {
            alert(response.data.message || "Error sending OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      }
    };
    
    React.useEffect(() => {
      if (resendCountdown > 0 && otpSent) {
        const timer = setInterval(() => {
          setResendCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else if (resendCountdown === 0) {
        setOtpSent(false); // Reset otpSent when countdown reaches zero
      }
    }, [resendCountdown, otpSent]);
 
    
     const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    
    const [userDetails, setUserData] = useState(null); // Holds user details
    const [orderid, setOrderID] = useState(() => generateOrderId()); 

    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; // Generates dynamic CUST_ID
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; // Add 350 to the existing amount
    
      const requestBody = {
        ORDER_ID: orderid, // Uses the generated order ID
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Redirect to payment gateway if payment URL is provided
          if (response.data?.paymentUrl) {
            window.location.href = response.data.paymentUrl;
          } else {
            alert(
              "Payment request was successful, but no redirect URL was provided."
            );
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };
    const [isResending, setIsResending] = useState(false);
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };
    const handleVerify = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        
        // Validate the mobile number format (10 digits, no country code check here)
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        
        // Add country code (only if needed)
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        // Validate OTP
        const enteredOtp = otp.join("").trim();
        if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
          alert("Please enter a valid 4-digit OTP.");
          return;
        }
    
        // Make the API request to verify OTP
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
          { mobilenumber: formattedNumber, otp: enteredOtp }
        );
    
        // Handle the response
        if (response.status === 200) {
          console.log("OTP Verification Response:", response.data);
          if (response.data.status === "success") {
            alert("OTP Verified Successfully!");
            setShowOtpSection(false); // Hide OTP section
            await fetchUserDetails(formattedNumber); // Fetch user details after success
          } else {
            alert(response.data.message || "Error verifying OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        alert("An error occurred while verifying OTP. Please try again.");
      }
    };
    
    

    
    const [selectedDistrict, setSelectedDistrict] = useState("");

    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        email: emailId || "",
        services: selectedOption || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        insurance_registration_number: registrationNumber || "",
        paidamount: "0.00",
        // qualification: "", 
        applyingfor:"",
        gender: selectedGender || "", 
        // fathername: fatherName || "", 
        // mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "MSME",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };



      const faqs = [
        {
          question: "Who are eligible for MSME Registration?",
answer: (
  <div>
    <p>
      Any person who intends to establish a micro, small, medium enterprise may apply online for MSME/Udyog Aadhaar registration. Someone who belongs to Proprietorship, Hindu Undivided Family (HUF), One Person Company (OPC), Partnership Business, Limited Liability Partnership (LLP), Private Limited or Limited Company, Co-operative Societies or any other group of individuals can easily apply for Micro, Small & Medium Enterprise.
    </p>
    <p style={{ marginTop: '10px' }}>
      The purpose to include all wide categories of enterprises for registration under this Act, is to ensure that the benefits offered by Government reaches to large portion of Society.
    </p>
   
  </div>
),
          },
        {
            question: "What are the benefits Of MSME?",
            answer: (
              <div>
                <ul style={{ listStyleType: 'disc', marginTop: '10px' }}>
                  <li>MSME registration helps new entrepreneurs, traders, and business owners in getting loans at low interest rates.</li>
                  <li>MSME registration helps in getting government tenders.</li>
                  <li>Under bank loan, 15% import subsidy on fully automatic machinery.</li>
                  <li>Becomes easy to get licenses, approvals, and registrations, irrespective of the field of business.</li>
                  <li>Compensation of ISO certificate expenditure.</li>
                  <li>Registered MSMEs get tariff subsidies and tax and capital subsidies.</li>
                  <li>Get exemption under Direct Tax Laws.</li>
                  <li>Concession in electricity bills.</li>
                  <li>Protection against payments (Delayed payments) through MSME Samadhan.</li>
                  <li>Technology and quality up-gradation support to MSMEs.</li>
                </ul>
              </div>
            ),
        },
        {
            question: "How Wll I Get MSME Certificate ?",
            answer: "Soft copy will be shared to your registered mail id. No physical copy of MSME Certificate will be issued.",
        },
        {
            question: "What is the difference between Udyam Registration and MSME/Udyog Aadhar?",
            answer: "Udyam Registration is MSME registration to start business under Ministry of Micro, Small and Medium Enterprises (MoMSME) approved by Government of India. Whereas MSME/Udyog Aadhaar is a 12-digit Unique Identification Number (UIN) provided by the MoMSME for Small and Medium Enterprises (SMEs).",
        },
        {
            question: "Is Aadhaar Number mandatory for online MSME Registration?",
            answer: "Yes Aadhaar Number is mandatory for issuance of MSME Certificate",
        },
        {
            question: "Is there any renewal provision of MSME registration?",
            answer: "Registration Number is a permanent identity number and there will be no need for renewal of Registration.",
        },
        {
            question: "What are the chances of rejection of Online MSME Application?",
            answer: "The application will be approved on the basis of the information provided by the applicant.",
        },
        {
            question: "Can the MSE borrowers get collateral free loans from banks?",
            answer: "In terms of RBI circular, banks are mandated not to accept collateral security in the case of loans upto Rs 10 lakh extended to units in the MSE sector. Further banks may, on the basis of good track record and financial position of MSE units, increase the limit of dispensation of collateral requirement for loans up to Rs.25 lakh with the approval of the appropriate authority.",
        },
        {
            question: "Can MSME borrowers get collateral-free loans from financial institutions?",
            answer: "MSME borrowers get collateral-free loans from financial institutions",
        },
       
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
          <div style={{
    background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
    minHeight: '60vh',
    paddingTop: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px',
    marginTop:'4%'
}}>
    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
        <h2 style={{ marginLeft: "25%", marginBottom: "60px", fontWeight: "bold" }}>
        MSME registration Online 
        </h2>
        
        {/* Form section */}
      
    </div>
    
    <div>
        <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
</div>



            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '3% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '50%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-40%',
                                marginLeft:'50%'
                            }}
                        ></div>

<div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '65%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-40%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={TimeIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '70%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-65%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        Documents Required For MSME Registration
    </h4>
    <ul
        style={{
            display: 'grid',
         
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Aadhar Card</li>
        <li style={{ fontWeight: '' }}>Pan Card</li>
      
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'20%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  padding: '0', marginBottom: '0' }}>Register Online</li>
                    <li style={{  padding: '0', marginBottom: '0'}}>Upload Documents</li>
                    <li style={{  padding: '0', marginBottom: '0' }}>Payment</li>
                    <li style={{  padding: '0', marginBottom: '0' }}>Get Delivered</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'5%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  padding: '0', marginBottom: '0' }}>1-2 working days</li>
                    
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'30%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ padding: '0', marginBottom: '0' }}><strong>Rs 500</strong></li>
        <li style={{ padding: '0', marginBottom: '0' }}><strong>Rs 99</strong> as booking/consulting charge. Need to pay while submitting online form</li>
        {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "-2%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
         position: "fixed",
         top: "100px",
         left: "0",
         width: "100%",
         height: "100%",
         backgroundColor: "#1A76D8", 
         display: "flex",
         justifyContent: "center",
         alignItems: "center",
         zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           marginTop:'-5%',
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 2 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 1 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
 {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4>

    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
      <div style={{ width: "45%" }}>
        <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
          Name<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text"   value={fullName}
              onChange={handleFullNameChange}  placeholder="Enter your name" style={{ width: "100%", padding: "8px", marginTop: "5px" ,   border: "2px solid #FCA505",
          borderRadius: "10px",}} />
             {errors.fullName && <p style={{ color: "red" }}>{errors.fullName}</p>}
      </div>
      <div style={{ width: "45%" }}>
  <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
    Aadhaar Number<span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="text"
    value={aadhaarNumber} // Bind to state
    onChange={(e) => setAadhaarNumber(e.target.value)} // Update state on change
    placeholder="Enter your Aadhaar number"
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
  />
  {errors.aadhaarNumber && <p style={{ color: "red" }}>{errors.aadhaarNumber}</p>}
</div>
      
    </div>
            
        
        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
        <div style={{ width: "45%" }}>
  <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
    Name of Enterprise/Business<span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="text"
    value={businessName} // Bind to state
    onChange={(e) => setBusinessName(e.target.value)} // Update state on change
    placeholder="Enter your Name of Enterprise/Business"
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
  />
  {errors.businessName && <p style={{ color: "red" }}>{errors.businessName}</p>}
</div>
<div style={{ width: "45%" }}>
  <label
    style={{
      fontSize: "16px",
      fontWeight: "500",
      color: "#333",
    }}
  >
    Type of Organisation<span style={{ color: "red" }}>*</span>
  </label>
  <select
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
    value={organisationType} // Bind to state
    onChange={(e) => setOrganisationType(e.target.value)} // Update state on change
  >
    <option value="">--Select Organisation--</option>
    <option value="Proprietorship">Proprietorship</option>
    <option value="Private Limited">Private Limited</option>
    <option value="Public Limited">Public Limited</option>
    <option value="Partnership Firm">Partnership Firm</option>
    <option value="Limited Liability Partnership">Limited Liability Partnership</option>
    <option value="Trust">Trust</option>
    <option value="Society">Society</option>
    <option value="Hindu Undivided Family">Hindu Undivided Family</option>
    <option value="Co-operative">Co-operative</option>
    <option value="Other">Other</option>
  </select>
  {errors.organisationType && (
    <p style={{ color: "red" }}>{errors.organisationType}</p>
  )}
</div>

      
    </div>
    <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
    <div style={{ width: "45%" }}>
  <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
    Date of Incorporation / Registration<span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="date"
    value={dateOfIncorporation} // Bind to state
    onChange={(e) => setDateOfIncorporation(e.target.value)} // Update state on change
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
  />
  {errors.dateOfIncorporation && (
    <p style={{ color: "red" }}>{errors.dateOfIncorporation}</p>
  )}
</div>

<div style={{ width: "45%" }}>
  <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
    Business PAN Number<span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="text"
    value={panNumber} // Bind to state
    onChange={(e) => setPanNumber(e.target.value)} // Update state on change
    placeholder="Enter your Business PAN Number"
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
  />
  {errors.panNumber && <p style={{ color: "red" }}>{errors.panNumber}</p>}
</div>
      
    </div>
  </div>
)}

 {currentStep === 2 && (
   <div style={{ textAlign: "center" }}>
    {/* <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4> */}

    <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Address
             <span style={{ color: "red" }}>*</span>
           </label>
           <input type="text" value={villageTownCity} onChange={(e) => setVillageTownCity(e.target.value)} placeholder="Enter your address" style={{ width: "100%", height: "80px", padding: "10px", fontSize: "16px", border: "2px solid #FCA505", borderRadius: "10px" }} />
             {errors.villageTownCity && <p style={{ color: "red" }}>{errors.villageTownCity}</p>}
         </div>

    {/* State and District */}
    
      <div>
            {/* State Dropdown */}
            <div style={{ marginBottom: "20px", textAlign: "left" }}>
              <label
                htmlFor="state"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                State<span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="state"
                value={selectedState}
                onChange={handleStateChange}
                style={{
                  width: "100%",
                  height: "45px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  border: "2px solid #FCA505"
                }}
              >
                <option value="">Select State</option>
                {stateData.map((stateObj, index) => (
                  <option key={index} value={stateObj.state}>
                    {stateObj.state}
                  </option>
                ))}
              </select>
              {errors.selectedState && <p style={{ color: "red" }}>{errors.selectedState}</p>}
            </div>

            {/* District Dropdown */}
            {selectedState && (
              <div style={{ marginBottom: "20px", textAlign: "left" }}>
                <label
                  htmlFor="district"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  District<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  style={{
                    width: "100%",
                    height: "45px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    border: "2px solid #FCA505"
                  }}
                >
                  <option value="">Select District</option>
                  {districts.map((district, index) => (
                    <option key={index} value={district}>
                      {district}
                    </option>
                  ))}
                </select>
                {errors.selectedDistrict && <p style={{ color: "red" }}>{errors.selectedDistrict}</p>}
              </div>
            )}
          </div>

                <br></br>
        {/* Name, Mobile Number, and Email */}
        <div style={{ display: "flex", justifyContent: "space-between", gap: "10px", marginBottom: "20px" ,textAlign:"start"}}>
        <div style={{ width: "30%" }}>
  <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
    Pincode<span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="text"
    placeholder="Enter your Pincode"
    value={pinCode}
    onChange={(e) => setPinCode(e.target.value)}
    style={{
      width: "100%",
      padding: "8px",
      marginTop: "5px",
      border: "2px solid #FCA505",
      borderRadius: "10px",
    }}
  />
  {errors.pinCode && <p style={{ color: "red" }}>{errors.pinCode}</p>}
</div>

      <div style={{ width: "30%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333"  , 
         }}>
          Mobile Number<span style={{ color: "red" }}>*</span>
        </label>
        <input type="text"  id="mobileNumber"
              value={mobileNumber}
              onChange={handleMobileNumberChange}  placeholder="Enter your mobile number" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
            {errors.mobileNumber && <p style={{ color: "red" }}>{errors.mobileNumber}</p>}
      </div>
      <div style={{ width: "30%" }}>
        <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
          Email ID<span style={{ color: "red" }}>*</span>
        </label>
        <input value={emailId}
              onChange={handleEmailIdChange} type="email" placeholder="Enter your email" style={{ width: "100%", padding: "8px", marginTop: "5px",   border: "2px solid #FCA505",
          borderRadius: "10px", }} />
           {errors.emailId && <p style={{ color: "red" }}>{errors.emailId}</p>}
      </div>
    </div>
    <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
       By clicking submit, you agree to our{" "}
       <a href="/terms-conditions" style={{ color: "#007BFF", textDecoration: "underline" }}>
         Terms & Conditions
       </a>{" "}
       and{" "}
       <a
         href="/privacy-policy"
         style={{ color: "#007BFF", textDecoration: "underline" }}
       >
         Privacy Policy
       </a>.
     </p>
  </div>
)}

         </div>
         <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>
          {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}
           {currentStep < 2 ? (
             <button
             onClick={handleNext}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
            <button
            onClick={handleSubmit}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  SUBMIT
                </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
            <div>
            <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
              OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
            </h4>
            <div style={{ margin: "20px 0" }}>
              <label style={{ fontWeight: "500", marginBottom: "10px" }}>
                Enter OTP <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
          
            <div style={{ marginTop: "20px", textAlign: "center" }}>
          
              <p style={{ fontSize: "14px", color: "#888" }}>
                  {resendCountdown > 0 ? (
                    <>Not Received? Resend in {resendCountdown}s</>
                  ) : (
                    <a
                      href="#"
                      onClick={handleResend}
                      style={{
                        textDecoration: "none",
                        color: isResending ? "#ccc" : "#007BFF",
                        pointerEvents: isResending ? "none" : "auto",
                      }}
                    >
                      Resend OTP
                    </a>
                  )}
                </p>
            </div>
          
            <button
              onClick={handleVerify}
              style={{
                backgroundColor: "#FFA500",
                color: "#000",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              VERIFY
            </button>
          </div>
          ) : (
            <>
            <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
            <div style={styles.infoBox}>
              <div style={styles.inputGroup}>
                <label style={styles.label}> Name:</label>
                <input
                  type="text"
                  value={userDetails?.name || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
             
              <div style={styles.inputGroup}>
                <label style={styles.label}>Mobile Number:</label>
                <input
                  type="text"
                  value={userDetails?.mobilenumber || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Order ID:</label>
                <input
                  type="text"
                  value={orderid}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
            <label style={styles.label}>Services:</label>
            <input
              type="text"
              value="MSME"
              readOnly
              style={styles.input}
            />
          </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Payment Amount:</label>
                <input
                  type="text"
                  value="₹99"
                  readOnly
                  style={styles.input}
                />
              </div>
            </div>
            <button
              onClick={handleProceedToPay}
              style={styles.proceedButton}
            >
              Proceed to Pay
            </button>
       
          </>
          )}
        </div>
         )}

         {/* Close Button */}
         <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
        
        </div>

        
        
        </>
    );
};

const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    flex: '2',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginLeft: '10px',
    width: '45%', 
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default Msme;
