import React, { useState } from 'react';
import Image30 from '../../images/leaseimage.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/how.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';

const stateData = [
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },

];

const Lease = () => {
    
  
   
  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');
const [shiftingaddress, setShiftingAddress] = useState('');
const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');
const [ownerAddress, setOwnerAddress]=useState('');
const [selectedState, setSelectedState] = useState('');
const [ownername, setOwnerName]=useState('');
const [tenantName, setTenantName]=useState('');
// const [userData, setUserData] = useState(null); 
const [error, setError] = useState("");
const [shippingaddress, shippingAddress]= useState(null); 
const [shiftingdate, setShiftingDate]=useState('');
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };
const [tenantaddress, setTeanantAddress]= useState('');
const [monthlyrent, setMonthlyRent] = useState('')
const [registrationNumber, setSeletedRegistrationNumber]=useState('');

    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleMonthlyRent =(e)=> setMonthlyRent (e.target.value)
    const handleShiftingAddress =(e) => setShiftingAddress(e.target.value)
    const handleShiftingdate =(e) => setShiftingDate(e.target.value)
    const handleTenantAddress =(e)=> setTeanantAddress(e.target.value)
    const handleOwnerName=(e)=>setOwnerName(e.target.value)
    const handleTenantName=(e)=>setTenantName(e.target.value)
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
    const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);
    const handleOwnerAddress = (e) => setOwnerAddress(e.target.value)
    const [ownerDistrict, setOwnerDistrict] = useState('');
    const [ownerPincode, setOwnerPincode] = useState('');
    const [tenantDistrict, setTenantDistrict] = useState('');
    const [tenantPincode, setTenantPincode] = useState('');
    const handleOwnerDistrictChange = (e) => setOwnerDistrict(e.target.value);
    const handleOwnerPincodeChange = (e) => setOwnerPincode(e.target.value);
    const handleTenantDistrictChange = (e) => setTenantDistrict(e.target.value);
    const handleTenantPincodeChange = (e) => setTenantPincode(e.target.value);
    
    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };
  
   
    const validateStep = () => {
      switch (currentStep) {
        case 1:
          const agreementOption = document.querySelector('input[name="agreementOption"]:checked');
          if (!agreementOption) {
            setError("Please select an agreement option.");
            return false;
          }
          break;
        case 2:
          const identityOption = document.querySelector('input[name="identityOption"]:checked');
          const stampPaper = document.getElementById("stampPaper").value;
          if (!identityOption) {
            setError("Please select your identity.");
            return false;
          }
          if (!stampPaper) {
            setError("Please select a required stamp paper.");
            return false;
          }
          break;
          case 3:
            if (!ownername) {
              setError("Owner's name and age are required.");
              return false;
            }
            if (!ownerAddress) {
              setError("Owner's address is required.");
              return false;
            }
            if (!selectedState) {
              setError("Please select a state.");
              return false;
            }
            if (!ownerDistrict) {
              setError("Please select a district.");
              return false;
            }
            if (!ownerPincode) {
              setError("Please enter a pin code.");
              return false;
            }
            break;
            case 4:
          
      
            if (!tenantName) {
              setError("Tenant's name and age are required.");
              return false;
            }
            if (!tenantaddress) {
              setError("Tenant's address is required.");
              return false;
            }
            if (!selectedState) {
              setError("Please select a state.");
              return false;
            }
            if (!tenantDistrict) {
              setError("Please select a district.");
              return false;
            }
            if (!tenantPincode) {
              setError("Please enter a pin code.");
              return false;
            }
            break;
        case 5:
          if (!shiftingdate) {
            setError("Shifting date is required.");
            return false;
          }
          if (!shiftingaddress) {
            setError("Shifting address is required.");
            return false;
          }
          break;
        case 6:
          // Add validation for step 6 if needed
          break;
          case 7:
            if (!shippingaddress) {
              setError("Shipping address is required.");
              return false;
            }
            if (!mobileNumber) {
              setError("Mobile number is required.");
              return false;
            }
            if (!selectedState) {
              setError("State is required.");
              return false;
            }
            if (!selectedDistrict) {
              setError("District is required.");
              return false;
            }
            if (!pincode) {
              setError("Pin code is required.");
              return false;
            }
            break;
          
        default:
          break;
      }
      setError(""); // Clear error if validation passes
      return true; // Validation passed
    };
    
    
    const nextStep = () => {
      if (validateStep()) {
        if (currentStep < 7) setCurrentStep(currentStep + 1);
      }
    };
    
    // In your render method, display the error message if it exists
    {error && <div style={{ color: "red" }}>{error}</div>}
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };
    
      const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };

    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };

    const selectedStateData = stateData.find(
      (stateObj) => stateObj.state === selectedState
    );
    const districts = selectedStateData ? selectedStateData.districts : [];

    const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
    
    const handleSendOtp = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
          mobilenumber: formattedNumber,
        });
    
        if (response.status === 200) {
          console.log("API Response:", response.data);
          if (response.data.status === "success") {
            // Remove the alert and just set the state
            setOtpSent(true);
            setResendCountdown(30); // Reset countdown to 30 seconds
          } else {
            alert(response.data.message || "Error sending OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      }
    };
    
    React.useEffect(() => {
      if (resendCountdown > 0 && otpSent) {
        const timer = setInterval(() => {
          setResendCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else if (resendCountdown === 0) {
        setOtpSent(false); // Reset otpSent when countdown reaches zero
      }
    }, [resendCountdown, otpSent]);


    const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    
    const [userDetails, setUserData] = useState(null); // Holds user details
    const [orderid, setOrderID] = useState(() => generateOrderId()); 
  
    // Handle Proceed to Pay API call
    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; 
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; 
      const requestBody = {
        ORDER_ID: orderid, 
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Check if payment URL is provided
          if (response.data?.paymentUrl) {
            // Redirect to payment gateway
            window.location.href = response.data.paymentUrl;
          } else {
            alert("Payment request was successful, but no redirect URL was provided.");
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };
  
    const [isResending, setIsResending] = useState(false);
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };

    const handleVerify = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        
        // Validate the mobile number format (10 digits, no country code check here)
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        
        // Add country code (only if needed)
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        // Validate OTP
        const enteredOtp = otp.join("").trim();
        if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
          alert("Please enter a valid 4-digit OTP.");
          return;
        }
    
        // Make the API request to verify OTP
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
          { mobilenumber: formattedNumber, otp: enteredOtp }
        );
    
        // Handle the response
        if (response.status === 200) {
          console.log("OTP Verification Response:", response.data);
          if (response.data.status === "success") {
            alert("OTP Verified Successfully!");
            setShowOtpSection(false); // Hide OTP section
            await fetchUserDetails(formattedNumber); // Fetch user details after success
          } else {
            alert(response.data.message || "Error verifying OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        alert("An error occurred while verifying OTP. Please try again.");
      }
    };
    
    

 
    const [selectedDistrict, setSelectedDistrict] = useState("");
    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        orderid: orderid || "",
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        email: emailId || "",
        services: selectedOption || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        shiftingdate:shiftingdate || "",
        shiftingaddress :shiftingaddress || "",
        insurance_registration_number: registrationNumber || "",
        paidamount: "0.00",
        tenantName: tenantName || "",
        tenantaddress: tenantaddress ||  "",
        monthlyrent : monthlyrent || "",
        // qualification: "", 
        ownername:ownername || "",
        ownerAddress:ownerAddress || "",
        applyingfor:"",
        gender: selectedGender || "", 
        // fathername: fatherName || "", 
        // mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "Lease Agreement",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };


      const faqs = [
        {
            question: "Benefits of Lease Agreement?",
            answer: (
              <ul style={{ listStyleType: 'disc' }}>
                <li>Minimizes the conflicts between a tenant and the owner</li>
                <li>Rental/lease agreement acts as an address proof</li>
                <li>Acts as a proof for Bank loans</li>
                <li>Helps in investment & loan</li>
                <li>Vehicle registration</li>
              </ul>
            ),
          },
        {
            question: "Difference between comprehensive and third-party liability?",
            answer: "Comprehensive insurance covers both third-party liabilities and damage to your own vehicle, while third-party liability only covers damage caused to others.",
        },
        {
            question: "What is the different between rental/lease agreement?",
            answer: "Leasing is defined as a contract between lessor and lessee whereby the lessor buys the asset and lets the lessee use the asset for a particular period. Renting is to allow the other party to occupy or use the asset for a short time, in return for a fixed payment.",
        },
        {
            question: "what is stamp paper?",
            answer: "Stamp papers which are used for execution of documents are called as Non-Judicial Stamp Papers. ... Stamp Duty paid in respect of Non-Judicial Stamp Paper is paid under The Indian Stamp Act, 1899 and Stamp Duty paid in respect of Judicial Stamp Paper is paid under the Court Fees Act, 1870",
        },
        {
            question: "Do I need to visit any office to get lease agreement?",
            answer: "No its completely online process once you registered in our website we create draft and it will be print on stamp paper, and it get dispatched to address.",
        },
        {
            question: "How will I get my lease agreement?",
            answer: "It will get dispatched through courier.",
        },
        {
            question: "What is the value of stamp paper for lease agreement?",
            answer: "The Rental/Lease agreement must be printed on a Non-Judicial Stamp Paper with a value of Rs.100/- or more.",
        },
        {
            question: "What is validity of lease agreement?",
            answer: "In the usual practice, a landlord and a tenant make a rent agreement for a period of 11 months, with an option for a periodic renewal.",
        },
        {
            question: "what is Notarized lease agreement?",
            answer: "A notarized agreement is a document that has been marked with a notary stamp, which indicates that the signature on the document is legal. A notary officers is the witness when you sign and then places the stamp near your signature.",
        },
        {
            question: "Is agreement required to be notarised?",
            answer: "A contract typically does not have to be notarized. A notary public  provides an acknowledgment that the signature appearing on the document is that of the person whose signature it purports to be. There is a requirement that some documents be notarized, such as a real property deed.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px'
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>

                    <h2 style={{marginLeft:"25%", marginBottom:"60px",fontWeight:"bold"}}>Lease Agreement Online</h2>
                    <p>"Find Peace of Mind with our Comprehensive lease Agreement</p>
                    <p>Services - Simplifying the Lease Agreement Process!"</p>
                </div>
                <div>
                    <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto', marginTop:'23%' }} />
                </div>
            </div>

            <div style={{ padding: '10px', textAlign: 'left',marginLeft:"8%" }}>
                <h2 style={{ color: '#1A76D8', fontWeight: 'bold', marginLeft:"20px" }}>What We Do!</h2>
                <p style={{ textAlign:'left', padding:"2%", fontWeight:"600"}}>
                    <span style={{  }}>Make My Documents Online Agreements Service.</span><br />
                    <span style={{ }}>Service available only in Karnataka.</span><br />
                    <span style={{  }}>Draft will be shared to your mail id within 60min.</span>
                </p>
            </div>

            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" style={{maxWidth:'31%'}} />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        width:'18%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-32%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" style={{maxWidth:'31%'}}/>
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        width:'18%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-52%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" style={{maxWidth:'31%'}}/>
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        width:'18%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-35%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        How It Works
    </h4>
    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li >Register Online</li>
        <li >Drafting</li>
        <li >Review Drafting</li>
        <li >Payment</li>
        <li >Doorstep Delivery</li>
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'10%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  marginBottom: '10px' }}>1-2 working days</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'32%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ marginBottom: '10px' }}>Rs 300</li>
        <li style={{  marginBottom: '10px' }}>Rs 50 as booking/consulting charge. Need to pay while submitting online form</li>
        <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li>
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 7 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 6 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
<div style={{ marginBottom: "20px" }}>
{currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
   
    <h4 style={{ color: "#00000", fontWeight: "400" }}>
     Lease  Agreement
    </h4>


    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Want to get
      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="flatHouseLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Flat/House Lease Agreement
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopLeaseAgreement"
          style={{ marginRight: "10px" }}
        />
        Commercial Office/Shop Lease Agreement
      </label>
    </div>
    {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
  </div>
)}

{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <h5 style={{ color: "#007BFF", fontWeight: "bold", marginLeft: '-79%', fontSize: '24px' }}>
      I Am
      <span style={{ color: "red" }}>*</span>
    </h5>

  
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="owner"
          style={{ marginRight: "10px" }}
        />
        Owner
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="identityOption"
          value="tenant"
          style={{ marginRight: "10px" }}
        />
        Tenant
      </label>
    </div>

    {/* Required Stamp Paper Dropdown */}
    <div style={{ marginBottom: "33px", textAlign: "left" , marginTop:"60px" }}>
      <label
        htmlFor="stampPaper"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Required Stamp Paper
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="stampPaper"
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="100Rs">100Rs</option>
        <option value="200Rs">200Rs</option>
        <option value="20Rs">500Rs</option>
        <option value="20Rs">400Rs</option>
        <option value="20Rs">300Rs</option>
      </select>
    </div>
    {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
  </div>
)}

{currentStep === 3 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Owner's Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Owner's Name & Age */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Name & Age
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="ownerName"
        value={ownername}
        onChange={handleOwnerName}
        placeholder="Enter Owner's Name & Age"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
      {error && !ownername && <span style={{ color: "red" }}>{error}</span>}
    </div>

    {/* Owner's Father's Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerFatherName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Father's Name
      </label>
      <input
        type="text"
        id="ownerFatherName"
        placeholder="Enter Owner's Father's Name"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Owner's Address */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerAddress"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Owner's Address
        <span style={{ color: "red" }}>*</span>
      </label>
      <textarea
        id="ownerAddress"
        value={ownerAddress}
        onChange={handleOwnerAddress}
        placeholder="Enter Owner's Address"
        style={{
          width: "100%",
          height: "90px", // Increased height for address
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
      {error && !ownerAddress && <span style={{ color: "red" }}>{error}</span>}
    </div>

    {/* State Selection */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label htmlFor="ownerState" style={{ fontSize: "16px" }}>
        State<span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="ownerState"
        value={selectedState}
        onChange={handleStateChange}
        style={{ width: "100%",
          height: "45px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px", }}
      >
        <option value="">Select State</option>
        {stateData.map((stateObj, index) => (
          <option key={index} value={stateObj.state}>
            {stateObj.state}
          </option>
        ))}
      </select>
      {error && !selectedState && <span style={{ color: "red" }}>State is required</span>}
    </div>

    {/* Owner District */}
    {selectedState && (
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label htmlFor="ownerDistrict" style={{ fontSize: "16px" }}>
          District<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="ownerDistrict"
          value={ownerDistrict}
          onChange={handleOwnerDistrictChange}
          style={{ width: "100%",
            height: "45px",
            fontSize: "16px",
            border: "2px solid #FCA505",
            borderRadius: "4px", }}
        >
          <option value="">Select District</option>
          {districts.map((district, index) => (
            <option key={index} value={district}>
              {district}
            </option>
          ))}
        </select>
        {error && !ownerDistrict && <span style={{ color: "red" }}>District is required</span>}
      </div>
    )}

    {/* Owner Pin Code */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label htmlFor="ownerPincode" style={{ fontSize: "16px" }}>
        Pin Code <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={ownerPincode}
        onChange={handleOwnerPincodeChange}
        id="ownerPincode"
        style={{ width: "100%",
          height: "45px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px", }}
      />
      {error && !ownerPincode && <span style={{ color: "red" }}>Pin Code is required</span>}
    </div>
  </div>
)}


{currentStep === 4 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Tenant Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Tenant Name & Age */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="TenantName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Tenant Name & Age
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="tenantName"
        value={tenantName}
        onChange={handleTenantName}
        placeholder="Enter Tenant Name & Age"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Tenant Father's Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="TenantFatherName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Tenant Father's Name
      </label>
      <input
        type="text"
        id="TenantFatherName"
        placeholder="Enter Tenant's Father's Name"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Tenant Address */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="TenantAddress"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Tenant Address
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="TenantAddress"
        value={tenantaddress}
        onChange={handleTenantAddress}
        placeholder="Enter Tenant Address"
        style={{
          width: "100%",
          height: "90px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label htmlFor="tenantState" style={{ fontSize: "16px" }}>
        State<span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="tenantState"
        value={selectedState}
        onChange={handleStateChange}
        style={{ width: "100%",
          height: "45px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px", }}
      >
        <option value="">Select State</option>
        {stateData.map((stateObj, index) => (
          <option key={index} value={stateObj.state}>
            {stateObj.state}
          </option>
        ))}
      </select>
      {error && !selectedState && <span style={{ color: "red" }}>State is required</span>}
    </div>

    {/* Tenant District */}
    {selectedState && (
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label htmlFor="tenantDistrict" style={{ fontSize: "16px" }}>
          District<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="tenantDistrict"
          value={tenantDistrict}
          onChange={handleTenantDistrictChange}
          style={{ width: "100%",
            height: "45px",
            fontSize: "16px",
            border: "2px solid #FCA505",
            borderRadius: "4px", }}
        >
          <option value="">Select District</option>
          {districts.map((district, index) => (
            <option key={index} value={district}>
              {district}
            </option>
          ))}
        </select>
        {error && !tenantDistrict && <span style={{ color: "red" }}>District is required</span>}
      </div>
    )}

    {/* Tenant Pin Code */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label htmlFor="tenantPincode" style={{ fontSize: "16px" }}>
        Pin Code <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={tenantPincode}
        onChange={handleTenantPincodeChange}
        id="tenantPincode"
        style={{ width: "100%",
          height: "45px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px", }}
      />
      {error && !tenantPincode && <span style={{ color: "red" }}>Pin Code is required</span>}
    </div>
  </div>
)}

{currentStep === 5 && (
           <div style={{ textAlign: "center" }}>
          
           <p style={{ color: "#1A76D8", fontWeight: "600" }}>
            Other Info<span style={{ color: "red" }}>*</span>
           </p>
       
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
  <label
    htmlFor="ShifterDate"
    style={{
      display: "block",
      marginBottom: "10px",
      fontSize: "16px",
      fontWeight: "500",
    }}
  >
    Shifted Date
    <span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="date"
    id="ShifterDate"
    value={shiftingdate}
    onChange={handleShiftingdate}
    placeholder=""
    style={{
      width: "100%",
      height: "45px",
      padding: "10px",
      fontSize: "16px",
      border: "2px solid #FCA505",
      borderRadius: "4px",
    }}
  />
</div>

       
           {/* Owner's Father's Name */}
           
       
           {/* Owner's Address */}
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="ShiftingAddress"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Shifting Address
               <span style={{ color: "red" }}>*</span>
             </label>
             <textarea
               id="TenantAddress"
               value={shiftingaddress}
               onChange={handleShiftingAddress}
               placeholder=""
               style={{
                 width: "100%",
                 height: "90px", // Increased height for address
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>

           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="SecurityDeposit"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Security Deposit 
               <span style={{ color: "red" }}>*</span>
             </label>
             <input
               type="text"
               id="TenantFatherName"
               placeholder=""
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
           <div style={{ marginBottom: "20px", textAlign: "left" }}>
             <label
               htmlFor="MonthlyRent"
               style={{
                 display: "block",
                 marginBottom: "10px",
                 fontSize: "16px",
                 fontWeight: "500",
               }}
             >
               Monthly Rent 
                <span style={{ color: "red" }}>*</span>
             </label>
             <input
               type="text"
               id=""
               value={monthlyrent}
               onChange={handleMonthlyRent}
               placeholder=""
               style={{
                 width: "100%",
                 height: "45px",
                 padding: "10px",
                 fontSize: "16px",
                 border: "2px solid #FCA505",
                 borderRadius: "4px",
               }}
             />
           </div>
           {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
         </div>
)}

{currentStep === 6 && (
  <div style={{ marginBottom: "20px", textAlign: "left" }}>
  <div style={{ marginBottom: "20px" }}>
  <label style={{ fontSize: "20px", fontWeight: "600" }}>
    Advance Paid Through <span style={{ color: "red" }}>*</span>
  </label>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "4%",
      width: "fit-content",
      gap: "20px",
      marginTop: "2%",
    }}
  >
    <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
      <input
        type="radio"
        name="advancePaidThrough"
        value="cash"
        style={{ marginRight: "10px" }}
      />
      Cash
    </label>
    <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
      <input
        type="radio"
        name="advancePaidThrough"
        value="cheque"
        style={{ marginRight: "10px" }}
      />
      Cheque
    </label>
    <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
      <input
        type="radio"
        name="advancePaidThrough"
        value="netBanking"
        style={{ marginRight: "10px" }}
      />
      Net Banking
    </label>
  </div>
</div>

<div style={{ textAlign: "left" }}>
  <label style={{ fontSize: "16px", fontWeight: "bold" }}>
    Water Charges <span style={{ color: "red" }}>*</span>
  </label>
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      marginLeft: "4%",
      width: "fit-content",
      gap: "9px",
      marginTop: "20px",
    }}
  >
    <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
      <input
        type="radio"
        name="waterCharges"
        value="included"
        style={{ marginRight: "10px" }}
      />
      Included in Rent
    </label>
    <label style={{ fontSize: "16px", fontWeight: "500", color: "#333" }}>
      <input
        type="radio"
        name="waterCharges"
        value="excluded"
        style={{ marginRight: "10px" }}
      />
      Excluded
    </label>
  </div>
</div>


<div style={{ marginBottom: "40px", textAlign: "left", marginTop: "26px" }}>
  <label style={{ fontSize: "16px", fontWeight: "bold" }}>
    Painting Charges <span style={{ color: "red" }}>*</span>
  </label>
  <select
    style={{
      width: "100%",
      padding: "10px",
      marginTop: "10px",
      border: "1px solid orange",
      borderRadius: "4px",
      fontSize: "16px",
    }}
    defaultValue=""
  >
    <option value="" disabled>
      Choose
    </option>
    <option value="applicable">Applicable</option>
    <option value="notApplicable">Not Applicable</option>
  </select>
</div>


    <div style={{ marginBottom: "40px" ,textAlign:"left"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
       Accommodation  <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        placeholder="Enter accommodation details"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "40px" ,textAlign:"left", marginTop:"-4px"}}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
       Appliances/Fittings Details  <span style={{ color: "red" }}>*</span> 
      </label>
      <textarea
        placeholder="Enter appliances and fitting details"
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
          minHeight: "100px",
        }}
      />
    </div>
    {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}
  </div>
)}

{currentStep === 7 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 7 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Contact Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Shipping Address */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerAddress"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Shipping Address
        <span style={{ color: "red" }}>*</span>
      </label>
      <textarea
        id="ownerAddress"
        value={shippingaddress}
        onChange={(e) => shippingAddress(e.target.value)}
        placeholder=""
        style={{
          width: "100%",
          height: "90px", // Increased height for address
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* State */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="state"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        State<span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="state"
        value={selectedState}
        onChange={handleStateChange}
        style={{
          width: "100%",
          height: "45px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <option value="">Select State</option>
        {stateData.map((stateObj, index) => (
          <option key={index} value={stateObj.state}>
            {stateObj.state}
          </option>
        ))}
      </select>
    </div>

    {/* District */}
    {selectedState && (
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="district"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          District<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="district"
          value={selectedDistrict}
          onChange={handleDistrictChange}
          style={{
            width: "100%",
            height: "45px",
            fontSize: "16px",
            border: "2px solid #FCA505",
            borderRadius: "4px",
          }}
        >
          <option value="">Select District</option>
          {districts.map((district, index) => (
            <option key={index} value={district}>
              {district}
            </option>
          ))}
        </select>
      </div>
    )}

    {/* Pin Code */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="pincode"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Pin Code <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={pincode}
        onChange={(e) => setPincode(e.target.value)}
        id="pincode"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "10px",
        }}
      />
    </div>

    {/* Mobile Number */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Mobile Number
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="ownerName"
        value={mobileNumber}
        onChange={handleMobileNumberChange}
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Email ID */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="ownerFatherName"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Email ID
      </label>
      <input
        type="text"
        value={emailId}
        onChange={handleEmailIdChange}
        id="email"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Error Message */}
    {error && <div style={{ color: "red", textAlign: "center" }}>{error}</div>}

     {/* Terms and Conditions */}
     <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          By clicking submit, you agree to our{" "}
                          <a
                            href="/terms-conditions"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
  </div>
)}
</div>
         <div   style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>
         {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}
          
           {currentStep < 7 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
            <button
            onClick={() => {
              if (!mobileNumber) {
                setError("Mobile number is required."); 
                return;
              }
              handleSendOtp(); 
              finishSubmission();
            }}
              style={{
                padding: "10px 20px",
                backgroundColor: "FCA505",
                color: "#000000",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              SUBMIT
            </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
      <div>
      <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
        OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
      </h4>
      <div style={{ margin: "20px 0" }}>
        <label style={{ fontWeight: "500", marginBottom: "10px" }}>
          Enter OTP <span style={{ color: "red" }}>*</span>
        </label>
        <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          {otp.map((digit, index) => (
            <input
              key={index}
              id={`otp-input-${index}`}
              type="text"
              maxLength="1"
              value={digit}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleBackspace(e, index)}
              style={{
                width: "50px",
                height: "50px",
                textAlign: "center",
                fontSize: "18px",
                border: "1px solid #ccc",
                borderRadius: "5px",
              }}
            />
          ))}
        </div>
      </div>
    
      <div style={{ marginTop: "20px", textAlign: "center" }}>
    
        <p style={{ fontSize: "14px", color: "#888" }}>
            {resendCountdown > 0 ? (
              <>Not Received? Resend in {resendCountdown}s</>
            ) : (
              <a
                href="#"
                onClick={handleResend}
                style={{
                  textDecoration: "none",
                  color: isResending ? "#ccc" : "#007BFF",
                  pointerEvents: isResending ? "none" : "auto",
                }}
              >
                Resend OTP
              </a>
            )}
          </p>
      </div>
    
      <button
        onClick={handleVerify}
        style={{
          backgroundColor: "#FFA500",
          color: "#000",
          border: "none",
          borderRadius: "5px",
          padding: "10px 20px",
          fontSize: "16px",
          cursor: "pointer",
        }}
      >
        VERIFY
      </button>
    </div>
          ) : (
  
    <>
      <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
      <div style={styles.infoBox}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Owner Name:</label>
          <input
            type="text"
            value={userDetails?.ownername || "N/A"}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Tenant Name:</label>
          <input
            type="text"
            value={userDetails?.tenantname || "N/A"}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Mobile Number:</label>
          <input
            type="text"
            value={userDetails?.mobilenumber || "N/A"}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Order ID:</label>
          <input
            type="text"
            value={orderid}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Services:</label>
          <input
            type="text"
            value={selectedOption}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Payment Amount:</label>
          <input
            type="text"
            value="₹99"
            readOnly
            style={styles.input}
          />
        </div>
      </div>
      <button
        onClick={handleProceedToPay}
        style={styles.proceedButton}
      >
        Proceed to Pay
      </button>
    {/* // </div> */}
    </>
          )}
        </div> 
         )}

         {/* Close Button */}
         <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
            <>
            <p>Welcome to Make My Documents, your one-stop destination for all your Lease agreement needs. 
            We understand that renting a property can be a complex process, which is why we offer a hassle-free 
            solution to help you secure your Lease agreement quickly and easily.</p>

            <p>Our team of legal experts has extensive experience in drafting lease agreements that meet all legal requirements. 
            We provide comprehensive Lease agreement services, including new lease agreements, 
            Lease agreement renewals, and Lease agreement amendments. We also offer customized lease agreement 
            solutions tailored to your unique needs.</p>

            <p>At Make My Documents, we take pride in providing exceptional customer service. We understand that 
            your time is valuable, which is why we offer a seamless online Lease agreement application process.</p>

            <p>Our online Lease agreement form allows you to apply from the comfort of your own home, and our team will 
            guide you through every step of the process. Our lease agreement services include all the necessary legal clauses 
            and terms to protect both the landlord and the tenant. We also provide guidance on security deposits, 
            rent payment schedules, and any other special requirements you may have.</p>

            <p>Our Lease agreement services are competitively priced, so you won't have to break the bank to secure your 
            lease agreement. Plus, our fast turnaround times mean that you can have your lease agreement in hand as soon as possible.</p>

            <p>Whether you need a lease agreement for your house, room, or commercial property, Make My Documents has 
            got you covered. We offer e-registration of lease agreements, lease agreements, and notarized lease 
            agreements to suit your specific requirements. Our services also include guidance on stamp duty for 
            lease agreements, rent agreement validity, lease agreement cancellation, and lease agreement renewal.</p>

            <p>If you're in need of a lease agreement, look no further than Make My Documents. Contact us today to 
            learn more about our lease agreement services and to schedule an appointment with one of our legal experts. 
            We look forward to helping you secure your lease agreement and enjoy a hassle-free renting experience.</p>

            <p>We also ensure that our lease agreement services are compliant with the latest legal and regulatory requirements, 
            giving you peace of mind. Our easy-to-use online platform and step-by-step support make the entire process 
            smooth and efficient. At Make My Documents, we are committed to delivering reliable, transparent, and customer-focused 
            solutions to cater to your lease agreement needs. With our expertise and dedication, you can trust us to simplify your 
            lease agreement process, saving you time and effort.</p>
        </>
        </div>

        
        
        </>
    );
};

const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    padding: '8px', // Slightly reduced padding for smaller input boxes
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px', // Reduced font size for better alignment with smaller width
    marginLeft: '10px',
    width: '200px', // Set the desired width here
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};



export default Lease;
