import React from "react";
import { Link, useLocation } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';  
import logo from "./images/logo.svg";
import { ReactComponent as InsuranceIcon } from "./images/service/insurance.svg";
import { ReactComponent as VisaIcon } from "./images/service/visa.svg";
import { ReactComponent as RentalIcon } from "./images/service/rental.svg";
import { ReactComponent as LeaseIcon } from "./images/service/lease.svg";
import { ReactComponent as PassportIcon } from "./images/service/Passport.svg";
import { ReactComponent as SeniorCardIcon } from "./images/service/senior.svg";
import { ReactComponent as GSTIcon } from "./images/service/gst.svg";
import { ReactComponent as FoodLicenseIcon } from "./images/service/food.svg";
import { ReactComponent as MSMEIcon } from "./images/service/msme.svg";
import { ReactComponent as PoliceIcon } from "./images/service/police.svg";
import { ReactComponent as AffidavitsIcon } from "./images/service/affidavits.svg";
import { ReactComponent as Pancard } from "./images/service/leasecertification.svg";

const Header = () => {
  const location = useLocation();

  // Function to check if the current page is active
  const isActive = (path) => location.pathname === path;

  return (
    <header className="bg-white">
      <nav className="navbar navbar-expand-lg navbar-light bg-white fixed-top">
        <Link to="/">
          <img 
            src={logo} 
            alt="Logo" 
            className="logo-icon" 
            style={{ marginRight: "20px" }} 
          />
        </Link>

        <div className="collapse navbar-collapse me-12" id="navbarSupportedContent" style={{
          marginLeft: "-12%",           
          transform: "translateX(55%)",
        }}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link 
                className={`nav-link ${isActive("/") ? "active" : ""}`} 
                to="/" 
                style={{
                  display: "inline-block", // Ensure the link respects padding and border
                  color: isActive("/") ? "#fff" : "#1A76D8", // Highlight active link
                  textDecoration: "none", 
                  marginRight: "-2px",
                  backgroundColor: isActive("/") ? "#1A76D8" : "transparent", // Active background
                  padding: isActive("/") ? "8px 15px" : "5px 15px", // Add padding to active link
                  borderRadius: isActive("/") ? "5px" : "0", // Rounded corners for active link
                  lineHeight: "20px", // Adjust line height for consistent alignment
                  borderBottom: isActive("/") ? "3px solid #1A76D8" : "none", // Increase border width for active link
                }}
              >
                Home
              </Link>
            </li>

            <li className="nav-item dropdown">
              <Link
                className={`nav-link dropdown-toggle ${isActive("/services") ? "active" : ""}`}
                to="#"
                id="navbarDropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  display: "inline-block", // Ensure the link respects padding and border
                  backgroundColor: isActive("/services") ? "#1A76D8" : "transparent",
                  color: isActive("/services") ? "white" : "#1A76D8", 
                  padding: "8px 15px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  marginRight: "-2px",
                  marginTop: '-2px',
                  lineHeight: "20px",
                  borderBottom: isActive("/services") ? "3px solid #1A76D8" : "none", // Line under active dropdown
                }}
              >
                Services
              </Link>
              <div
                className="dropdown-menu"
                aria-labelledby="navbarDropdown"
                style={{
                  width: "525px",
                  borderRadius: "25px",
                  left: "0",
                  padding: "10px",
                  transform: "translateX(-50%)",
                }}
              >
                <div className="row">
                  {/* First Column */}
                  <div className="col-6">
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/insurance">
    <InsuranceIcon className="me-2" />
    Insurance
  </Link>
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/visa">
    <VisaIcon className="me-2" />
    Travel Visa
  </Link>
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/rental-agreement">
    <RentalIcon className="me-2" />
    Rental Agreement
  </Link>
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/lease-agreement">
    <LeaseIcon className="me-2" />
    Lease Agreement
  </Link>
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/passport">
    <PassportIcon className="me-2" />
    Passport
  </Link>
  <Link className="dropdown-item d-flex align-items-center mb-2" to="/pan-card">
    <Pancard className="me-2" />
    Pancard
  </Link>
</div>

                  {/* Second Column */}
                  <div className="col-6">
                    <Link className="dropdown-item d-flex align-items-center" to="/senior-citizen">
                      <SeniorCardIcon className="me-2" />
                      Senior Citizen Card
                    </Link>
                    <Link className="dropdown-item d-flex align-items-center" to="/policeverification">
                      <GSTIcon className="me-2" />
                      Police Verification<br/>  Certifiacate
                    </Link>
                    <Link className="dropdown-item d-flex align-items-center" to="/food-license">
                      <FoodLicenseIcon className="me-2" />
                      Food License (FSSAI)
                    </Link>
                    <Link className="dropdown-item d-flex align-items-center" to="/msme">
                      <MSMEIcon className="me-2" />
                      MSME Certificate
                    </Link>
                    <Link className="dropdown-item d-flex align-items-center" to="/police-clearance">
                      <PoliceIcon className="me-2" />
                      Police Clearance <br/> Certificate
                    </Link>
                    <Link className="dropdown-item d-flex align-items-center" to="/affidavits">
                      <AffidavitsIcon className="me-2" />
                      Affidavits/Annexure
                    </Link>
                  </div>
                </div>
              </div>
            </li>

            <li className="nav-item">
              <Link 
                className={`nav-link ${isActive("/about") ? "active" : ""}`} 
                to="/about" 
                style={{
                  display: "inline-block", // Ensure the link respects padding and border
                  color: isActive("/about") ? "#fff" : "#1A76D8",
                  textDecoration: "none",
                  marginRight: "-2px",
                  backgroundColor: isActive("/about") ? "#1A76D8" : "transparent",
                  padding: isActive("/about") ? "8px 15px" : "5px 15px",
                  borderRadius: isActive("/about") ? "5px" : "0",
                  lineHeight: "20px",
                  borderBottom: isActive("/about") ? "3px solid #1A76D8" : "none", // Line under active link
                }}
              >
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link 
                className={`nav-link ${isActive("/blogs") ? "active" : ""}`} 
                to="/blogs" 
                style={{
                  display: "inline-block", // Ensure the link respects padding and border
                  color: isActive("/blogs") ? "#fff" : "#1A76D8",
                  textDecoration: "none",
                  marginRight: "-2px",
                  backgroundColor: isActive("/blogs") ? "#1A76D8" : "transparent",
                  padding: isActive("/blogs") ? "8px 15px" : "5px 15px",
                  borderRadius: isActive("/blogs") ? "5px" : "0",
                  lineHeight: "20px",
                  borderBottom: isActive("/blogs") ? "3px solid #1A76D8" : "none", // Line under active career link
                }}
              >
                Blogs
              </Link>
            </li>
            
            <li className="nav-item">
              <Link 
                className={`nav-link ${isActive("/contact") ? "active" : ""}`} 
                to="/contact" 
                style={{
                  display: "inline-block", // Ensure the link respects padding and border
                  color: isActive("/contact") ? "#fff" : "#1A76D8",
                  textDecoration: "none",
                  marginRight: "-1px",
                  backgroundColor: isActive("/contact") ? "#1A76D8" : "transparent",
                  padding: isActive("/contact") ? "8px 15px" : "5px 15px", 
                  borderRadius: isActive("/contact") ? "5px" : "0",
                  lineHeight: "20px",
                  borderBottom: isActive("/contact") ? "3px solid #1A76D8" : "none", 
                }}
              >
                Contact Us
              </Link>
            </li>
           
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
