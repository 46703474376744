import React, { useState } from 'react';
import Image30 from '../../images/Police_verification_image.png'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import howIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';

const stateData = [
  {
    state: "Andhra Pradesh",
    districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
  },
  {
    state: "Arunachal Pradesh",
    districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
  },
  {
    state: "Assam",
    districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
  },
  {
    state: "Bihar",
    districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  },
  {
    state: "Chhattisgarh",
    districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
  },
  {
    state: "Goa",
    districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  },
  {
    state: "Haryana",
    districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
  },
  {
    state: "Himachal Pradesh",
    districts: ["Shimla", "Manali", "Kullu", "Kangra"],
  },
  {
    state: "Jharkhand",
    districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
  },
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },
  {
    state: "Kerala",
    districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
  },
  {
    state: "Madhya Pradesh",
    districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
  },
  {
    state: "Maharashtra",
    districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  },
  {
    state: "Manipur",
    districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
  },
  {
    state: "Meghalaya",
    districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
  },
  {
    state: "Mizoram",
    districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
  },
  {
    state: "Nagaland",
    districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
  },
  {
    state: "Odisha",
    districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
  },
  {
    state: "Punjab",
    districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
  },
  {
    state: "Rajasthan",
    districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
  },
  {
    state: "Sikkim",
    districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
  },
  {
    state: "Tamil Nadu",
    districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
  },
  {
    state: "Telangana",
    districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
  },
  {
    state: "Tripura",
    districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
  },
  {
    state: "Uttar Pradesh",
    districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
  },
  {
    state: "Uttarakhand",
    districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
  },
  {
    state: "West Bengal",
    districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
  },
  {
    state: "Andaman and Nicobar Islands",
    districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
  },
  {
    state: "Chandigarh",
    districts: ["Chandigarh"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    districts: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Lakshadweep",
    districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
  },
  {
    state: "Delhi",
    districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
  },
  {
    state: "Puducherry",
    districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  },
];

const PoliceClearance = () => {
    
  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');

const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');

const [selectedState, setSelectedState] = useState('');
const [qualification , setQualification] =useState('');


const [error, setError] = useState("");
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };
const [applyingFor, setApplyingFor] =useState("")
const handleApplyingFor =(e) => setApplyingFor(e.target.value)
    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleDateofbirth =(e) => setDob (e.target.value)
    const handleQualification =(e) => setQualification(e.target.value)
const handleSelectedGender =(e) => setSelectedGender(e.target.value)
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);

    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };
    const selectedStateData = stateData.find(
      (stateObj) => stateObj.state === selectedState
    );
    const districts = selectedStateData ? selectedStateData.districts : [];


    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);
    
      // Navigate steps
      const nextStep = () => {
        if (currentStep === 1) {
          if (!applyingFor) {
            setError("Please select an application type.");
            return;
          }
        } else if (currentStep === 2) {
          if (!fullName || !selectedGender || !dob || !qualification) {
            setError("All fields in Step 2 are required.");
            return;
          }
        } else if (currentStep === 3) {
          if (
            !villageTownCity ||
            !selectedState ||
            !selectedDistrict ||
            !mobileNumber
          ) {
            setError("All fields in Step 3 are required.");
            return;
          }
          if (!/^\d{10}$/.test(mobileNumber)) {
            setError("Please enter a valid 10-digit mobile number.");
            return;
          }
        }
      
        setError(""); // Clear the error if validations pass
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      };

      const handleSubmit = () => {
        if (!mobileNumber) {
          setError("Mobile number is required.");
          return;
        }
        handleSendOtp(); // Add your OTP handling logic here
        finishSubmission(); // Add final submission logic here
      };


      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

      const finishSubmission = () => {
        submitDataToAPI();
        if (mobileNumber) {
          setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
        }
        setIsCompleted(true);
      };

      const submitDataToAPI = async () => {
        const data = {
          name: fullName || "", 
          mobilenumber: mobileNumber || "", 
          email: emailId || "",
          services: applyingFor || "", 
          address: houseStreetName || "", 
          district: villageTownCity || "",
          date: dob || "", 
          paidamount: "0.00",
          qualification: qualification || "", 
          // applying_for: applyingFor || "",
          gender: selectedGender || "", 
          // fathername: fatherName || "", 
          // mothername: motherName || "", 
          pincode: pincode || "", 
          adharnumber: aadharNumber || "", 
          pancard: "", 
          time: "00:00:00", 
          comment: "", 
          status: "",
          service: "Police Clearance",
          followuptime: "00:00:00", 
          // existingpancardnumber: existingPanCardNumber || "", 
          village: villageTownCity || "", 
          "pancard-state": selectedState || "", 
          // "pancard-district": selectedDistrict || "", 
        };
    
        console.log("Data being sent to API:", data); 
    
        try {
          const response = await axios.post(
            "https://makemydocuments.nakshatranamahacreations.in/create.php",
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("API Response:", response.data); 
          if (response.data.success) {
            alert("Details saved successfully!");
          } else {
          }
        } catch (error) {
          console.error("Error while saving data:", error);
          alert("An error occurred while saving your details. Please try again.");
        }
      };

      const [otpSent, setOtpSent] = useState(false);


      const handleSendOtp = async () => {
        try {
          let formattedNumber = mobileNumber.trim();
          if (!/^\d{10}$/.test(formattedNumber)) {
            alert("Please enter a valid 10-digit mobile number.");
            return;
          }
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
      
          const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
            mobilenumber: formattedNumber,
          });
      
          if (response.status === 200) {
            console.log("API Response:", response.data);
            if (response.data.status === "success") {
              // Remove the alert and just set the state
              setOtpSent(true);
              setResendCountdown(30); // Reset countdown to 30 seconds
            } else {
              alert(response.data.message || "Error sending OTP.");
            }
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error sending OTP:", error);
          alert("An error occurred while sending OTP. Please try again.");
        }
      };
      
      React.useEffect(() => {
        if (resendCountdown > 0 && otpSent) {
          const timer = setInterval(() => {
            setResendCountdown((prev) => prev - 1);
          }, 1000);
          return () => clearInterval(timer);
        } else if (resendCountdown === 0) {
          setOtpSent(false); // Reset otpSent when countdown reaches zero
        }
      }, [resendCountdown, otpSent]);


          
     const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    
    const [userDetails, setUserData] = useState(null); // Holds user details
    const [orderid, setOrderID] = useState(() => generateOrderId()); 

    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; // Generates dynamic CUST_ID
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; // Add 350 to the existing amount
    
      const requestBody = {
        ORDER_ID: orderid, // Uses the generated order ID
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Redirect to payment gateway if payment URL is provided
          if (response.data?.paymentUrl) {
            window.location.href = response.data.paymentUrl;
          } else {
            alert(
              "Payment request was successful, but no redirect URL was provided."
            );
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };

 
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };
      const handleVerify = async () => {
        try {
          let formattedNumber = mobileNumber.trim();
          
          // Validate the mobile number format (10 digits, no country code check here)
          if (!/^\d{10}$/.test(formattedNumber)) {
            alert("Please enter a valid 10-digit mobile number.");
            return;
          }
          
          // Add country code (only if needed)
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
      
          // Validate OTP
          const enteredOtp = otp.join("").trim();
          if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
            alert("Please enter a valid 4-digit OTP.");
            return;
          }
      
          // Make the API request to verify OTP
          const response = await axios.post(
            "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
            { mobilenumber: formattedNumber, otp: enteredOtp }
          );
      
          // Handle the response
          if (response.status === 200) {
            console.log("OTP Verification Response:", response.data);
            if (response.data.status === "success") {
              alert("OTP Verified Successfully!");
              setShowOtpSection(false); // Hide OTP section
              await fetchUserDetails(formattedNumber); // Fetch user details after success
            } else {
              alert(response.data.message || "Error verifying OTP.");
            }
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error verifying OTP:", error);
          alert("An error occurred while verifying OTP. Please try again.");
        }
      };
      


    
      const [selectedDistrict, setSelectedDistrict] = useState("");
 
    
      const [isResending, setIsResending] = useState(false);
    
      // Handle OTP input
      const handleChange = (value, index) => {
        if (!/^\d*$/.test(value)) return; // Allow only numbers
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
    
        // Automatically move to the next input
        if (value && index < otp.length - 1) {
          document.getElementById(`otp-input-${index + 1}`).focus();
        }
      };
    
    
      const handleBackspace = (e, index) => {
        if (e.key === "Backspace" && !otp[index] && index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
          const newOtp = [...otp];
          newOtp[index - 1] = "";
          setOtp(newOtp);
        }
      };
    


    return (
        <>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px',
                marginTop:'4%',
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight:"bold"}}>

                    {/* <h2 style={{marginLeft:"25%", marginBottom:"60px",fontWeight:"bold"}}>Lease Agreement Online</h2> */}
                    <p style={{fontSize:"2rem"}}>Police Clearance Certificate(PCC)</p>
                    {/* <p>PAN Card Services - Apply Now!"</p> */}
                </div>
                <div>
                    <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '75%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-65%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        Documents Required(Any One Addres Proof)
    </h4>
    <ul
        style={{
            display: 'grid',
          
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Existing Passport (M)</li>
        <li style={{ fontWeight: '' }}>Aadhaar card</li>
        <li style={{ fontWeight: '' }}>Bank statement</li>
        <li style={{ fontWeight: '' }}>Voter id</li>
    </ul>
</div>
                <div style={{marginLeft:'72%',marginTop:'25%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ padding:'0px', marginBottom: '0px' }}>Register online</li>
                    <li style={{ padding:'0px', marginBottom: '0px'  }}>Upload Documents</li>
                    <li style={{ padding:'0px', marginBottom: '0px'  }}>Payment</li>
                    <li style={{ padding:'0px', marginBottom: '0px'  }}>Get Appointment</li>
                    <li style={{ padding:'0px', marginBottom: '0px'  }}>Visit Psk</li>
                    <li style={{ padding:'0px', marginBottom: '0px' }}>Get Delivered</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'13%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  padding:'0px', marginBottom: '0px'  }}><strong>Rs.1000</strong></li>
                    <li style={{ padding:'0px', marginBottom: '0px'  }}> <strong>Rs.99</strong> as booking fee. Need to pay while submitting online form (This fee is non-refundable and <br/> will be adjusted in the total bill.)</li>
        {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
        top: "100px",
        left: "0",
        width: "100%",
        height: "86%",
        backgroundColor: "rgba(26, 118, 216, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 3 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 2 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
 {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
   
  
    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
      Applying for      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
    value={applyingFor}
    onChange={handleApplyingFor}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
        fontWeight:"bold"
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
        value="Citizenship"
        onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
        Citizenship/Nationality Application 
      </label>
    
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Education/Research"
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
       Education/Research 
      </label>
  
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Employment Visa/Work Permit"
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
        Employment Visa/Work Permit 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Immigration Purposes Other Than Citizenship"
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
       Immigration Purposes Other Than Citizenship 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Long Term Visa/Stay "
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
       Long Term Visa/Stay 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Residence Permit"
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
       Residence Permit 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Tourist Visa "
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
     Tourist Visa 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "600", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="Others"
          onChange={handleApplyingFor}
          style={{ marginRight: "10px" }}
        />
      Others 
      </label>
    </div>
    {error && <p style={{ color: "red" }}>{error}</p>}
  </div>
)}
{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 3 Heading */}
    <p style={{ color: "#1A76D8", fontWeight: "600" }}>
      Application Details<span style={{ color: "red" }}>*</span>
    </p>

    {/* Given Name */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="givenname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Given Name
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={fullName}
        onChange={handleFullNameChange}
        id="givernname"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Gender */}
    <div
                          style={{
                            marginBottom: "33px",
                            textAlign: "left",
                            gap: "10px",
                          }}
                        >
                          <label
                            style={{ fontSize: "16px", fontWeight: "500" }}
                          >
                            Gender
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column", // Align radio buttons in a column
                              gap: "10px", // Add space between the radio buttons
                            }}
                          >
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="Female"
                                onChange={handleSelectedGender}
                                style={{ marginRight: "10px" }}
                              />
                              Female
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="male"
                                onChange={handleSelectedGender}
                                style={{ marginRight: "10px" }}
                              />
                              Male
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="gender"
                                value="transgender"
                                onChange={handleSelectedGender}
                                style={{ marginRight: "10px" }}
                              />
                              Transgender
                            </label>
                          </div>
                        </div>

    {/* Date of Birth */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="dateOfBirth"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Date of Birth
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="date"
        id="dateOfBirth"
        value={dob}
        onChange={handleDateofbirth}
        placeholder="Enter Date of Birth"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Place of Birth */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="place"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Place of Birth (Village/ Town / City) <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="place"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {/* Employment Type */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="employmentType"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Employment Type <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="employmentType"

        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="fulltime">Government</option>
        <option value="parttime">Private</option>
        <option value="selfemployed">Student</option>
        <option value="unemployed">Homemaker</option>
        <option value="unemployed">Retired</option>
      </select>
    </div>

    {/* Education Qualification */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="educationQualification"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Education Qualification <span style={{ color: "red" }}>*</span>
      </label>
      <select
      value={qualification}
      onChange={handleQualification}
        id="educationQualification"
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      >
        <option value="">Choose</option>
        <option value="highschool">Grduate and Above</option>
        <option value="graduate">10th Pass and Above</option>
        <option value="postgraduate">Below 10th</option>
      
      </select>
    </div>
    {error && <p style={{ color: "red" }}>{error}</p>}
  </div>
)}

{currentStep === 3 && (
 <div style={{ textAlign: "center" }}>
 {/* Step 3 Heading */}
 <p style={{ color: "#1A76D8", fontWeight: "600" }}>
 Present Residential Address<span style={{ color: "red" }}>*</span>
 </p>

 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
    //  htmlFor="ownerName"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    House No. and Street Name
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     value={villageTownCity}
     onChange={handleVillageTownCityChange}
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>

 <div>
            {/* State Dropdown */}
            <div style={{ marginBottom: "20px", textAlign: "left" }}>
              <label
                htmlFor="state"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                State<span style={{ color: "red" }}>*</span>
              </label>
              <select
                id="state"
                value={selectedState}
                onChange={handleStateChange}
                style={{
                  width: "100%",
                  height: "45px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  border: "2px solid #FCA505"
                }}
              >
                <option value="">Select State</option>
                {stateData.map((stateObj, index) => (
                  <option key={index} value={stateObj.state}>
                    {stateObj.state}
                  </option>
                ))}
              </select>
             
            </div>

            {/* District Dropdown */}
            {selectedState && (
              <div style={{ marginBottom: "20px", textAlign: "left" }}>
                <label
                  htmlFor="district"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    fontSize: "16px",
                    fontWeight: "500",
                  }}
                >
                  District<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="district"
                  value={selectedDistrict}
                  onChange={handleDistrictChange}
                  style={{
                    width: "100%",
                    height: "45px",
                    fontSize: "16px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    border: "2px solid #FCA505"
                  }}
                >
                  <option value="">Select District</option>
                  {districts.map((district, index) => (
                    <option key={index} value={district}>
                      {district}
                    </option>
                  ))}
                </select>
               
              </div>
            )}
          </div>
<div style={{ marginBottom: "20px", textAlign: "left" }}>
<label
htmlFor="pincode"
style={{
  display: "block",
  marginBottom: "10px",
  fontSize: "16px",
  fontWeight: "500",
}}
>
Pin Code 
<span style={{ color: "red" }}>*</span>
</label>
<input
type="text"
id="pincode"
placeholder=""
style={{
  width: "100%",
  height: "45px",
  padding: "10px",
  fontSize: "16px",
  border: "2px solid #FCA505",
  borderRadius: "4px",
}}
/>
</div>
 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
     htmlFor=""
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    Email ID
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>

 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
    //  htmlFor="ownerName"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
   Nearest Police Station 
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     id=""
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "4px",
     }}
   />
 </div>
 <div style={{ marginBottom: "20px", textAlign: "left" }}>
   <label
     htmlFor="mobilenumber"
     style={{
       display: "block",
       marginBottom: "10px",
       fontSize: "16px",
       fontWeight: "500",
     }}
   >
    Mobile Number
     <span style={{ color: "red" }}>*</span>
   </label>
   <input
     type="text"
     id="mobileNumber"
     value={mobileNumber}
     onChange={handleMobileNumberChange}
     placeholder=""
     style={{
       width: "100%",
       height: "45px",
       padding: "10px",
       fontSize: "16px",
       border: "2px solid #FCA505",
       borderRadius: "10px",
     }}
   />
 </div>
 {error && <p style={{ color: "red" }}>{error}</p>}
 <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
       By clicking submit, you agree to our{" "}
       <a   href="/terms-conditions" style={{ color: "#007BFF", textDecoration: "underline" }}>
         Terms & Conditions
       </a>{" "}
       and{" "}
       <a
         href="/privacy-policy"
         style={{ color: "#007BFF", textDecoration: "underline" }}
       >
         Privacy Policy
       </a>.
     </p>
</div>
)}



         </div>
         <div   style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>

{currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}
          
           {currentStep < 3 ? (
             <button
               onClick={nextStep}
               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
             <button
             onClick= {handleSubmit}
              style={{
                padding: "10px 20px",
                backgroundColor: "FCA505",
                color: "#000000",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              SUBMIT
             </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
            <div>
            <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
              OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
            </h4>
            <div style={{ margin: "20px 0" }}>
              <label style={{ fontWeight: "500", marginBottom: "10px" }}>
                Enter OTP <span style={{ color: "red" }}>*</span>
              </label>
              <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e.target.value, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{
                      width: "50px",
                      height: "50px",
                      textAlign: "center",
                      fontSize: "18px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                    }}
                  />
                ))}
              </div>
            </div>
          
            <div style={{ marginTop: "20px", textAlign: "center" }}>
          
              <p style={{ fontSize: "14px", color: "#888" }}>
                  {resendCountdown > 0 ? (
                    <>Not Received? Resend in {resendCountdown}s</>
                  ) : (
                    <a
                      href="#"
                      onClick={handleResend}
                      style={{
                        textDecoration: "none",
                        color: isResending ? "#ccc" : "#007BFF",
                        pointerEvents: isResending ? "none" : "auto",
                      }}
                    >
                      Resend OTP
                    </a>
                  )}
                </p>
            </div>
          
            <button
              onClick={handleVerify}
              style={{
                backgroundColor: "#FFA500",
                color: "#000",
                border: "none",
                borderRadius: "5px",
                padding: "10px 20px",
                fontSize: "16px",
                cursor: "pointer",
              }}
            >
              VERIFY
            </button>
          </div>
          ) : (
            <>
            <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
            <div style={styles.infoBox}>
              <div style={styles.inputGroup}>
                <label style={styles.label}> Name:</label>
                <input
                  type="text"
                  value={userDetails?.name || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
             
              <div style={styles.inputGroup}>
                <label style={styles.label}>Mobile Number:</label>
                <input
                  type="text"
                  value={userDetails?.mobilenumber || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Order ID:</label>
                <input
                  type="text"
                  value={orderid}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
            <label style={styles.label}>Services:</label>
            <input
              type="text"
              value={applyingFor}
              readOnly
              style={styles.input}
            />
          </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Payment Amount:</label>
                <input
                  type="text"
                  value="₹99"
                  readOnly
                  style={styles.input}
                />
              </div>
            </div>
            <button
              onClick={handleProceedToPay}
              style={styles.proceedButton}
            >
              Proceed to Pay
            </button>
       
          </>
          )}
        </div>
         )}

         {/* Close Button */}
         <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
       </div>
     </div>
   )}
 </div>


<br/>
        
        
        </>
    );
};

const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    flex: '2',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginLeft: '10px',
    width: '45%', 
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default PoliceClearance;
