import React, { useState } from 'react';
import Image30 from '../../images/passport_image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import TimeIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg'
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';
import howIcon from '../../images/how.svg';


const stateData = [
  {
    state: "Andhra Pradesh",
    districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
  },
  {
    state: "Arunachal Pradesh",
    districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
  },
  {
    state: "Assam",
    districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
  },
  {
    state: "Bihar",
    districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  },
  {
    state: "Chhattisgarh",
    districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
  },
  {
    state: "Goa",
    districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  },
  {
    state: "Haryana",
    districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
  },
  {
    state: "Himachal Pradesh",
    districts: ["Shimla", "Manali", "Kullu", "Kangra"],
  },
  {
    state: "Jharkhand",
    districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
  },
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },
  {
    state: "Kerala",
    districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
  },
  {
    state: "Madhya Pradesh",
    districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
  },
  {
    state: "Maharashtra",
    districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  },
  {
    state: "Manipur",
    districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
  },
  {
    state: "Meghalaya",
    districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
  },
  {
    state: "Mizoram",
    districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
  },
  {
    state: "Nagaland",
    districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
  },
  {
    state: "Odisha",
    districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
  },
  {
    state: "Punjab",
    districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
  },
  {
    state: "Rajasthan",
    districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
  },
  {
    state: "Sikkim",
    districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
  },
  {
    state: "Tamil Nadu",
    districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
  },
  {
    state: "Telangana",
    districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
  },
  {
    state: "Tripura",
    districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
  },
  {
    state: "Uttar Pradesh",
    districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
  },
  {
    state: "Uttarakhand",
    districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
  },
  {
    state: "West Bengal",
    districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
  },
  {
    state: "Andaman and Nicobar Islands",
    districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
  },
  {
    state: "Chandigarh",
    districts: ["Chandigarh"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    districts: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Lakshadweep",
    districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
  },
  {
    state: "Delhi",
    districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
  },
  {
    state: "Puducherry",
    districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  },
];

const Passport = () => {
  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');
const [fatherName, setFatherName]=useState('');
const[motherName, setMotherName]=useState('');
const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');
const [qualification,setQualification]=useState('');
const [selectedState, setSelectedState] = useState('');
const [applyingFor, setApplyingFor]=useState('');
// const [userData, setUserData] = useState(null); 
const [error, setError] = useState("");

const [showOtpSection, setShowOtpSection] = useState(true); 
const[typeofapplication, setTypeOfApplication]=useState(true);
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };
const [typeofbooklet, setTypeOfBooklet]=useState('')
const [registrationNumber, setSeletedRegistrationNumber]=useState('');
const handleDob =(e) => setDob(e.target.value);
const handleQualification=(e)=>setQualification
(e.target.value)
const handleMaritalStatus =(e) => setMaritalStatus(e.target.value)
    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleTypeOfBooklet =(e)=> setTypeOfBooklet(e.target.value)
    const handleMotherName =(e)=>setMotherName(e.target.value)
    const handleFatherName=(e)=>setFatherName(e.target.value)
    const handleGender =(e)=>setSelectedGender(e.target.value)
    const handleTypeOfApplication =(e) => setTypeOfApplication(e.target.value)
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
    const handleStateChange = (e) => setSelectedState(e.target.value);
    const handleDistrictChange = (e) => setSelectedDistrict(e.target.value);
    const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);
    const handleApplyingFor =(e) =>setApplyingFor(e.target.value)
    const [applicationType, setApplicationType] = useState('');
    const [passportBookletType, setPassportBookletType] = useState('');
    const [educationQualification, setEducationQualification] = useState('');
    const [employmentType, setEmploymentType] = useState('');
    const [maritalStatus, setMaritalStatus] = useState('');
    // const [maritalstatus, setMaritalStatus]=useState("");
    const [gender, setGender] = useState('');
    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };

    const [spouseName, setSpouseName] = useState("");
  const [errorMessages, setErrorMessages] = useState({});
  
  const selectedStateData = stateData.find(
    (stateObj) => stateObj.state === selectedState
  );
  const districts = selectedStateData ? selectedStateData.districts : [];

    // Navigate steps
    const validateStep = () => {
      let errors = {};
      switch (currentStep) {
        case 1:
          const applyingForOptions = document.getElementsByName("agreementOption");
          if (!Array.from(applyingForOptions).some(option => option.checked)) {
            errors.applyingFor = "Please select an option.";
          }
          break;
        case 2:
          case 2:
            if (!applicationType) {
              errors.applicationType = "Please select a type of application.";
            }
            if (!passportBookletType) {
              errors.passportBookletType = "Please select a type of passport booklet.";
            }
            if (!gender) {
              errors.gender = "Please select your gender.";
            }
            break;
        
        case 3:
          if (!fullName) {
            errors.fullName = "Given Name is required.";
          }
          if (!dob) {
            errors.dob = "Date of Birth is required.";
          }
          if (!document.getElementById("place").value) {
            errors.place = "Place of Birth is required.";
          }
          break;
          case 4:
            if (!educationQualification) {
              errors.qualification = "Please select your education qualification.";
            }
            if (!employmentType) {
              errors.employment = "Please select your employment type.";
            }
            break;
            case 5:
              if (!maritalStatus) {
                errors.maritalStatus = "Please select your marital status.";
              }
              break;
        case 6:
          if (!fatherName) {
            errors.fatherName = "Father's Given Name is required.";
          }
          if (!motherName) {
            errors.motherName = "Mother's Given Name is required.";
          }
          break;
        case 7:
          if (!villageTownCity) {
            errors.villageTownCity = "House No. and Street Name is required.";
          }
          if (!selectedState) {
            errors.selectedState = "State is required.";
          }
          if (!selectedDistrict) {
            errors.selectedDistrict = "District is required.";
          }
          if (!document.getElementById("pincode").value) {
            errors.pincode = "Pin Code is required.";
          }
          if (!emailId) {
            errors.emailId = "Email ID is required.";
          }
          if (!mobileNumber) {
            errors.mobileNumber = "Mobile Number is required.";
          }
          break;
        default:
          break;
      }
      return errors;
    };
  
    const nextStep = () => {
      const errors = validateStep();
      if (Object.keys(errors).length === 0) {
        setErrorMessages({});
        if (currentStep < 7) setCurrentStep(currentStep + 1);
      } else {
        setErrorMessages(errors);
      }
    };
  
  
    const prevStep = () => {
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };
    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };


    const [otpSent, setOtpSent] = useState(false);

    const handleSendOtp = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
          mobilenumber: formattedNumber,
        });
    
        if (response.status === 200) {
          console.log("API Response:", response.data);
          if (response.data.status === "success") {
          
            setOtpSent(true);
            setResendCountdown(30); 
          } else {
            alert(response.data.message || "Error sending OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
        alert("An error occurred while sending OTP. Please try again.");
      }
    };
    
    React.useEffect(() => {
      if (resendCountdown > 0 && otpSent) {
        const timer = setInterval(() => {
          setResendCountdown((prev) => prev - 1);
        }, 1000);
        return () => clearInterval(timer);
      } else if (resendCountdown === 0) {
        setOtpSent(false); // Reset otpSent when countdown reaches zero
      }
    }, [resendCountdown, otpSent]);


    const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    
    const [userDetails, setUserData] = useState(null); // Holds user details
    const [orderid, setOrderID] = useState(() => generateOrderId()); 

    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; // Generates dynamic CUST_ID
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; // Add 350 to the existing amount
    
      const requestBody = {
        ORDER_ID: orderid, // Uses the generated order ID
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Redirect to payment gateway if payment URL is provided
          if (response.data?.paymentUrl) {
            window.location.href = response.data.paymentUrl;
          } else {
            alert(
              "Payment request was successful, but no redirect URL was provided."
            );
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };

    const [isResending, setIsResending] = useState(false);
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };
    const handleVerify = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        
        // Validate the mobile number format (10 digits, no country code check here)
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        
        // Add country code (only if needed)
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        // Validate OTP
        const enteredOtp = otp.join("").trim();
        if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
          alert("Please enter a valid 4-digit OTP.");
          return;
        }
    
        // Make the API request to verify OTP
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
          { mobilenumber: formattedNumber, otp: enteredOtp }
        );
    
        // Handle the response
        if (response.status === 200) {
          console.log("OTP Verification Response:", response.data);
          if (response.data.status === "success") {
            alert("OTP Verified Successfully!");
            setShowOtpSection(false); // Hide OTP section
            await fetchUserDetails(formattedNumber); // Fetch user details after success
          } else {
            alert(response.data.message || "Error verifying OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        alert("An error occurred while verifying OTP. Please try again.");
      }
    };
    
    


    const [selectedDistrict, setSelectedDistrict] = useState("");
    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        email: emailId || "",
        services: selectedOption || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        insurance_registration_number: registrationNumber || "",
        paidamount: "0.00",
        qualification: qualification ||"", 
        typeofapplication: typeofapplication ||"",
        applying_for:applyingFor ||"",
        gender: selectedGender || "", 
        fathername: fatherName || "", 
        mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        maritalstatus: maritalStatus||"",
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "TwoWheeler Insurance",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };
  



      const faqs = [
        {
            question: "Who qualifies for an Indian passport?",
            // answer: (
            //   <ul style={{ listStyleType: 'disc' }}>
            //     <li>Minimizes the conflicts between a tenant and the owner</li>
            //     <li>Rental/lease agreement acts as an address proof</li>
            //     <li>Acts as a proof for Bank loans</li>
            //     <li>Helps in investment & loan</li>
            //     <li>Vehicle registration</li>
            //   </ul>
            // ),
            answer:"You can qualify for Indian citizenship by being born in the country, being born elsewhere but with at least one Indian parent, or by being granted citizenship through a naturalization process. If none of these categories apply you will not be classed as an Indian citizen, and will not be entitled to an Indian passport.",
          },
        {
            question: "What is Ordinary, Diplomatic or Official Passport?",
            answer: "All private citizens apply for an ordinary passport however the other two types of passports are for government workers who are being send overseas on official business only.",
        },
        {
            question: "What is validity of passport?",
            answer: "05 years for minor 10 years for adults",
        },
        {
            question: "What is the different between normal and tatkal application?",
            answer: (
                <ul style={{ listStyleType: 'none' }}>
                  <li>In normal application you will receive your passport within 10 to 15 working days</li>
                  <br/>
                  <li>In tatkal application you will receive your passport within 2 to 5 working days</li>
                 
                </ul>
              ),
        },
        {
            question: "Within how many days will I get the appointment?",
            answer: "Its depends on the demand in market, our executive will give 3 to 4 days’ options you can book according to your calendar.",
        },
        {
            question: "Is that possible to get the appointment in weekends?",
            answer: "No appointments are booked only in weekdays.",
        },
        {
            question: "What happens if I missed the passport appointment?",
            answer: "If you missed your 1st appointment still you have 2 more chance to reschedule",
        },
        {
            question: "Can someone else attend my passport appointment for me?",
            answer: "You have to attend in person for your passport interview, even if you are ill or if it is very inconvenient for you to do so. You are allowed to bring someone with you for your passport interview if you find it difficult to travel.",
        },
        {
            question: "When the police verification will happen?",
            answer: "After the appointment, police verification will happen at your nearest police station.",
        },
        {
            question: "How will I get the passport?",
            answer: "Passport will be dispatched through Indian speed post for your address.",
        },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
        <div>
            <div style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                // minHeight: '40%',
                // paddingTop: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 20px'
            }}>
                <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
                    <p>"Ready to Explore the World? Get Expert</p>
                    <p>Assistance for Your Passport Application Today!"</p>
                </div>
                <div>
                    <img src={Image30} alt="Rental Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>
            </div>


            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '-1% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '105%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>
                         <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '32%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '45%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '35%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={TimeIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>



                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '45%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             <div
    className="mb-5"
    style={{
        marginTop: '-130%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
      Documents Required For Fresh Passport
    </h4>
    {/* First List */}
    <ul
      style={{
        listStyleType: 'none',
        paddingLeft: '20px',
        whiteSpace:'nowrap',
        margin: '20px 0',
      }}
    >
      <li style={{ fontWeight:'bold' }}>Proof of Identity (Any 01)</li>
      <li>Aadhar Card</li>
      <li>Voter ID</li>
      <li>Pan Card</li>
      <li>Driving Licence</li>
    </ul>

    {/* Second List */}
    <ul
      style={{
        listStyleType: 'none',
        paddingLeft: '20px',
        whiteSpace:'nowrap',
        margin: '20px 0',
      }}
    >
      <li style={{ fontWeight:'bold' }}>Proof of Address (Any 01)</li>
      <li>Aadhar Card</li>
      <li>Voter ID</li>
      <li>Bank Account Passbook</li>
      <li>Gas Connection Bill / Electricity Bill / Telephone Bill / Water Bill</li>
      <li>Parents Passport / Spouse Passport</li>
    </ul>

    {/* Third List */}
    <ul
      style={{
        listStyleType: 'none',
        paddingLeft: '20px',
        whiteSpace:'nowrap',
        margin: '20px 0',
      }}
    >
      <li style={{ fontWeight:'bold' }}>Proof of Birth (Any 01)</li>
      <li>10th Certificate</li>
      <li>12th Certificate</li>
      <li>Higher Education Pass Certificate</li>
      <li>School Leaving Certificate</li>
      <li>Income Tax Assessment Order</li>
    </ul>
</div>
<div
    className="mb-5"
    style={{
        marginTop: '-5%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
      Document Required for Renewal / Reissue of Passport
    </h4>
    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Original Old Passport</li>
        <li style={{ fontWeight: '' }}>Id And Present Address Proof</li>
       
    
    </ul>
</div>
<div style={{marginLeft:'72%',marginTop:'35%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Document Required for Minor Passport</h4>
                    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Birth Certificate</li>
        <li style={{ fontWeight: '' }}>Both Parents Passport</li>
       
    
    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'50%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>Register Online & Make Payment</li>
        <li style={{ fontWeight: '' }}>Upload Documents</li>
        <li style={{ fontWeight: '' }}>Get Appointment</li>
        <li style={{ fontWeight: '' }}>Visit Psk</li>
        <li style={{ fontWeight: '' }}>Get Delivered</li>
    
    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'12%'}}>
                    <h4 style={{  display: 'grid', color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ fontWeight: '' }}>15-20 working days (Normal)</li>
        <li style={{ fontWeight: '' }}>5-10 working days (Tatkal)</li>
       
    
    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'35%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
      <li style={{}}>
    <strong>Rs. 2,499</strong> For (Normal Application)
</li>

  <li style={{ fontWeight: '' }}>
  <strong>Rs. 4,499</strong>  For (Tatkal Application)
  </li>
  <li style={{ fontWeight: '' }}>
  <strong>Rs.99</strong>  as booking fee. Need to pay while submitting online form (This fee is non-refundable <br/>and will be adjusted in the total bill.)
  </li>
    
    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", 
           overflowY: "auto", 
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 7 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
        {/* Optional Step Labels */}
        {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step {index + 1}</span> */}
      </div>

      {/* Add Connection Divider Between Steps */}
      {index < 6 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
 {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
  
    <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
Applying For
      <span style={{ color: "red" }}>*</span>
    </h5>

    {/* Radio Buttons */}
    <div
    // value={applyingFor}
    // onChange={handleApplyingFor}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft: "4%",
        width: "fit-content",
        gap: "20px",
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="flatHouseRentalAgreement"
          style={{ marginRight: "10px" }}
        />
    Fresh Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Re-issue of Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Change In Existing Personal Particulars 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
       Damaged Passport 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Exhaustion Of Pages  
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Lost Passport  
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Validity Expired More Than 3 Years Ago 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="agreementOption"
          value="commercialOfficeShopRentalAgreement"
          style={{ marginRight: "10px" }}
        />
        Validity Expired Within 3 Years/Due To Expire
      </label>
    </div>
    {errorMessages.applyingFor && <p style={{ color: 'red' }}>{errorMessages.applyingFor}</p>}
  </div>
)}
 {currentStep === 2 && (
        <div style={{ textAlign: "center" }}>
          <h5 style={{ color: "#007BFF", fontWeight: "bold" }}>
            Application Details
            <span style={{ color: "red" }}>*</span>
          </h5>

          {/* Type of Application */}
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "4%", width: "fit-content", gap: "20px" }}>
            <h5 style={{ fontWeight: "600", fontSize:'22px' }}>Type of Application</h5>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="applicationType"
                value="normal"
                onChange={(e) => setApplicationType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Normal
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="applicationType"
                value="tatkal"
                onChange={(e) => setApplicationType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Tatkal
            </label>
            {errorMessages.applicationType && <p style={{ color: 'red' }}>{errorMessages.applicationType}</p>}
          </div>
          <br/>
          {/* Type of Passport Booklet */}
          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "4%", width: "fit-content", gap: "20px" }}>
            <h5 style={{ fontWeight: "600", fontSize:'22px' }}>Type of Passport Booklet</h5>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="passportBookletType"
                value="36Pages"
                onChange={(e) => setPassportBookletType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              36 Pages
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="passportBookletType"
                value="60Pages"
                onChange={(e) => setPassportBookletType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              60 Pages
            </label>
            {errorMessages.passportBookletType && <p style={{ color: 'red' }}>{errorMessages.passportBookletType}</p>}
          </div>
          <br/>
         <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "4%", width: "fit-content", gap: "20px" }}>
            <h5 style={{ fontWeight: "600", fontSize:'22px'}}>Gender<span style={{ color: "red" }}>*</span></h5>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="gender"
                value="female"
                onChange={(e) => setGender(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Female
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="gender"
                value="male"
                onChange={(e) => setGender(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Male
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="gender"
                value="transgender"
                onChange={(e) => setGender(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Transgender
            </label>
            {errorMessages.gender && <p style={{ color: 'red' }}>{errorMessages.gender}</p>}
          </div>
        </div>
      )}
{currentStep === 3 && (
  <div style={{ textAlign: "center" }}>
 
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="givenname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Given Name 
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={fullName}
        onChange={handleFullNameChange}
        id="givernname"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="surname"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Surname
      </label>
      <input
        type="text"
        id="ownerFatherName"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    <div style={{ marginBottom: "20px", textAlign: "left" }}>
  <label
    htmlFor="dateOfBirth"
    style={{
      display: "block",
      marginBottom: "10px",
      fontSize: "16px",
      fontWeight: "500",
    }}
  >
    Date of Birth
    <span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="date"
    id="dateOfBirth"
    value={dob}
    onChange={handleDob}
    placeholder="Enter Date of Birth"
    style={{
      width: "100%",
      height: "45px",
      padding: "10px",
      fontSize: "16px",
      border: "2px solid #FCA505",
      borderRadius: "4px",
    }}
  />
</div>
<div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="place"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Place of Birth (Village/ Town / City) *
      </label>
      <input
        type="text"
        id="place"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
    </div>

    {errorMessages.place && <p style={{ color: 'red' }}>{errorMessages.place}</p>}
    
  </div>
)}
 {currentStep === 4 && (
        <div style={{ textAlign: "center" }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "4%",
            width: "fit-content",
            gap: "20px",
          }}>
            <p style={{ fontWeight: "600" ,fontSize:'18px'}}>Education Qualification</p>

            {/* Education Qualification Radio Buttons */}
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="educationQualification"
                value="graduateAndAbove"
                onChange={(e) => setEducationQualification(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Graduate And Above
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="educationQualification"
                value="10thPassAndAbove"
                onChange={(e) => setEducationQualification(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              10th Pass And Above
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="educationQualification"
                value="below10th"
                onChange={(e) => setEducationQualification(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Below 10th
            </label>

            <p style={{ fontWeight: "600",fontSize:'18px' }}>Employment Type</p>

            {/* Employment Type Radio Buttons */}
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="government"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Government
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="private"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Private
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="selfEmployed"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Self Employed
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="student"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Student
 </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="homeMaker"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Home Maker
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="employmentType"
                value="retired"
                onChange={(e) => setEmploymentType(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Retired
            </label>

            {errorMessages.qualification && <p style={{ color: 'red' }}>{errorMessages.qualification}</p>}
            {errorMessages.employment && <p style={{ color: 'red' }}>{errorMessages.employment}</p>}
          </div>
        </div>
)}
{currentStep === 5 && (
        <div style={{ textAlign: "center" }}>
          <div style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginLeft: "4%",
            width: "fit-content",
            gap: "20px",
          }}>
            <p style={{ color: "#1A76D8", fontWeight: "600" }}>
              Marital Status <span style={{ color: "red" }}>*</span>
            </p>

            {/* Marital Status Radio Buttons */}
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="maritalStatus"
                value="single"
                onChange={(e) => setMaritalStatus(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Single
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="maritalStatus"
                value="married"
                onChange={(e) => setMaritalStatus(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Married
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="maritalStatus"
                value="widowWidower"
                onChange={(e) => setMaritalStatus(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Widow/Widower
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="maritalStatus"
                value="separated"
                onChange={(e) => setMaritalStatus(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Separated
            </label>
            <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
              <input
                type="radio"
                name="maritalStatus"
                value="divorced"
                onChange={(e) => setMaritalStatus(e.target.value)}
                style={{ marginRight: "10px" }}
              />
              Divorced
            </label>
          </div>
          {errorMessages.maritalStatus && <p style={{ color: 'red' }}>{errorMessages.maritalStatus}</p>}
        </div>
      )}
{currentStep === 6 && (
  <div style={{ textAlign: "center" }}>
    <div style={{ marginBottom: "20px" }}>
      <label style={{ fontSize: "20px", fontWeight: "600" }}>
        Present Residential Address<span style={{ color: "red" }}>*</span>
      </label>
    </div>

    {/* Father's Given Name */}
    <div style={{ marginBottom: "40px", textAlign: "left", marginTop: "26px" }}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
        Father's Given Name<span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={fatherName}
        onChange={handleFatherName}
        placeholder=""
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
      {errorMessages.fatherName && <p style={{ color: 'red' }}>{errorMessages.fatherName}</p>}
    </div>

    {/* Mother's Given Name */}
    <div style={{ marginBottom: "40px", textAlign: "left" }}>
      <label style={{ fontSize: "16px", fontWeight: "bold" }}>
        Mother's Given Name <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        value={motherName}
        onChange={handleMotherName}
        placeholder=""
        style={{
          width: "100%",
          padding: "10px",
          marginTop: "10px",
          border: "1px solid orange",
          borderRadius: "4px",
        }}
      />
      {errorMessages.motherName && <p style={{ color: 'red' }}>{errorMessages.motherName}</p>}
    </div>

    {/* Spouse's Given Name - Conditionally Rendered */}
    {maritalStatus !== "single" &&
      maritalStatus !== "widowWidower" &&
      maritalStatus !== "divorced" && (
        <div style={{ marginBottom: "40px", textAlign: "left" }}>
          <label style={{ fontSize: "16px", fontWeight: "bold" }}>
            Spouse's Given Name <span style={{ color: "red" }}>*</span>
          </label>
          <input
            type="text"
            value={spouseName}
            onChange={(e) => setSpouseName(e.target.value)}
            placeholder=""
            style={{
              width: "100%",
              padding: "10px",
              marginTop: "10px",
              border: "1px solid orange",
              borderRadius: "4px",
            }}
          />
        </div>
      )}
  </div>
)}

{currentStep === 7 && (
         <div style={{ textAlign: "center" }}>
         {/* Step 3 Heading */}
         <p style={{ color: "#1A76D8", fontWeight: "600" }}>
         Present Residential Address<span style={{ color: "red" }}>*</span>
         </p>
        
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            House No. and Street Name
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             value={villageTownCity}
             onChange={handleVillageTownCityChange}
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
              {errorMessages.villageTownCity && <p style={{ color: 'red' }}>{errorMessages.villageTownCity}</p>}
         </div>

     
       

    
         <div>
      {/* State Dropdown */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="state"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          State<span style={{ color: "red" }}>*</span>
        </label>
        <select
          id="state"
          value={selectedState}
          onChange={handleStateChange}
          style={{
            width: "100%",
            height: "45px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        >
          <option value="">Select State</option>
          {stateData.map((stateObj, index) => (
            <option key={index} value={stateObj.state}>
              {stateObj.state}
            </option>
          ))}
        </select>
        {errorMessages.selectedState && <p style={{ color: 'red' }}>{errorMessages.selectedState}</p>}
      </div>

      {/* District Dropdown */}
      {selectedState && (
        <div style={{ marginBottom: "20px", textAlign: "left" }}>
          <label
            htmlFor="district"
            style={{
              display: "block",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            District<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="district"
            value={selectedDistrict}
            onChange={handleDistrictChange}
            style={{
              width: "100%",
              height: "45px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <option value="">Select District</option>
            {districts.map((district, index) => (
              <option key={index} value={district}>
                {district}
              </option>
            ))}
          </select>
          {errorMessages.selectedDistrict && <p style={{ color: 'red' }}>{errorMessages.selectedDistrict}</p>}
        </div>
      )}
    </div>
    
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="pincode"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Pin Code 
        <span style={{ color: "red" }}>*</span>
      </label>
      <input
        type="text"
        id="pincode"
        placeholder=""
        style={{
          width: "100%",
          height: "45px",
          padding: "10px",
          fontSize: "16px",
          border: "2px solid #FCA505",
          borderRadius: "4px",
        }}
      />
        {errorMessages.pincode && <p style={{ color: 'red' }}>{errorMessages.pincode}</p>}
    </div>
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
             htmlFor=""
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Email ID
           </label>
           <input
             type="text"
             value={emailId}
             onChange={handleEmailIdChange}
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
             {errorMessages.emailId && <p style={{ color: 'red' }}>{errorMessages.emailId}</p>}
         </div>
     
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
           Nearest Police Station 
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "4px",
             }}
           />
         </div>
         <div style={{ marginBottom: "20px", textAlign: "left" }}>
           <label
            //  htmlFor="ownerName"
             style={{
               display: "block",
               marginBottom: "10px",
               fontSize: "16px",
               fontWeight: "500",
             }}
           >
            Mobile Number
             <span style={{ color: "red" }}>*</span>
           </label>
           <input
             type="text"
             id=""
             value={mobileNumber}
            onChange={handleMobileNumberChange}
             placeholder=""
             style={{
               width: "100%",
               height: "45px",
               padding: "10px",
               fontSize: "16px",
               border: "2px solid #FCA505",
               borderRadius: "10px",
             }}
           />
              {errorMessages.mobileNumber && <p style={{ color: 'red' }}>{errorMessages.mobileNumber}</p>}
         </div>
     
         <p
                          style={{
                            marginTop: "20px",
                            fontSize: "14px",
                            textAlign: "left",
                          }}
                        >
                          By clicking submit, you agree to our{" "}
                          <a
                            href="/terms-conditions"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Terms & Conditions
                          </a>{" "}
                          and{" "}
                          <a
                            href="/privacy-policy"
                            style={{
                              color: "#007BFF",
                              textDecoration: "underline",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
       </div>
)}

         </div>
         <div style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>
                       {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}
          
           {currentStep < 7 ? (
             <button
               onClick={nextStep}
              // onClick={()=>console.log("test009897", )}

               style={{
                 padding: "10px 20px",
                 backgroundColor: "#FCA505",
                 color: "#000000",
                 border: "none",
                 borderRadius: "5px",
                 cursor: "pointer",
               }}
             >
               Next
             </button>
           ) : (
            <button
                onClick={() => {
                  if (!mobileNumber) {
                    setError("Mobile number is required."); 
                    return;
                  }
                  handleSendOtp(); 
                  finishSubmission();
                }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  SUBMIT
                </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
              <div>
              <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
                OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
              </h4>
              <div style={{ margin: "20px 0" }}>
                <label style={{ fontWeight: "500", marginBottom: "10px" }}>
                  Enter OTP <span style={{ color: "red" }}>*</span>
                </label>
                <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  {otp.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      value={digit}
                      onChange={(e) => handleChange(e.target.value, index)}
                      onKeyDown={(e) => handleBackspace(e, index)}
                      style={{
                        width: "50px",
                        height: "50px",
                        textAlign: "center",
                        fontSize: "18px",
                        border: "1px solid #ccc",
                        borderRadius: "5px",
                      }}
                    />
                  ))}
                </div>
              </div>
            
              <div style={{ marginTop: "20px", textAlign: "center" }}>
            
                <p style={{ fontSize: "14px", color: "#888" }}>
                    {resendCountdown > 0 ? (
                      <>Not Received? Resend in {resendCountdown}s</>
                    ) : (
                      <a
                        href="#"
                        onClick={handleResend}
                        style={{
                          textDecoration: "none",
                          color: isResending ? "#ccc" : "#007BFF",
                          pointerEvents: isResending ? "none" : "auto",
                        }}
                      >
                        Resend OTP
                      </a>
                    )}
                  </p>
              </div>
            
              <button
                onClick={handleVerify}
                style={{
                  backgroundColor: "#FFA500",
                  color: "#000",
                  border: "none",
                  borderRadius: "5px",
                  padding: "10px 20px",
                  fontSize: "16px",
                  cursor: "pointer",
                }}
              >
                VERIFY
              </button>
            </div>
          ) : (
    <>
      <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
      <div style={styles.infoBox}>
        <div style={styles.inputGroup}>
          <label style={styles.label}> Name:</label>
          <input
            type="text"
            value={userDetails?.name || "N/A"}
            readOnly
            style={styles.input}
          />
        </div>
       
        <div style={styles.inputGroup}>
          <label style={styles.label}>Mobile Number:</label>
          <input
            type="text"
            value={userDetails?.mobilenumber || "N/A"}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Order ID:</label>
          <input
            type="text"
            value={orderid}
            readOnly
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
      <label style={styles.label}>Services:</label>
      <input
        type="text"
        value={selectedOption}
        readOnly
        style={styles.input}
      />
    </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Payment Amount:</label>
          <input
            type="text"
            value="₹99"
            readOnly
            style={styles.input}
          />
        </div>
      </div>
      <button
        onClick={handleProceedToPay}
        style={styles.proceedButton}
      >
        Proceed to Pay
      </button>
 
    </>
          )}
        </div>
         )}

         {/* Close Button */}
         <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
       </div>
     </div>
   )}
 </div>

 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
<br></br>
      <>
      <p style={{color:'#1a76d8', fontSize:'24px'}}><strong>Comprehensive Passport Services - Make My Documents</strong></p>
<p>Are you planning an international journey and need a reliable passport agent to help you navigate the process? Look no further than <strong>Make My Documents</strong>. Our team of experienced passport agents is dedicated to providing you with fast, efficient, and hassle-free passport services. Whether you're planning a vacation, studying abroad, or relocating to another country, we are here to assist you in obtaining your passport quickly and easily.</p>

<p><strong>Why Choose Our Passport Services?</strong></p>
<p>At <strong>Make My Documents</strong>, we understand the importance of having a valid passport for international travel. Our team specializes in a wide range of passport services to meet your needs, including:</p>

<p><strong>New Passport Applications:</strong> Starting a new chapter abroad? We help you get your new passport application right the first time.</p>
<p><strong>Passport Renewals:</strong> Is your passport nearing its expiration date? We offer swift passport renewal services to ensure your travel plans remain uninterrupted.</p>
<p><strong>Passport Corrections:</strong> Need to correct errors in your passport? We handle passport corrections efficiently to prevent any travel complications.</p>
<p><strong>Lost or Stolen Passport Replacement:</strong> If you've lost your passport or it has been stolen, we can help you navigate the process of getting a replacement.</p>
<p><strong>Name Change on Passport:</strong> Have you changed your name recently? We assist with updating your passport to reflect your new name.</p>
<p><strong>Child Passport Applications:</strong> Traveling with children? We guide you through the specific requirements for obtaining a child’s passport.</p>
<p><strong>Passport Expedite Services:</strong> Need your passport urgently? Our expedited passport services ensure you receive your passport as quickly as possible.</p>

<p><strong>Expertise You Can Rely On</strong></p>
<p>Navigating the latest passport regulations and requirements can be challenging. Our team of experienced passport agents stays up-to-date with the latest changes, ensuring your application meets all necessary criteria. We handle every detail of your passport application process, from filling out the forms accurately to submitting your application to the passport office on your behalf.</p>

<p><strong>Exceptional Customer Service</strong></p>
<p>At <strong>Make My Documents</strong>, we pride ourselves on providing exceptional customer service. We understand that the passport application process can be overwhelming, and we are here to make it as easy and stress-free as possible. Our personalized guidance and support throughout the application process ensure that you have a seamless experience from start to finish.</p>

<p><strong>Fast and Efficient Turnaround Times</strong></p>
<p>We know that time is of the essence when it comes to obtaining a passport. Our fast turnaround times mean you can have your passport in hand as soon as possible, allowing you to focus on planning your trip. Whether you need a passport for an upcoming vacation, a business trip, or any other reason, we work diligently to expedite your application.</p>

<p><strong>Competitive Pricing</strong></p>
<p>Obtaining a passport shouldn’t break the bank. At <strong>Make My Documents</strong>, we offer competitive pricing for all our passport services. We believe in providing high-quality services at affordable rates, ensuring that you receive the best value for your money.</p>

<p>If you need passport services, contact <strong>Make My Documents</strong>. Our experienced agents are ready to help you embark on your next international adventure. Schedule an appointment today and take the first step towards obtaining your passport.</p>

<p><strong>Types of Indian Passports: Your Comprehensive Guide to Easy Applications</strong></p>
<p>Are you looking to apply for an Indian passport? Navigating through the various types of passports and understanding the application process can be daunting. At <strong>Make My Documents</strong>, we simplify the process for you, ensuring a hassle-free experience from start to finish. Whether you need a passport for personal travel, business trips, or diplomatic purposes, we’ve got you covered. Here’s everything you need to know about the different types of Indian passports.</p>

<p><strong>Ordinary Passport (Blue Passport)</strong></p>
<p>The Ordinary Passport, also known as the Blue Passport, is the most common type issued to Indian citizens. It's ideal for regular international travel, including vacations, business trips, and student exchanges. With a validity of 10 years, it offers convenience for frequent travelers.</p>

<p><strong>Key Features:</strong></p>
<ul>
  <li>Suitable for general travel purposes</li>
  <li>Valid for 10 years</li>
  <li>Available for renewal upon expiry</li>
</ul>

<p><strong>Official Passport (White Passport)</strong></p>
<p>The Official Passport, or White Passport, is issued to individuals representing the Indian government on official business abroad. This includes government employees, delegates, and officials attending international conferences.</p>

<p><strong>Key Features:</strong></p>
<ul>
  <li>Issued for official government travel</li>
  <li>Validity varies based on the assignment duration</li>
  <li>Streamlined application process through government channels</li>
</ul>

<p><strong>Diplomatic Passport (Maroon Passport)</strong></p>
<p>The Diplomatic Passport is reserved for Indian diplomats and consular staff, as well as their families. This passport facilitates diplomatic duties and provides certain privileges and immunities in foreign countries.</p>

<p><strong>Key Features:</strong></p>
<ul>
  <li>Exclusively for diplomats and consular officials</li>
  <li>Offers diplomatic immunity and privileges</li>
  <li>Validity aligns with the tenure of the diplomatic assignment</li>
</ul>

<p>At <strong>Make My Documents</strong>, we understand the complexities involved in obtaining an Indian passport. Our expert team is dedicated to providing you with personalized assistance, ensuring a smooth and efficient application process.</p>

      </>
        </div>

        
     
        </>
    );
};

const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    flex: '2',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '16px',
    marginLeft: '10px',
    width: '45%', 
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};


export default Passport;
