import React, { useState , useEffect} from 'react';
import Image30 from '../../images/food-image.svg'; 
import circleIcon from '../../images/circle1.svg'; 
import documentsIcon from '../../images/documents.svg'; 
import TimeIcon from '../../images/Time.svg'; 
import Price from '../../images/Price Tag.svg';
// import Affidavit from '../senior/SeniorCitizen';
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';
import howIcon from '../../images/how.svg';

const Food = () => {
    
  
    const [current, setCurrent] = useState(1); 

    const closePopup = () => {
        setShowPopup(false);
        setCurrentStep(1);
        setIsCompleted(false);
      };

      const [openIndex, setOpenIndex] = useState(null); 
        const [showPopup, setShowPopup] = useState(false);
        const [currentStep, setCurrentStep] = useState(1);
        const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
 
        const [isCompleted, setIsCompleted] = useState(false);
        const [mobileNumber, setMobileNumber] = useState('');
     
      const [fullName, setFullName] = useState('');
      const [emailId, setEmailId] = useState('');
      const [selectedOption, setSelectedOption] = useState('');
      const [houseStreetName, setHouseStreetName] = useState('');
      const [villageTownCity, setVillageTownCity] = useState('');
      const [dob, setDob] = useState('');
      const [selectedGender, setSelectedGender] = useState('');
      const [selectedAffidavit, setSelectedAffidavit] = useState('');
      const [pincode, setPincode] = useState('');
      const [aadharNumber, setAadharNumber] = useState('');
      
      const [selectedState, setSelectedState] = useState('');
      
       
      const [otp, setOtp] = useState(["", "", "", ""]);
      const [resendCountdown, setResendCountdown] = useState(30); 
      const [isResending, setIsResending] = useState(false);
    
      const [error, setError] = useState("");
      const [showOtpSection, setShowOtpSection] = useState(true); 
      
      const [registrationNumber, setSeletedRegistrationNumber]=useState('');
      
      
          const handleFullNameChange = (event) => {
            setFullName(event.target.value);
          };
          const handleEmailIdChange = (e) => setEmailId(e.target.value);
          const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);
          const handleStateChange = (e) => setSelectedState(e.target.value);

          const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);
      
          const getMaskedMobileNumber = (number) => {
            if (!number || number.length < 3) return ""; // Return empty if number is too short
            const firstTwo = number.slice(0, 2); // First two digits
            const lastDigit = number.slice(-1); // Last digit
            const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
            return masked;
          };

      const finishSubmission = () => {
        submitDataToAPI();
        if (mobileNumber) {
          setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
        }
        setIsCompleted(true);
      };

      const nextStep = () => {
        if (currentStep < 3) setCurrentStep(currentStep + 1);
      };

      const submitDataToAPI = async () => {
        const data = {
          name: fullName || "", 
          mobilenumber: mobileNumber || "", 
          email: emailId || "",
          services: selectedAffidavit || "", 
          address: houseStreetName || "", 
          district: villageTownCity || "",
          date: dob || "", 
          insurance_registration_number: registrationNumber || "",
          paidamount: "0.00",
          // qualification: "", 
          applyingfor:"",
          gender: selectedGender || "", 
          // fathername: fatherName || "", 
          // mothername: motherName || "", 
          pincode: pincode || "", 
          adharnumber: aadharNumber || "", 
          pancard: "", 
          time: "00:00:00", 
          comment: "", 
          status: "",
          service: "Food License(FSSAI) ",
          followuptime: "00:00:00", 
          // existingpancardnumber: existingPanCardNumber || "", 
          village: villageTownCity || "", 
          "pancard-state": selectedState || "", 
          // "pancard-district": selectedDistrict || "", 
        };
    
        console.log("Data being sent to API:", data); 
    
        try {
          const response = await axios.post(
            "https://makemydocuments.nakshatranamahacreations.in/create.php",
            data,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("API Response:", response.data); 
          if (response.data.success) {
            alert("Details saved successfully!");
          } else {
          }
        } catch (error) {
          console.error("Error while saving data:", error);
          alert("An error occurred while saving your details. Please try again.");
        }
      };
    
      const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);
    
      const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
    
      const handleSendOtp = async () => {
        try {
          let formattedNumber = mobileNumber.trim();
          if (!/^\d{10}$/.test(formattedNumber)) {
            alert("Please enter a valid 10-digit mobile number.");
            return;
          }
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
      
          const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
            mobilenumber: formattedNumber,
          });
      
          if (response.status === 200) {
            console.log("API Response:", response.data);
            if (response.data.status === "success") {
              // Remove the alert and just set the state
              setOtpSent(true);
              setResendCountdown(30); // Reset countdown to 30 seconds
            } else {
              alert(response.data.message || "Error sending OTP.");
            }
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error sending OTP:", error);
          alert("An error occurred while sending OTP. Please try again.");
        }
      };
      
      React.useEffect(() => {
        if (resendCountdown > 0 && otpSent) {
          const timer = setInterval(() => {
            setResendCountdown((prev) => prev - 1);
          }, 1000);
          return () => clearInterval(timer);
        } else if (resendCountdown === 0) {
          setOtpSent(false); // Reset otpSent when countdown reaches zero
        }
      }, [resendCountdown, otpSent]);
  
      const prevStep = () => {
        if (currentStep > 1) setCurrentStep(currentStep - 1);
      };

   
      const handleResend = async () => {
        try {
          let formattedNumber = mobileNumber.trim();
          if (!/^91\d{10}$/.test(formattedNumber)) {
            formattedNumber = `91${formattedNumber}`;
          }
      
          console.log("Formatted Mobile Number for Resend:", formattedNumber);
      
          const config = {
            url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
            method: "post",
            data: {
              mobilenumber: formattedNumber,
            },
          };
          const response = await axios(config);
          if (response.status === 200 && response.data.status === "success") {
            console.log("Resend OTP Response:", response.data);
            setResendCountdown();
            alert("OTP resent successfully!");
          } else {
            alert(response.data.message || "Error resending OTP.");
          }
        } catch (error) {
          console.error("Error resending OTP:", error);
          // alert("An error occurred while resending OTP. Please try again.");
        }  finally {
          setIsResending(false); // Stop resending state
        }
      };
  
  const handleVerify = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        
        // Validate the mobile number format (10 digits, no country code check here)
        if (!/^\d{10}$/.test(formattedNumber)) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        
        // Add country code (only if needed)
        formattedNumber = `91${formattedNumber}`;
        console.log("Formatted Mobile Number:", formattedNumber);
    
        // Validate OTP
        const enteredOtp = otp.join("").trim();
        if (!enteredOtp || enteredOtp.length !== 4) {  // Update to 4 if you're expecting 4 digits
          alert("Please enter a valid 4-digit OTP.");
          return;
        }
    
        // Make the API request to verify OTP
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
          { mobilenumber: formattedNumber, otp: enteredOtp }
        );
    
        // Handle the response
        if (response.status === 200) {
          console.log("OTP Verification Response:", response.data);
          if (response.data.status === "success") {
            alert("OTP Verified Successfully!");
            setShowOtpSection(false); // Hide OTP section
            await fetchUserDetails(formattedNumber); // Fetch user details after success
          } else {
            alert(response.data.message || "Error verifying OTP.");
          }
        } else {
          throw new Error(`Unexpected response status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error verifying OTP:", error);
        alert("An error occurred while verifying OTP. Please try again.");
      }
    };

          const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
  
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          const userDetails = response.data.data[0];
  
          if (userDetails) {
            setUserData(userDetails); // Set user details
          } else {
            alert("No user details found.");
          }
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const generateOrderId = () => {
      return `ORD${Date.now()}`;
    };
  
    
    const [userDetails, setUserData] = useState(null); // Holds user details
    const [orderid, setOrderID] = useState(() => generateOrderId()); 
  
    // Handle Proceed to Pay API call
    const handleProceedToPay = async () => {
      if (!orderid) {
        alert("Order ID is missing!");
        return;
      }
    
      const custId = userDetails?.name
        ? `CUST_${userDetails.name.toUpperCase()}`
        : "CUST001"; 
    
      const txnAmount = (userDetails?.paidamount || 0) + 99; 
      const requestBody = {
        ORDER_ID: orderid, 
        CUST_ID: custId,
        INDUSTRY_TYPE_ID: "Retail",
        CHANNEL_ID: "WEB",
        TXN_AMOUNT: txnAmount.toString(), // Convert total amount to a string
      };
    
      try {
        const response = await axios({
          method: "post",
          url: "https://makemydocuments.nakshatranamahacreations.in/paytm-pg/TxnTest.php",
          data: requestBody,
          headers: {
            "Content-Type": "application/json",
          },
        });
    
        if (response.status === 200) {
          console.log("Payment API Response:", response.data);
    
          // Check if payment URL is provided
          if (response.data?.paymentUrl) {
            // Redirect to payment gateway
            window.location.href = response.data.paymentUrl;
          } else {
            alert("Payment request was successful, but no redirect URL was provided.");
          }
        } else {
          alert("Payment failed. Please try again.");
        }
      } catch (error) {
        console.error("Error making payment request:", error);
        alert("An error occurred while processing the payment.");
      }
    };
      
    
    
  
  // Handle OTP Input Change
  const handleChange = (value, index) => {
    if (!/^\d?$/.test(value)) return; // Only allow digits
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Auto-focus on the next input
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };


  const handleBackspace = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      // Move focus to the previous input
      if (index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }
    }
  };

  const [isSubmitting, setIsSubmitting] = useState(false);


  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    emailId: "",
    address: "",
    service: "Food License (FSSAI)",
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };


    return (
        <>
          <div style={{
    background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
    minHeight: '60vh',
    paddingTop: '20px',
    display: 'flex',
    marginTop:'4%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 20px'
}}>
    <div style={{ flex: 1, textAlign: 'left', fontWeight: 'bold' }}>
        <h2 style={{ marginLeft: "10%", marginBottom: "60px", fontWeight: "bold" }}>
        Apply FSSAI Food Safety License Registration
        </h2>
        
        {/* Form section */}
      
    </div>
    
    <div>
        <img src={Image30} alt="Lease Agreement" style={{ maxWidth: '100%', height: 'auto' }} />
    </div>
</div>



            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '3% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '60%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '60%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

<div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={TimeIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '55%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-48%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={Price}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>
                       
            
                    </div>
                </div>
             <>
             
             <div
    className="mb-5"
    style={{
        marginTop: '-85%',
        marginLeft: '70%',
    }}
>
    <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
        }}
    >
        Documents Required Food License (Soft Copy)
    </h4>
    <ul
        style={{
            display: 'grid',
            listStyleType: 'none', 
            paddingLeft: '20px',
            whiteSpace: 'nowrap',
            margin: '20px 0',
        }}
    >
        <li style={{ }}>Aadhar card</li>
        <li style={{  }}>Pan card</li>
        <li style={{  }}>One Passport Size Photo</li>
        <li style={{ fontWeight: '' }}>Fssai declaration form</li>
        <li style={{ fontWeight: '' }}>Nature of business details</li>
    </ul>
</div>

                <div style={{marginLeft:'72%',marginTop:'25%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8', whiteSpace: 'nowrap' }}>
  <li style={{ padding: '0', marginBottom: '0' }}>Register Online</li>
  <li style={{ padding: '0', marginBottom: '0' }}>Upload Documents</li>
  <li style={{ padding: '0', marginBottom: '0' }}>Payment</li>
  <li style={{ padding: '0', marginBottom: '0' }}>Get Delivered</li>
</ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'25%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Time Duration</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{ fontWeight: '', marginBottom: '10px' }}>1-2 working days</li>
                    </ul>
                </div>
                <div style={{marginLeft:'72%',marginTop:'37%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>Charges</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{  padding: '0', marginBottom: '0' }}><strong>Rs 1200</strong></li>
        <li style={{  padding: '0', marginBottom: '0' }}><strong>Rs 99</strong> as booking/consulting charge. Need to pay while submitting online form</li>
        {/* <li style={{ fontWeight: 'bold', marginBottom: '10px' }}>Note: Additional charges for stamp paper</li> */}
       
                    </ul>
                </div>
                </>
            </div>
            <div>
   
   {/* Get Quotes Button */}
   <div style={{ textAlign: "center", marginTop: "4%" }}>
     <button
       style={{
         backgroundColor: "#FCA505",
         color: "#000000",
         padding: "12px 50px",
         border: "none",
         borderRadius: "30px",
         fontWeight: "bold",
         cursor: "pointer",
         fontSize: "16px",
         marginRight:"40%",
         marginTop:"-30%",
       }}
       onClick={() => setShowPopup(true)}
     >
       CONTINUE
     </button>
   
   
 </div>
   {/* Modal Popup */}
   {showPopup && (
     <div
       style={{
        position: "fixed",
        top: "100px",
        left: "0",
        width: "100%",
        height: "86%",
        backgroundColor: "rgba(26, 118, 216, 0.9)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: "9999",
       }}
     >
       <div
         style={{
           backgroundColor: "#FFFFFF",
           padding: "40px",
           borderRadius: "28px",
           width: "70%",
           maxHeight: "90%", // Maximum height of the popup
           overflowY: "auto", // Enable scrolling if content overflows
           textAlign: "center",
           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
         }}
       >
         {/* Stepper */}
       

         {!isCompleted ? (
           <>
   <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
  {Array.from({ length: 2 }).map((_, index) => (
    <React.Fragment key={index}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          flex: 1,
        }}
      >
        <button
          onClick={() => setCurrentStep(index + 1)} // Make step clickable
          style={{
            width: "50px",
            height: "50px",
            backgroundColor: currentStep >= index + 1 ? "#4285F4" : "#ccc",
            color: "white",
            borderRadius: "50%",
            lineHeight: "50px",
            fontWeight: "bold",
            border: "none",
            cursor: "pointer",
            outline: "none",
          }}
        >
          {index + 1}
        </button>
     
      </div>


      {index < 1 && (
        <div
          style={{
            height: "2px",
            flex: 1,
            backgroundColor: currentStep > index + 1 ? "#4285F4" : "#ccc",
            alignSelf: "center",
          }}
        />
      )}
    </React.Fragment>
  ))}
</div>



      
         <div style={{ marginBottom: "20px" }}>
         {currentStep === 1 && (
        <div style={{ textAlign: "center" }}>
          <h4 style={{ color: "#1A76D8", fontWeight: "600" }}>Application</h4>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "20px",
              textAlign: "start",
            }}
          >
            <div style={{ width: "45%" }}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                Name<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="name"
               value={fullName}
                onChange={handleFullNameChange}
                placeholder=""
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "2px solid #FCA505",
                  borderRadius: "10px",
                }}
              />
            </div>
            <div style={{ width: "45%" }}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                Mobile Number<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="mobileNumber"
               value={mobileNumber}
                onChange={handleMobileNumberChange}
                placeholder=""
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "2px solid #FCA505",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "20px",
              textAlign: "start",
            }}
          >
            <div style={{ width: "100%" }}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                Email Id<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="emailId"
                value={emailId}
                onChange={handleEmailIdChange}
                placeholder=""
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "2px solid #FCA505",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
              marginBottom: "20px",
              textAlign: "start",
            }}
          >
            <div style={{ width: "100%" }}>
              <label
                style={{
                  fontSize: "16px",
                  fontWeight: "500",
                  color: "#333",
                }}
              >
                Address<span style={{ color: "red" }}>*</span>
              </label>
              <input
                type="text"
                name="address"
                value={villageTownCity}
              onChange={handleVillageTownCityChange}
                placeholder=""
                style={{
                  width: "100%",
                  padding: "8px",
                  marginTop: "5px",
                  border: "2px solid #FCA505",
                  borderRadius: "10px",
                }}
              />
            </div>
           
          </div>
            {error && <div style={{ color: "red", marginBottom: "20px" }}>{error}</div>}
          <p style={{ marginTop: "20px", fontSize: "14px", textAlign: "left" }}>
       By clicking submit, you agree to our{" "}
       <a  href="/terms-conditions" style={{ color: "#007BFF", textDecoration: "underline" }}>
         Terms & Conditions
       </a>{" "}
       and{" "}
       <a
          href="/privacy-policy"
         style={{ color: "#007BFF", textDecoration: "underline" }}
       >
         Privacy Policy
       </a>.
     </p>
        </div>
      )}



         </div>
         <div   style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }}>
          
         {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}

           {currentStep < 1 ? (
           <button
           onClick={nextStep}
           style={{
             padding: "10px 20px",
             backgroundColor: "#FCA505",
             color: "#000000",
             border: "none",
             borderRadius: "5px",
             cursor: "pointer",
           }}
         >
           Next
         </button>
           
           ) : (
            <button
            onClick={() => {
              if (!fullName) {
                setError("Name is required.");
                return;
              }
              if (!mobileNumber) {
                setError("Mobile number is required."); 
                return;
              }
              if (!emailId) {
                setError("Email ID is required.");
                return;
              }
              if (!villageTownCity) {
                setError("Address is required.");
                return;
              }
           
              handleSendOtp(); 
              finishSubmission();
            }}
              style={{
                padding: "10px 20px",
                backgroundColor: "#FCA505",
                color: "#000000",
                border: "none",
                alignContent:'center',
                alignItems:'center',
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              SUBMIT
            </button>
           )} 
         </div>
         </>
         ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
          {showOtpSection ? (
        <div>
        <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
          OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
        </h4>
        <div style={{ margin: "20px 0" }}>
          <label style={{ fontWeight: "500", marginBottom: "10px" }}>
            Enter OTP <span style={{ color: "red" }}>*</span>
          </label>
          <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
            {otp.map((digit, index) => (
              <input
                key={index}
                id={`otp-input-${index}`}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleChange(e.target.value, index)}
                onKeyDown={(e) => handleBackspace(e, index)}
                style={{
                  width: "50px",
                  height: "50px",
                  textAlign: "center",
                  fontSize: "18px",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                }}
              />
            ))}
          </div>
        </div>
      
        <div style={{ marginTop: "20px", textAlign: "center" }}>
      
          <p style={{ fontSize: "14px", color: "#888" }}>
              {resendCountdown > 0 ? (
                <>Not Received? Resend in {resendCountdown}s</>
              ) : (
                <a
                  href="#"
                  onClick={handleResend}
                  style={{
                    textDecoration: "none",
                    color: isResending ? "#ccc" : "#007BFF",
                    pointerEvents: isResending ? "none" : "auto",
                  }}
                >
                  Resend OTP
                </a>
              )}
            </p>
        </div>
      
        <button
          onClick={handleVerify}
          style={{
            backgroundColor: "#FFA500",
            color: "#000",
            border: "none",
            borderRadius: "5px",
            padding: "10px 20px",
            fontSize: "16px",
            cursor: "pointer",
          }}
        >
          VERIFY
        </button>
      </div>
          ) : (
            <>
            <h2 style={styles.thankYouMessage}>Thank You for Your Submitting!</h2>
            <div style={styles.infoBox}>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Name:</label>
                <input
                  type="text"
                  value={userDetails?.name || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
             
              <div style={styles.inputGroup}>
                <label style={styles.label}>Mobile Number:</label>
                <input
                  type="text"
                  value={userDetails?.mobilenumber || "N/A"}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Order ID:</label>
                <input
                  type="text"
                  value={orderid}
                  readOnly
                  style={styles.input}
                />
              </div>
              <div style={styles.inputGroup}>
  <label style={styles.label}>Services:</label>
  <input
    type="text"
    value="Food License (FSSAI)" // Set the default value here
    readOnly
    style={styles.input}
  />
</div>
              <div style={styles.inputGroup}>
                <label style={styles.label}>Payment Amount:</label>
                <input
                  type="text"
                  value="₹99"
                  readOnly
                  style={styles.input}
                />
              </div>
            </div>
            <button
              onClick={handleProceedToPay}
              style={styles.proceedButton}
            >
              Proceed to Pay
            </button>
          {/* // </div> */}
          </>
          )}
        </div>
         )}

         {/* Close Button */}
         <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
       </div>
     </div>
   )}
 </div>

 <br></br>

        
        
        </>
    );
};


const styles = {
  paymentSummary: {
    maxWidth: '600px',
    margin: '20px auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  },
  thankYouMessage: {
    textAlign: 'center',
    color: '#007BFF',
    marginBottom: '20px',
  },
  infoBox: {
    marginBottom: '20px',
  },
  inputGroup: {
    display: 'flex',
    alignItems: 'center',
    margin: '10px 0',
  },
  label: {
    flex: '1',
    fontWeight: 'bold',
    color: '#333',
  },
  input: {
    padding: '8px', 
    border: '1px solid #ccc',
    borderRadius: '4px',
    fontSize: '14px', 
    // marginLeft: '10px',
    width: '200px', // Set the desired width here
  },
  proceedButton: {
    backgroundColor: '#007BFF',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    transition: 'background-color 0.3s',
  },
};

export default Food;
