import React, { useState } from 'react';
    import "./css/home.css"; 
    import { Link } from 'react-router-dom';
    import { ReactComponent as HomeImage } from "./images/homeimage.svg"; 
    import { ReactComponent as BookImage } from "./images/book.svg"; 
    import { ReactComponent as NewImage1 } from "./images/Insurance.svg"; 
    import { ReactComponent as NewImage2 } from "./images/visa.svg";  
    import { ReactComponent as NewImage3 } from "./images/rentalagreement.svg";  
    import { ReactComponent as NewImage4 } from "./images/leaseagreement.svg";  
    import { ReactComponent as NewImage5 } from "./images/affidavits.svg";  
    import { ReactComponent as NewImage6 } from "./images/pancard.svg";  
    import { ReactComponent as AdditionalImage1 } from "./images/passport.svg";  
    import { ReactComponent as AdditionalImage2 } from "./images/seniorcitizen.svg";  
    import { ReactComponent as AdditionalImage3 } from "./images/policeverification.svg"; 
    import { ReactComponent as AdditionalImage4 } from "./images/foodlicence.svg";  
    import { ReactComponent as AdditionalImage5 } from "./images/msmecertification.svg";  
    import { ReactComponent as AdditionalImage6 } from "./images/policeclearence.svg"; 
    import { ReactComponent as CircleImage } from "./images/circle.svg";
    import { ReactComponent as Wave } from "./images/wave.svg";
    import oneImage from './images/1.svg';
    import twoImage from './images/2.svg';
    import threeImage from './images/3.svg';
    import RectangleSlidingImage from "./images/rectangle sliding.svg";

    import girlImage from './images/girl image.jpg';
    import man1Image from './images/man1.jpg';
import man2Image from './images/man2.jpg';
import ColorCircle1 from '../src/images/cirle-image.svg';
import Step1Image from '../src/images/step 1 1.svg'
import Step2Image from '../src/images/step 2.svg'
import Step3Image from '../src/images/step3.svg'
import { ReactComponent as PaytmImage } from "./images/Paytm_logo 1.svg";
import {ReactComponent as CscImage} from "./images/csc.svg"
import { ReactComponent as TurtleImage } from "./images/TurtlemintProLogo.svg";
import { ReactComponent as ImageIcon } from "./images/image.svg";
import { ReactComponent as GromoImage } from "./images/gromo.svg";
import {ReactComponent as DigitalIndiaImage} from "./images/Digital_india_logo.svg"

import Slider from "react-slick"; 
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 
import LadyImage from "./images/ladyimage.svg";
import AirplaneImage from "./images/airoplane.svg";
const services = [
  { name: 'Insurance', path: '/insurance', image: <NewImage1 className="box-image scale-tilt" /> },
  { name: 'Travel Visa', path: '/visa', image: <NewImage2 className="box-image scale-tilt" /> },
  { name: 'Rental Agreement', path: '/rental-agreement', image: <NewImage3 className="box-image scale-tilt" /> },
  { name: 'Lease Agreement', path: '/lease-agreement', image: <NewImage4 className="box-image scale-tilt" /> },
  { name: 'Affidavits/  Annexure', path: '/affidavits', image: <NewImage5 className="box-image scale-tilt" /> },
  { name: 'Pan Card', path: '/pan-card', image: <NewImage6 className="box-image scale-tilt" /> },
  { name: 'Passport', path: '/passport', image: <AdditionalImage1 className="box-image scale-tilt" /> },
  { name: 'Senior Citizen Card', path: '/senior-citizen', image: <AdditionalImage2 className="box-image scale-tilt" /> },
  { name: 'Police Verification  Certificate', path: '/policeverification', image: <AdditionalImage3 className="box-image scale-tilt" /> },
  { name: 'Food License (FSSAI)', path: '/food-license', image: <AdditionalImage4 className="box-image scale-tilt" /> },
  { name: 'MSME Certification', path: '/msme', image: <AdditionalImage5 className="box-image scale-tilt" /> },
  { name: 'Police Clearance Certificate', path: '/police-clearance', image: <AdditionalImage6 className="box-image scale-tilt" /> },
];
    const Home = () => {

      const [searchTerm, setSearchTerm] = useState('');

      // Filter services based on the search term
      const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
      };
    
      const filteredServices = services.filter((service) =>
        service.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      window.scrollTo({
        top: 0,
        behavior: 'smooth', 
      })

    return (
        <>
        <section className="home-section" style={{overflowX:'hidden'}}>
            <div className="container home-container">
            {/* Text Content */}
            <div className="home-text">
                <h1>We are India's</h1>
                <h1>#1* Online Documents Consultancy.</h1>
                <p>
                Efficiently Organized, Expertly Delivered <br />
                Accurate Drafting, Fast Turnaround, Hassle-Free Execution
                </p>
                 <div className="search-bar">
                <input
                    type="text"
                    placeholder="Search and select which documents you want to get ready!"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </div>
            </div>
            <div className="home-images" style={{marginTop:"17%"}}>
                <HomeImage className="home-main-image" />
            </div>    
            <BookImage className="home-book-image" />
            </div>
        </section>
        <section className="boxes-section">
        <div className="boxes-container">
          {(searchTerm ? filteredServices : services).map((service, index) => (
            <div className="box-container" key={index}>
              <Link to={service.path} className="box-link" style={{ textDecoration: 'none' }}>
                <div className="box">{service.image}</div>
                <p className="box-name" style={{ fontSize: '16px', wordWrap: 'nowrap' }}>
                  {service.name}
                </p>
              </Link>
            </div>
          ))}
        </div>
      </section>
          


<section className="circle-section">
<div className="container">
<div className="row">
  <div className="image-text-wrapper">
    <CircleImage className="circle-image" />
    <div className="text-container">
      <span className="how-it-works-text">HOW IT WORKS</span>
     <div style={{marginTop:'5%'}}>
 <p style={{marginTop:'-4%', marginLeft:'-50%', fontSize:'24px', fontWeight:'bold'}}>Make My Documents Is</p> 
  <br />
  <div  style={{marginTop:'-7%', fontSize:'24px'}}>
  <strong style={{ color: "#007bff" }}>
    The Smartest Way To Get Your Documents
  </strong>
  <span style={{ color: "#333", fontWeight: "normal" }}> Done.</span>
  </div>
  </div>
    </div>
  </div>
</div>
  </div> 
  <section className="wave-section">
        <div className="wave-wrapper">
          {/* Wave SVG */}
          <Wave className="wave-svg" style={{sm:"Hidden",}} />
          <div className="color-circle start-circle position-relative">
  <img 
    src={ColorCircle1} 
    alt="Color Circle 1" 
    className="circle-img" 
    style={{ height: "30%", marginTop: "26px" , marginLeft:"-10%"}} 
  />
  <img 
    src={Step1Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "115%", 
      top: "12%", 
      left: "-3%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
  />
</div>

          <div className="color-circle middle-circle position-relative">
         <img src={ColorCircle1} alt="Color Circle 2" className="" style={{height:"30%",}}/>
         <img 
    src={Step2Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "174%", 
      top: "12%", 
      left: "8%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
    />
          </div>
          <div className="color-circle end-circle position-relative">
          <img src={ColorCircle1} alt="Color Circle 3" className="" style={{height:"30%",}} />
          <img 
    src={Step3Image} 
    alt="Step 1" 
    className="step-img position-absolute" 
    style={{
      height: "171%", 
      top: "12%", 
      left: "15%", 
      transform: "translate(-48%, -50%)",
      zIndex: 1,
    }} 
    />
          </div>  
        </div>
       
      </section>
      <div className="why-make-my-documents text-center my-10">
        <div className="row justify-content-center">
          <div className="col-12 col-md-4 " style={{marginTop:"-3%" , marginLeft:"-5%"}}>
            {/* <img
              src={oneImage}
              alt="Complete Online Process"
              className="mb-3 feature-img img-fluid"
              style={{marginLeft:"-21%"}} 
            /> */}
          <p style={{ fontWeight: 'bold' , marginLeft:'40%', marginTop:'10%'}}>
  Search and Select <br />
  which Document <br />
  you want to get ready
</p>

          </div>
          <div className="col-12 col-md-4 text-center" style={{marginTop:"-2%" , marginLeft:"1px"}}>
            {/* <img
              src={twoImage}
              alt="Dedicated Team"
              className="mb-3 feature-img img-fluid"
            /> */}
            {/* <h5 style={{ fontWeight: 'bold' }}>DEDICATED TEAM</h5> */}
            <p style={{ fontWeight: 'bold' ,marginTop:'-2%'}}>
  Get end-to-end assistance <br />
  and complete guidance <br />
  to get ready documents
</p>

          </div>
          <div className="col-12 col-md-4 text-center" style={{marginTop:"-2%", marginRight:"-10%"}}>
            {/* <img
              src={threeImage}
              alt="99% Approval Rate"
              className="mb-3 feature-img img-fluid" 
              style={{marginLeft:'-33%',}}
            /> */}
        
            <p style={{ fontWeight: 'bold' , marginLeft:'-28%',marginTop:'-7%'}}>
  Your Document gets ready <br />
  and delivered to your doorstep <br />
  sit back and relax
</p>

          </div>
        </div>
      </div>
</section>




<section className="rectangle-section">
  <div className="rectangle-container">
    <div className="rectangle-background" style={{ height: '1%' }}>
      <div className="rectangle-content">
        <div className="rectangle-text">
          <h1>Ready for your next destination?</h1>
          <p>Leave the visa paperwork to us!</p>
          <a href="/visa" style={{ textDecoration: 'none' }}>
            <button
              style={{
                display: 'block',
                margin: '20px auto',
                padding: '10px 20px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
              onMouseLeave={(e) => (e.target.style.backgroundColor = '#007bff')}
            >
              Click Here
            </button>
          </a>
        </div>
        <img src={AirplaneImage} alt="Airplane" className="airplane-image" />
        <img src={LadyImage} alt="Lady" className="lady-image" />
      </div>
    </div>
  </div>
</section>


<section className="sliding-section" style={{ zIndex: "1", marginTop:'-4%' }}>
  
  <div
    id="slidingCarousel"
    className="carousel slide"
    data-bs-ride="carousel"
    data-bs-interval="1000"
    data-bs-pause="false"
  >
    <div className="carousel-inner">
      {/* Slide 1 */}
      <div className="carousel-item active">
        <Link to="/insurance">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 1" style={{zIndex:"1"}}
          />
          <div className="carousel-caption">
            <h2 className="slide-title" style={{whiteSpace:"nowrap", marginLeft:"-40%"}}>
              Get Insured Without The Paperwork Headaches!
            </h2>
            <p className="slide-description">
              We make it simple and quick to secure insurance, ensuring you
              receive your policy in just a matter of days without the stress of
              handling paperwork.
            </p>
            <img
              src={require("./images/paperwork.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
        </Link>
      </div>
      {/* Slide 2 */}
      <div className="carousel-item">
      <Link to="/rental-agreement">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 2"
          />
          <div className="carousel-caption">
            <h2 className="slide-title" style={{whiteSpace:"nowrap", marginTop:"-22px"}}>Need a rental agreement fast?</h2>
            <p className="slide-description">
            With our hassle-free service, you can get your rental agreement without stepping out. 
            Your legal document will be delivered to you in just a few days
            </p>
            <img
              src={require("./images/rental-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
        </Link>
      </div>

      {/* Slide 3 */}
      <div className="carousel-item">
      <Link to="/food-license">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Starting a food business? You’ll need a food license!</h2>
            <p className="slide-description">
            Our service takes the hassle out of the process, ensuring you receive your food 
license promptly so you can focus on building your business.

            </p>
            <img
              src={require("./images/food-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
       </Link>
      </div>
      <div className="carousel-item">
        <Link to="/senior-citizen">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Secure Your Senior Citizen Card, Stress-Free!</h2>
            <p className="slide-description">
            We handle the process while you relax.
            </p>
            <img
              src={require("./images/senior-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
         
        </div>
        </Link>
      </div>
      <div className="carousel-item">
      <Link to="/visa">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Your Passport, Simplified.</h2>
            <p className="slide-description">
            Apply now and leave the paperwork to us.
            </p>
            <img
            src={require("./images/passport-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
        </Link>
      </div>
      <div className="carousel-item">
        <Link to="/pan-card">
        <div className="slide-container">
          <img
            src={RectangleSlidingImage}
            className="d-block slide-image"
            alt="Slide 3"
          />
          <div className="carousel-caption">
            <h2 className="slide-title">Your Pancard, Simplified.</h2>
            <p className="slide-description">
            Apply now and leave the paperwork to us.
            </p>
            <img
              src={require("./images/pancard-image-slide.svg").default}
              alt="Paperwork Icon"
              className="paperwork-icon"
            />
          </div>
        </div>
        </Link>
      </div>
    </div>
  </div>
</section>

<section className="review-section" style={{ marginTop: "5%" }}>
  {/* Title and Button in the Same Line */}
  <div
  className="d-flex justify-content-between align-items-center"
  style={{ marginBottom: "2%" }}
>
  <p
    style={{
      fontWeight: "bold",
      fontSize: "30px",
      textAlign: "center",
      marginLeft: "40%",
    }}
  >
    Customer Reviews
  </p>
  <a
    href="https://g.page/r/Caew-RxlE-aQEAE/review"
    target="_blank"
    rel="noopener noreferrer"
  >
    <button
      className="btn btn-primary"
      style={{ fontWeight: "bold", fontSize: "16px" }}
    >
      Write a Review
    </button>
  </a>
</div>


  {/* Review Carousel */}
  <div
    id="reviewCarousel"
    className="carousel slide"
    data-bs-ride="carousel"
    data-bs-interval="3000"
  >
    <div className="carousel-inner">
      {/* Slide 1 */}
      <div className="carousel-item active">
        <div
          className="row"
          style={{ justifyContent: "center", marginTop: "3%" }}
        >
          <div className="col-md-3">
            <div className="review-box">
              <img src={girlImage} alt="Customer 1" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 2" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 3" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 4" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Slide 2 */}
      <div className="carousel-item">
        <div
          className="row"
          style={{ justifyContent: "center", marginTop: "3%" }}
        >
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 5" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={girlImage} alt="Customer 6" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man1Image} alt="Customer 7" />
              <div className="stars">★★★★☆</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div className="review-box">
              <img src={man2Image} alt="Customer 8" />
              <div className="stars">★★★★★</div>
              <p>
                Fakespot uses Artificial Intelligence to analyze thousands of
                reviews and consumer ratings before assigning your own authentic
                rating on e-commerce products. The platform was created to help
                consumers of online products recognize genuine products in a
                context.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
 
</section>
<br/>
<div
  className="d-flex justify-content-end align-items-center"
  style={{ marginBottom: "2%" }}
>
  {/* "View All" Button aligned to the right */}
  <a
    href=""
    target="_blank"
    rel="noopener noreferrer"
  >
    <button
      className="btn"
      style={{
        fontWeight: "bold",
        fontSize: "16px",
        backgroundColor: "#1A76D8", 
        color: "white", 
        border: "none", 
        padding: "10px 20px", 
        borderRadius: "5px", 
      }}
    >
      View All
    </button>
  </a>
</div>




<section className="additional-section" style={{marginTop:"30px"}}>
  <div>
    <div className="additional-container">
      {/* New Header */}
      <h2 className="additional-header">ASSOCIATED WITH</h2>
      {/* SVG Images Row */}
      <div className="svg-images-row">
        <div className="svg-image-container">
          <PaytmImage className="svg-image" style={{ width: "150px" }} />
        </div>
        <div className="svg-image-container">
          <CscImage className="svg-image" style={{ width: "150px" }} />
        </div>
        <div className="svg-image-container">
          <GromoImage className="svg-image" style={{ width: "150px" }} />
        </div>
        <div className="svg-image-container">
          <DigitalIndiaImage className="svg-image" style={{ width: "150px" }} />
        </div>
        <div className="svg-image-container">
          <ImageIcon className="svg-image" style={{ width: "150px" }} />
        </div>
        <div className="svg-image-container">
          <TurtleImage className="svg-image" style={{ width: "150px" }} />
        </div>
      </div>
    </div>
  </div>
</section>

<br></br>

        </>
    );
    };

    export default Home;
