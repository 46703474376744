import React, { useState } from 'react';
import './InsurancePage.css'; 
import checklistIcon from '../images/notebook.svg';
import vehiclesIcon from '../images/Life_image.png';
import circleIcon from '../images/circle1.svg'; 
import documentsIcon from '../images/documents.svg'; 
import howIcon from '../images/how.svg'; 
import thirdImage from '../images/third.svg'; 
import axios from "axios";
import { FaArrowLeft } from 'react-icons/fa';

const stateData = [
  {
    state: "Andhra Pradesh",
    districts: ["Visakhapatnam", "Vijayawada", "Guntur", "Kakinada"],
  },
  {
    state: "Arunachal Pradesh",
    districts: ["Itanagar", "Tawang", "Ziro", "Pasighat"],
  },
  {
    state: "Assam",
    districts: ["Guwahati", "Jorhat", "Dibrugarh", "Silchar"],
  },
  {
    state: "Bihar",
    districts: ["Patna", "Gaya", "Bhagalpur", "Muzaffarpur"],
  },
  {
    state: "Chhattisgarh",
    districts: ["Raipur", "Bilaspur", "Durg", "Korba"],
  },
  {
    state: "Goa",
    districts: ["Panaji", "Margao", "Mapusa", "Vasco da Gama"],
  },
  {
    state: "Gujarat",
    districts: ["Ahmedabad", "Surat", "Vadodara", "Rajkot"],
  },
  {
    state: "Haryana",
    districts: ["Chandigarh", "Faridabad", "Gurgaon", "Ambala"],
  },
  {
    state: "Himachal Pradesh",
    districts: ["Shimla", "Manali", "Kullu", "Kangra"],
  },
  {
    state: "Jharkhand",
    districts: ["Ranchi", "Jamshedpur", "Dhanbad", "Giridih"],
  },
  {
    state: "Karnataka",
    districts: ["Bagalkot",
      "Ballari (Bellary)",
      "Belagavi (Belgaum)",
      "Bengaluru (Bangalore) Rural",
     "Bengaluru (Bangalore) Urban", 
      "Bidar",
      "Chamarajanagar",
      "Chikballapur",
      "Chikkamagaluru (Chikmagalur)",
      "Chitradurga",
      "Dakshina Kannada",
      "Davangere",
      "Dharwad",
      "Gadag",
      "Hassan",
      "Haveri",
     "Kalaburagi (Gulbarga)",
      "Kodagu",
      "Kolar",
      "Koppal",
      "Mandya",
      "Mysuru (Mysore)",
      "Raichur",
      "Ramanagara",
      "Shivamogga (Shimoga)",
      "Tumakuru (Tumkur)",
      "Udupi",
     " Uttara Kannada (Karwar)",
     " Vijayapura (Bijapur)",
      "Yadgir"],
  },
  {
    state: "Kerala",
    districts: ["Thiruvananthapuram", "Kochi", "Kozhikode", "Kottayam"],
  },
  {
    state: "Madhya Pradesh",
    districts: ["Bhopal", "Indore", "Gwalior", "Jabalpur"],
  },
  {
    state: "Maharashtra",
    districts: ["Mumbai", "Pune", "Nagpur", "Nashik"],
  },
  {
    state: "Manipur",
    districts: ["Imphal", "Thoubal", "Bishnupur", "Churachandpur"],
  },
  {
    state: "Meghalaya",
    districts: ["Shillong", "Tura", "Jowai", "Nongpoh"],
  },
  {
    state: "Mizoram",
    districts: ["Aizawl", "Lunglei", "Champhai", "Serchhip"],
  },
  {
    state: "Nagaland",
    districts: ["Kohima", "Dimapur", "Mokokchung", "Wokha"],
  },
  {
    state: "Odisha",
    districts: ["Bhubaneswar", "Cuttack", "Berhampur", "Rourkela"],
  },
  {
    state: "Punjab",
    districts: ["Amritsar", "Chandigarh", "Ludhiana", "Patiala"],
  },
  {
    state: "Rajasthan",
    districts: ["Jaipur", "Udaipur", "Jodhpur", "Ajmer"],
  },
  {
    state: "Sikkim",
    districts: ["Gangtok", "Namchi", "Mangan", "Rangpo"],
  },
  {
    state: "Tamil Nadu",
    districts: ["Chennai", "Coimbatore", "Madurai", "Salem"],
  },
  {
    state: "Telangana",
    districts: ["Hyderabad", "Warangal", "Khammam", "Nalgonda"],
  },
  {
    state: "Tripura",
    districts: ["Agartala", "Udaipur", "Dhalai", "North Tripura"],
  },
  {
    state: "Uttar Pradesh",
    districts: ["Lucknow", "Kanpur", "Varanasi", "Agra"],
  },
  {
    state: "Uttarakhand",
    districts: ["Dehradun", "Haridwar", "Nainital", "Almora"],
  },
  {
    state: "West Bengal",
    districts: ["Kolkata", "Howrah", "Siliguri", "Durgapur"],
  },
  {
    state: "Andaman and Nicobar Islands",
    districts: ["Port Blair", "Nicobar", "Car Nicobar", "Little Andaman"],
  },
  {
    state: "Chandigarh",
    districts: ["Chandigarh"],
  },
  {
    state: "Dadra and Nagar Haveli and Daman and Diu",
    districts: ["Daman", "Diu", "Silvassa"],
  },
  {
    state: "Lakshadweep",
    districts: ["Kavaratti", "Agatti", "Andrott", "Kalapeni"],
  },
  {
    state: "Delhi",
    districts: ["Central Delhi", "New Delhi", "East Delhi", "West Delhi"],
  },
  {
    state: "Puducherry",
    districts: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
  },
];

const TwoWheeler = () => {

  const [openIndex, setOpenIndex] = useState(null); 
  const [showPopup, setShowPopup] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [maskedNumber, setMaskedNumber] = useState("XXXX-XXXX-XXXX"); 
  const [resendCountdown, setResendCountdown] = useState(30); 
  const [isCompleted, setIsCompleted] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
const [otp, setOtp] = useState(['', '', '', '']);
const [fullName, setFullName] = useState('');
const [emailId, setEmailId] = useState('');
const [selectedOption, setSelectedOption] = useState('');
const [houseStreetName, setHouseStreetName] = useState('');
const [villageTownCity, setVillageTownCity] = useState('');
const [dob, setDob] = useState('');
const [selectedGender, setSelectedGender] = useState('');

const [pincode, setPincode] = useState('');
const [aadharNumber, setAadharNumber] = useState('');

const [selectedState, setSelectedState] = useState('');

const [userData, setUserData] = useState(null); 
const [error, setError] = useState("");
const [showOtpSection, setShowOtpSection] = useState(true); 
  const closePopup = () => {
      setShowPopup(false);
      setCurrentStep(1);
      setIsCompleted(false);
    };
const [registrationNumber, setSeletedRegistrationNumber]=useState('');

    const handleFullNameChange = (event) => {
      setFullName(event.target.value);
    };
    const handleEmailIdChange = (e) => setEmailId(e.target.value);
    const handleVillageTownCityChange = (e) => setVillageTownCity(e.target.value);

    const handleRegistrationNumber =(e) => setSeletedRegistrationNumber(e.target.value);

    const getMaskedMobileNumber = (number) => {
      if (!number || number.length < 3) return ""; // Return empty if number is too short
      const firstTwo = number.slice(0, 2); // First two digits
      const lastDigit = number.slice(-1); // Last digit
      const masked = `${firstTwo}******${lastDigit}`; // Mask the middle digits
      return masked;
    };
    const [fullNameError, setFullNameError] = useState("");
    const [mobileNumberError, setMobileNumberError] = useState("");
    const [emailIdError, setEmailIdError] = useState("");
    const [villageTownCityError, setVillageTownCityError] = useState("");
    const [selectedStateError, setSelectedStateError] = useState("");
    const [selectedDistrictError, setSelectedDistrictError] = useState("");
    const handleStateChange = (e) => {
      const state = e.target.value;
      setSelectedState(state);
      setSelectedDistrict(""); 
      setSelectedStateError(""); 
      setSelectedDistrictError(""); 
    };
    const handleDistrictChange = (e) => {
      setSelectedDistrict(e.target.value);
      setSelectedDistrictError(""); 
    };



const pincodeRegex = /^\d{6}$/; // Pin code validation (6 digits)

const handlePincodeChange = (e) => {
  setPincode(e.target.value);
};

    // Navigate steps
    const nextStep = () => {
      if (currentStep < 3) setCurrentStep(currentStep + 1);
    };
    const [policyOption, setPolicyOption] = useState("");
     const [policyOptionError, setPolicyOptionError] = useState('');
     const [gender, setGender] = useState('');
     const [genderError, setGenderError] = useState('');
     const [errors, setErrors] = useState({})

    const validStep = () => {
      let errors = {};
      
      if (currentStep === 1) {
        if (!policyOption) errors.policyOption = "Please select a policy option.";
      }
    
      if (currentStep === 2) {
        if (!gender) errors.gender = "Please select your gender.";
        if (!dob) errors.dob = "Date of birth is required.";
      }
    
     
    
      if (currentStep === 3) {
        const mobileRegex = /^[6-9]\d{9}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        if (!fullName) errors.fullName = "Full name is required.";
        if (!mobileNumber) errors.mobileNumber = "Mobile number is required.";
        else if (!mobileRegex.test(mobileNumber)) errors.mobileNumber = "Invalid mobile number.";
    
        if (!emailId) errors.emailId = "Email ID is required.";
        else if (!emailRegex.test(emailId)) errors.emailId = "Invalid email ID.";
    
        if (!villageTownCity) errors.address = "Address is required.";
        if (!selectedState) errors.selectedState = "Please select a state.";
        if (!selectedDistrict) errors.selectedDistrict = "Please select a district.";
        if (!pincode) errors.pincode = "Pin code is required.";
        else if (!pincodeRegex.test(pincode)) errors.pincode = "Invalid pin code.";
      }
    
      // Set errors in state if there are any
      setErrors(errors);
    
      // If no errors, proceed to next step
      if (Object.keys(errors).length > 0) return false;
      return true;
    };
  
    const prevStep = () => {
      if (currentStep > 1) setCurrentStep(currentStep - 1);
    };

    const selectedStateData = stateData.find(
      (stateObj) => stateObj.state === selectedState
    );
    const districts = selectedStateData ? selectedStateData.districts : [];
    const [isResending, setIsResending] = useState(false);
    const [applyingfor, setApplyingFor]=useState('');

    const handleMobileNumberChange = (e) => setMobileNumber(e.target.value);

    const handleChange = (value, index) => {
      if (!/^\d?$/.test(value)) return; 
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value && index < otp.length - 1) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    };
    const handleBackspace = (e, index) => {
      if (e.key === "Backspace") {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
  
        // Move focus to the previous input
        if (index > 0) {
          document.getElementById(`otp-input-${index - 1}`).focus();
        }
      }
    };


    const [otpSent, setOtpSent] = useState(false); // To track if OTP has been sent
    
      const handleSendOtp = async () => {
        try {
          let formattedNumber = mobileNumber.trim();
          if (!/^\d{10}$/.test(formattedNumber)) {
            alert("Please enter a valid 10-digit mobile number.");
            return;
          }
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
      
          const response = await axios.post("https://makemydocuments.nakshatranamahacreations.in/otp.php", {
            mobilenumber: formattedNumber,
          });
      
          if (response.status === 200) {
            console.log("API Response:", response.data);
            if (response.data.status === "success") {
              // Remove the alert and just set the state
              setOtpSent(true);
              setResendCountdown(30); // Reset countdown to 30 seconds
            } else {
              alert(response.data.message || "Error sending OTP.");
            }
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error sending OTP:", error);
          alert("An error occurred while sending OTP. Please try again.");
        }
      };
      
      React.useEffect(() => {
        if (resendCountdown > 0 && otpSent) {
          const timer = setInterval(() => {
            setResendCountdown((prev) => prev - 1);
          }, 1000);
          return () => clearInterval(timer);
        } else if (resendCountdown === 0) {
          setOtpSent(false); // Reset otpSent when countdown reaches zero
        }
      }, [resendCountdown, otpSent]);


 
    
    const fetchUserDetails = async (formattedNumber) => {
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/get.php",
          { mobilenumber: formattedNumber }
        );
        if (response.status === 200 && response.data) {
          console.log("Fetched User Details:", response.data);
          setUserData(response.data.data[0]); // Save the first record from the response
        } else {
          alert("Failed to fetch user details.");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
        alert("An error occurred while fetching user details.");
      }
    };

    const handleVerify = async () => {
      try {
          let formattedNumber = mobileNumber.trim();
  
          // Validate the mobile number format (10 digits, no country code check here)
          if (!/^\d{10}$/.test(formattedNumber)) {
              alert("Please enter a valid 10-digit mobile number.");
              return;
          }
  
          // Add country code (only if needed)
          formattedNumber = `91${formattedNumber}`;
          console.log("Formatted Mobile Number:", formattedNumber);
  
          // Validate OTP
          const enteredOtp = otp.join("").trim();
          if (!enteredOtp || enteredOtp.length !== 4) {
              alert("Please enter a valid 4-digit OTP.");
              return;
          }
  
          // Make the API request to verify OTP
          const response = await axios.post(
              "https://makemydocuments.nakshatranamahacreations.in/otpverify.php",
              { mobilenumber: formattedNumber, otp: enteredOtp }
          );
  
          // Handle the response
          if (response.status === 200) {
              console.log("OTP Verification Response:", response.data);
              if (response.data.status === "success") {
                  alert("OTP Verified Successfully!");
                  setShowOtpSection(false); // Hide OTP section
                  await fetchUserDetails(formattedNumber); // Fetch user details after success
                  
                  // After OTP verification, call the message API
                  await sendMessage(formattedNumber);
              } else {
                  alert(response.data.message || "Error verifying OTP.");
              }
          } else {
              throw new Error(`Unexpected response status: ${response.status}`);
          }
      } catch (error) {
          console.error("Error verifying OTP:", error);
          alert("An error occurred while verifying OTP. Please try again.");
      }
  };
  
  // Function to send message after OTP verification
  const sendMessage = async (formattedNumber) => {
      try {
          const name = fullName || "User"; // Assuming `fullName` is available in your state
          const url = `https://makemydocuments.nakshatranamahacreations.in/message-insurance.php?mobile=${formattedNumber}&name=${encodeURIComponent(name)}`;
          
          const response = await axios.get(url);
          
          if (response.status === 200) {
              console.log("Message sent successfully:", response.data);
          } else {
              console.error("Error sending message:", response.data);
          }
      } catch (error) {
          console.error("Error while sending message:", error);
          alert("An error occurred while sending the message. Please try again.");
      }
  };
  
    
    
    
    const handleResend = async () => {
      try {
        let formattedNumber = mobileNumber.trim();
        if (!/^91\d{10}$/.test(formattedNumber)) {
          formattedNumber = `91${formattedNumber}`;
        }
    
        console.log("Formatted Mobile Number for Resend:", formattedNumber);
    
        const config = {
          url: "https://makemydocuments.nakshatranamahacreations.in/resendotp.php",
          method: "post",
          data: {
            mobilenumber: formattedNumber,
          },
        };
        const response = await axios(config);
        if (response.status === 200 && response.data.status === "success") {
          console.log("Resend OTP Response:", response.data);
          setResendCountdown();
          alert("OTP resent successfully!");
        } else {
          alert(response.data.message || "Error resending OTP.");
        }
      } catch (error) {
        console.error("Error resending OTP:", error);
        // alert("An error occurred while resending OTP. Please try again.");
      }  finally {
        setIsResending(false); // Stop resending state
      }
    };


    const [selectedDistrict, setSelectedDistrict] = useState("");
    const finishSubmission = () => {
      submitDataToAPI();
      if (mobileNumber) {
        setMaskedNumber(getMaskedMobileNumber(mobileNumber)); 
      }
      setIsCompleted(true);
    };
    const submitDataToAPI = async () => {
      const data = {
        name: fullName || "", 
        mobilenumber: mobileNumber || "", 
        email: emailId || "",
        services: selectedOption || "", 
        address: houseStreetName || "", 
        district: villageTownCity || "",
        date: dob || "", 
        insurance_registration_number: registrationNumber || "",
        paidamount: "0.00",
        // qualification: "", 
        applyingfor:"",
        gender: selectedGender || "", 
        // fathername: fatherName || "", 
        // mothername: motherName || "", 
        pincode: pincode || "", 
        adharnumber: aadharNumber || "", 
        pancard: "", 
        time: "00:00:00", 
        comment: "", 
        status: "",
        service: "Life Insurance",
        followuptime: "00:00:00", 
        // existingpancardnumber: existingPanCardNumber || "", 
        village: villageTownCity || "", 
        "pancard-state": selectedState || "", 
        // "pancard-district": selectedDistrict || "", 
      };
  
      console.log("Data being sent to API:", data); 
  
      try {
        const response = await axios.post(
          "https://makemydocuments.nakshatranamahacreations.in/create.php",
          data,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log("API Response:", response.data); 
        if (response.data.success) {
          alert("Details saved successfully!");
        } else {
        }
      } catch (error) {
        console.error("Error while saving data:", error);
        alert("An error occurred while saving your details. Please try again.");
      }
    };
  



    const faqs = [
        {
            question: "What is life insurance?",
            answer: "A life insurance plan pays your family a certain sum of money as death benefit (as mentioned in the policy) in event of your death while the policy is in force and/or provides returns as maturity proceeds after a set period (called policy term) when the policy terminates; in exchange of a premium.  There are different types of life insurance plans broadly the pure protection or term-life plans and investment plans. In comparison to investment-type life insurance plans, term life plans only get you a death benefit and not any other return. However, the death benefits you get is substantial in comparison, typically 500-1000 times your annual premium. It would take an investment earning 10% interest for more than 65 years – a lifetime - to get a 500X return!  Term insurance is also quite cheap, e.g. for a 30-year-old, a cover of 50 lakhs, costs about Rs. 4,000 per year.",
        },
        {
            question: "What are the types of life insurance?",
            answer: "Life insurance plans are classified into two major types: Pure protection policies or term life plans: Life insurance term plan pays your family the death benefit as mentioned in the policy in case of your death while the policy is in force.  In comparison to investment-type life insurance plans, term life plans only get you a death benefit and not any other return.  Investment policies: Investment-type life insurance plan pays your family a certain sum of money as maturity returns after a set time period (called policy term) or the death benefit in event of your death (while the policy is in force); in exchange of a premium.  Typically maturity periods are ten, fifteen or twenty years upto a certain age limit, usually 65 years. Furthermore, these policies are traditional with-profits or unit linked (ULIP) plans. The death benefit you get is lesser in comparison to pure protection (term insurance) plans, typically 7 -10 times your annual premiue.",
        },
        {
            question: "Why should I buy a life insurance plan?",
            answer: "If you have family members who are dependent on your income, you must buy a life cover (a term-life protection plan at the least) to secure their future in your absence.  Life insurance provides financial protection against several risk-hazards in the life of every person:  That of dying too soon leaving a dependent family without any means of regular income That of living till old age without visible means of support Paying off loans and other expenses like illness or accidents in your absence. Moreover, the death benefit is tax free to your family u/s 10(10D), and premiums get tax exemption u/s 80C.  ",
        },
        {
            question: "What are the tax benefits for life insurance?",
            answer: "Life insurance provides two types of tax benefits:  The premiums you pay for a life insurance policy are eligible for tax deductions upto Rs 1.5 lakhs under Section 80C (to the extent of 10% of sum assured or actual premiums paid whichever is less)  The death benefit (including any accumulated bonuses) received by the nominee is fully tax- free as per section 10 (10 D).  Any maturity proceeds received (other than death benefit) are tax-free provided, the premiums paid in any of the years during the term of the policy do not exceed 10% of the actual Sum Assured.",
        },
        {
            question: "How can your nominee claim the amount in the event of your death?",
            answer: "Once you buy a Life Plan, keep your nominee aware of the latest policy copy. To make the claim, your nominee has to intimate the insurance company and provide necessary documents which will include copy of death certificate, hospital records, if any, identity and bank account proofs.",
        },
        {
            question: "Can the claim be denied for any reason?",
            answer: "Your claim could be denied for any of the following reasons: Policy was not in force, i.e. you had not paid the premium or policy was cancelled for some reasonYou did not fully disclose required information in your insurance application Death cause was in excluded list; currently the only exclusion for life plan is suicide in first 12 months since start of policy",
        },
        {
            question: "What are the types of claims in life insurance policies?",
            answer: "Life insurance policies have the following types of claims - Maturity claim which is paid when the term of the plan comes to an end Death claim which is paid if the insured dies during the term of the plan Money back claims which are payable during the term of the plan if the insured is alive in a money back policy Surrender value claim which is payable if the policy is surrendered by the policyholder before the term completes",
        },
        {
            question: "What is not covered under life insurance plans?",
            answer: "Life insurance plans do not cover death due to the following reasons - When under the influence of alcohol or drugs Acts of criminal nature Participation in hazardous activities Suicide within 12 months of buying the policy or reviving it. Any one or multiple riders can be chosen along with the basic policy.",
        },
        {
          question: "Do life insurance policies provide loans?",
          answer: "Yes, savings oriented life insurance plans, except ULIPs, provide a loan facility. Under this facility, up to 90% of the plan’s surrender value can be taken by the policyholder as loan.",
      },
      {
        question: "What is the process of making a death claim?",
        answer: "The nominee should inform the insurance company about the claim. The death certificate of the insured should be submitted and a claim form should be filled stating the details of the policy. Then the insurance company would check the forms and settle the claim",
    },
    ];

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index); // Toggle the dropdown
    };


    return (
        <div
            className="container-fluid"
            style={{
                background: 'linear-gradient(182.42deg, #FCA505 2.01%, #FFFFFF)',
                minHeight: '60vh',
                paddingTop: '20px',
            }}
        >
            {/* Top Section */}
            <div className="row justify-content-start align-items-center" style={{ minHeight: '40vh' }}>
                {/* Left side: Text content */}
                <div className="col-12 col-md-6 text-left">
                    <div className="text-content">
                        <h1>Protect Your Life Insurance with Ease</h1>
                        <p style={{ fontWeight: '500' }}>Insurance applications made simple and stress-free.</p>
                    </div>
                    <img src={checklistIcon} alt="Checklist Icon" className="img-fluid checklist-icon" />
                </div>

                {/* Right side: Vehicles and Phone Icon */}
                <div className="col-12 col-md-6 text-center">
                    <img src={vehiclesIcon} alt="Vehicles and Phone" className="img-fluid" />
                </div>
            </div>

            {/* Content Section */}
            <div
                className="content-section"
                style={{
                    backgroundColor: '#fffff',
                    padding: '30px 15px',
                    borderRadius: '10px',
                    margin: '14% auto',
                    marginRight:'72%',
                }}
            >
                <div className="row justify-content-center">
                    {/* Main Column for Vertical Layout */}
                    <div className="col-12 col-md-8 position-relative">
                        {/* First Section: Documents */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={documentsIcon}
                                    alt="Documents Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        justifyContent:'center',
                                        alignItems:'center',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div> 
                        </div>
                    
           
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '100%',
                                // margin: '0 auto',
                                width: '4px',
                                marginTop:'-25%',
                                marginLeft:'50%'
                            }}
                        ></div>

                        {/* Second Section: How It Works */}
                        <div className="text-center mb-5">
                            <div style={{ position: 'relative' }}>
                                <img src={circleIcon} alt="Circle Background" className="img-fluid" />
                                <img
                                    src={howIcon}
                                    alt="How It Works Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '45%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
              
                        </div>

                        {/* Blue Line */}
                        <div
                            style={{
                                borderLeft: '3px solid #007BFF',
                                height: '150%',
                                // margin: '0 auto',
                                marginLeft:'49.8%',
                                marginTop:'-25%',
                                width: '4px',
                            }}
                        ></div>

                        {/* Third Section */}
                        <div className="text-center">
                            <div style={{ position: 'relative' }}>
                    
                                <img
                                    src={thirdImage}
                                    alt="Third Section Icon"
                                    style={{
                                        position: 'absolute',
                                        top: '60%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                            </div>
                           
                        </div>
                       
            
                    </div>
                </div>
             <>
                <div className="mb-5"   style={{
                        marginTop: '-38%',
                        marginLeft: '71%',
                    }}>
                     <h4
        style={{
            color: '#007BFF',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Prevents text from wrapping
        }}
    >Documents Required For Life Insurance</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8', whiteSpace: 'nowrap', fontSize:'bold' }}>
                    <li style={{ padding: '0px', marginBottom: '0px' }}>Vaild Id & Address Prof</li>
                    
                    </ul>
                </div>

                {/* How It Works Section */}
                <div style={{marginLeft:'72%',marginTop:'33%'}}>
                    <h4 style={{ color: '#007BFF', fontWeight: 'bold',whiteSpace:'nowrap' }}>How It Works</h4>
                    <ul style={{ listStyleType: 'none', paddingLeft: '20px', lineHeight: '1.8' , whiteSpace:'nowrap' }}>
                    <li style={{padding: '0px', marginBottom: '0px' }}>Register Online</li>
        <li style={{ padding: '0px', marginBottom: '0px'}}>Get Quotation Via E-mail / WhatsApp</li>
        <li style={{ padding: '0px', marginBottom: '0px' }}> Talk To Experts</li>

        <li style={{ padding: '0px', marginBottom: '0px'}}>Compare Policies</li>
        <li style={{ padding: '0px', marginBottom: '0px'}}>Make Payment</li>
        <li style={{ padding: '0px', marginBottom: '0px' }}>Download Your Policy Instantly</li>
                    </ul>
                </div>
                </>
            </div>
     <div>
      {/* Get Quotes Button */}
      <div style={{ textAlign: "center", marginTop: "-10%" }}>
        <button
          style={{
            backgroundColor: "#FCA505",
            color: "#000000",
            padding: "12px 50px",
            border: "none",
            borderRadius: "30px",
            fontWeight: "bold",
            cursor: "pointer",
            fontSize: "16px",
            marginRight:"40%",
            marginTop:"-30%",
          }}
          onClick={() => setShowPopup(true)}
        >
          Get Quotes
        </button>
      </div>

      {/* Modal Popup */}
      {showPopup && (
        <div
          style={{
            position: "fixed",
            top: "100px",
            left: "0",
            width: "100%",
            height: "86%",
            backgroundColor: "rgba(26, 118, 216, 0.9)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "9999",
          }}
        >
          <div
            style={{
              backgroundColor: "#FFFFFF",
              padding: "40px",
              borderRadius: "28px",
              width: "70%",
              maxHeight: "90%", // Maximum height of the popup
              overflowY: "auto", // Enable scrolling if content overflows
              textAlign: "center",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            {/* Stepper */}

            {!isCompleted ? (
              <>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "30px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 1 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "60px",
                    fontWeight: "bold",
                  }}
                >
                  1
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 1</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep >= 2 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  2
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 2</span> */}
              </div>
              <div
                style={{
                  height: "2px",
                  flex: 1,
                  backgroundColor: currentStep >= 3 ? "#4285F4" : "#ccc",
                  alignSelf: "center",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  flex: 1,
                }}
              >
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    backgroundColor: currentStep === 3 ? "#4285F4" : "#ccc",
                    color: "white",
                    borderRadius: "50%",
                    lineHeight: "50px",
                    fontWeight: "bold",
                  }}
                >
                  3
                </div>
                {/* <span style={{ marginTop: "10px", fontSize: "16px" }}>Step 3</span> */}
              </div>
            </div>

            {/* Popup Content Based on Step */}
            <div style={{ marginBottom: "20px" }}>
            {currentStep === 1 && (
  <div style={{ textAlign: "center" }}>
    <p style={{fontWeight:"600"}}>Buy Life insurance, the smart way,</p>

    {/* Heading: I Want to* */}
    <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
      I Want to
      <span style={{ color: "red" }}>*</span>
    </h4>

    {/* Radio Buttons */}
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginLeft:"4%",
        // margin: "30px auto",
        width: "fit-content", 
        gap: "20px", 
      }}
    >
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="TermLifePlans"
          checked={policyOption === "TermLifePlans"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
       Term Life Plans 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="InvestmentandTaxPlanning"
          checked={policyOption === "InvestmentandTaxPlanning"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
      Investment and Tax Planning 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="SavingsForChild"
          checked={policyOption === "SavingsForChild"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
     Savings For Child 
      </label>
      <label style={{ fontSize: "18px", fontWeight: "500", color: "#333" }}>
        <input
          type="radio"
          name="policyOption"
          value="renewExistingPolicy"
          checked={policyOption === "renewExistingPolicy"}
          onChange={(e) => setPolicyOption(e.target.value)}
          style={{ marginRight: "10px" }}
        />
      Pension / retirement 
      </label>
    </div>
    {errors.policyOption && (
      <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
        {errors.policyOption}
      </p>
    )}
  </div>
)}


{currentStep === 2 && (
  <div style={{ textAlign: "center" }}>
    {/* Step 2 Heading */}
    <p>Buy Life Insurance, the smart way</p>

    {/* Subheading */}
    <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
      Enter your Life Insurance details
    </h3>
    {/* Gender Selection */}
    <div style={{ marginBottom: "20px", textAlign: "left" }}>
      <label
        htmlFor="gender"
        style={{
          display: "block",
          marginBottom: "10px",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        Gender
        <span style={{ color: "red" }}>*</span>
      </label>
      <select
        id="gender"
        value={gender}
        onChange={(e) => setGender(e.target.value)}
        style={{
          width: "100%",
          padding: "10px",
          fontSize: "16px",
          border: "1px solid #ccc",
          borderRadius: "4px",
        }}
      >
        <option value="">Select Gender</option>
        <option value="male">Male</option>
        <option value="female">Female</option>
        <option value="other">Other</option>
      </select>
      {errors.gender && (
        <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
          {errors.gender}
        </p>
      )}
    </div>

   {/* Date of Birth */} 
<div style={{ marginBottom: "20px", textAlign: "left" }}>
  <label
    htmlFor="dob"
    style={{
      display: "block",
      marginBottom: "10px",
      fontSize: "16px",
      fontWeight: "500",
    }}
  >
    What is your date of birth? <span style={{ color: "red" }}>*</span>
  </label>
  <input
    type="date"
    id="dob"
    value={dob}
    onChange={(e) => setDob(e.target.value)}
    style={{
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      border: "1px solid #ccc",
      borderRadius: "4px",
    }}
  />
    {errors.dob && (
        <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
          {errors.dob}
        </p>
      )}
</div>

  </div>
)}
{currentStep === 3 && (
      <div style={{ textAlign: "center" }}>
      <p>Buy Insurance, the smart way</p>
  
      <h3 style={{ color: "#007BFF", fontWeight: "600", margin: "20px 0" }}>
        Contact Details
      </h3>
  
      {/* Form Fields */}
      {/* Name */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="name"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Name<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          id="name"
          value={fullName}
          onChange={handleFullNameChange}
          placeholder="Enter Your Name"
          style={{
            width: "100%",
            height: "45px",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        {errors.fullName && (
    <p style={{ color: "red", fontSize: "14px", textAlign:'left' }}>{errors.fullName}</p>
  )}
      </div>
  
      {/* Mobile Number */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="mobileNumber"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Mobile Number<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="text"
          id="mobileNumber"
          value={mobileNumber}
          onChange={handleMobileNumberChange}
          placeholder="Enter Your Mobile Number"
          style={{
            width: "100%",
            height: "45px",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
         {errors.mobileNumber && (
    <p style={{ color: "red", fontSize: "14px" , textAlign:'left'}}>{errors.mobileNumber}</p>
  )}
      </div>
  
      {/* Email ID */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="email"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Email ID<span style={{ color: "red" }}>*</span>
        </label>
        <input
          type="email"
          id="email"
          value={emailId}
          onChange={handleEmailIdChange}
          placeholder="Enter Your Email ID"
          style={{
            width: "100%",
            height: "45px",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
        {errors.emailId && (
    <p style={{ color: "red", fontSize: "14px", textAlign:'left' }}>{errors.emailId}</p>
  )}
      </div>
  
      {/* Address */}
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="address"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Address<span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          id="address"
          value={villageTownCity}
          onChange={handleVillageTownCityChange}
          placeholder="Enter Your Address"
          style={{
            width: "100%",
            height: "70px",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
       {errors.villageTownCity && (
    <p style={{ color: "red", fontSize: "14px", textAlign:'left' }}>{errors.villageTownCity}</p>
  )}
      </div>
  
      <div style={{ marginBottom: "20px", textAlign: "left" }}>
        <label
          htmlFor="mobileNumber"
          style={{
            display: "block",
            marginBottom: "10px",
            fontSize: "16px",
            fontWeight: "500",
          }}
        >
          Pin code<span style={{ color: "red" }}>*</span>
        </label>
        <input
              type="text"
              id="pincode"
              value={pincode}
              onChange={handlePincodeChange}
              placeholder="Enter Pin Code"
          style={{
            width: "100%",
            height: "45px",
            padding: "10px",
            fontSize: "16px",
            border: "1px solid #ccc",
            borderRadius: "4px",
          }}
        />
    {errors.pincode && (
        <p style={{ color: "red", fontSize: "14px", textAlign: "left" }}>
          {errors.pincode}
        </p>
      )}
      </div>

      <div>
        {/* State Dropdown */}
        <div style={{ marginBottom: "20px", textAlign: "left" }}>
          <label
            htmlFor="state"
            style={{
              display: "block",
              marginBottom: "10px",
              fontSize: "16px",
              fontWeight: "500",
            }}
          >
            State<span style={{ color: "red" }}>*</span>
          </label>
          <select
            id="state"
            value={selectedState}
            onChange={handleStateChange}
            style={{
              width: "100%",
              height: "45px",
              fontSize: "16px",
              border: "1px solid #ccc",
              borderRadius: "4px",
            }}
          >
            <option value="">Select State</option>
            {stateData.map((stateObj, index) => (
              <option key={index} value={stateObj.state}>
                {stateObj.state}
              </option>
            ))}
          </select>
          {errors.selectedState && (
    <p style={{ color: "red", fontSize: "14px", textAlign:'left' }}>{errors.selectedState}</p>
  )}
        </div>
  
        {/* District Dropdown */}
        {selectedState && (
          <div style={{ marginBottom: "20px", textAlign: "left" }}>
            <label
              htmlFor="district"
              style={{
                display: "block",
                marginBottom: "10px",
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              District<span style={{ color: "red" }}>*</span>
            </label>
            <select
              id="district"
              value={selectedDistrict}
              onChange={handleDistrictChange}
              style={{
                width: "100%",
                height: "45px",
                fontSize: "16px",
                border: "1px solid #ccc",
                borderRadius: "4px",
              }}
            >
              <option value="">Select District</option>
              {districts.map((district, index) => (
                <option key={index} value={district}>
                  {district}
                </option>
              ))}
            </select>
            {selectedDistrictError && (
              <p style={{ color: "red", fontSize: "14px" }}>{selectedDistrictError}</p>
            )}
          </div>
        )}
      </div>
    </div>
      )}

            </div>

            {/* Stepper Navigation */}
            <div  style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "20px",
                    }} >
       {currentStep > 1 && (
                      <button
                        onClick={prevStep}
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#FCA505",
                          color: "#000000",
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                        }}
                      >
                        Back
                      </button>
                    )}
              {currentStep < 3 ? (
                <button
                onClick={() => {
                  // Call the centralized validation function
                  if (!validStep()) {
                    setError("Please fill all required fields.");
                    return;
                  }
                  setError(""); // Clear any existing errors
                  nextStep(); // Move to the next step
                }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  Next
                </button>
              ) : (
                <button
                onClick={() => {
                  if (!validStep()) {
                    setError("Please fill all required fields.");
                    return;
                  }
                  setError(""); 
                  handleSendOtp(); 
                  finishSubmission();
                }}
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "FCA505",
                    color: "#000000",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  SUBMIT
                </button>
              )} 
            </div>
            </>
            ) : (
              <div style={{ textAlign: "center", padding: "20px" }}>
              {showOtpSection ? (
             <div>
             <h4 style={{ color: "#007BFF", fontWeight: "bold" }}>
               OTP sent on {mobileNumber ? mobileNumber.replace(/.(?=.{4})/g, "*") : ""}
             </h4>
             <div style={{ margin: "20px 0" }}>
               <label style={{ fontWeight: "500", marginBottom: "10px" }}>
                 Enter OTP <span style={{ color: "red" }}>*</span>
               </label>
               <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                 {otp.map((digit, index) => (
                   <input
                     key={index}
                     id={`otp-input-${index}`}
                     type="text"
                     maxLength="1"
                     value={digit}
                     onChange={(e) => handleChange(e.target.value, index)}
                     onKeyDown={(e) => handleBackspace(e, index)}
                     style={{
                       width: "50px",
                       height: "50px",
                       textAlign: "center",
                       fontSize: "18px",
                       border: "1px solid #ccc",
                       borderRadius: "5px",
                     }}
                   />
                 ))}
               </div>
             </div>
           
             <div style={{ marginTop: "20px", textAlign: "center" }}>
           
               <p style={{ fontSize: "14px", color: "#888" }}>
                   {resendCountdown > 0 ? (
                     <>Not Received? Resend in {resendCountdown}s</>
                   ) : (
                     <a
                       href="#"
                       onClick={handleResend}
                       style={{
                         textDecoration: "none",
                         color: isResending ? "#ccc" : "#007BFF",
                         pointerEvents: isResending ? "none" : "auto",
                       }}
                     >
                       Resend OTP
                     </a>
                   )}
                 </p>
             </div>
           
             <button
               onClick={handleVerify}
               style={{
                 backgroundColor: "#FFA500",
                 color: "#000",
                 border: "none",
                 borderRadius: "5px",
                 padding: "10px 20px",
                 fontSize: "16px",
                 cursor: "pointer",
               }}
             >
               VERIFY
             </button>
           </div>
              ) : (
                <div style={{ marginTop: "20px", textAlign: "center" }}>
                {/* Success Message with Checkmark */}
                <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // Optional: light green background
      }}
    >
      <div
        style={{
          position: "relative",
          display: "inline-block",
          width: "80px",
          height: "80px",
          borderRadius: "50%",
          backgroundColor: "#28a745",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          animation: "circleAnimation 1s ease-in-out",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          fill="none"
          stroke="white"
          viewBox="0 0 24 24"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <path
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M20 6L9 17l-5-5"
          />
        </svg>
      </div>
      {/* <span style={{ marginLeft: "15px", color: "#28a745" }}>Success!</span> */}
    </div>
    <br/>
    <p style={{fontSize:'30px'}}> Thank You!</p>
                {/* Confirmation Text */}
                <p>Your Submission has been Received.</p>
                <p>We appreciate your interest and will get back to you as soon as possible.</p>
                {/* {redirecting && <p>Redirecting to homepage...</p>} */}
              </div>
              )}
            </div>
            )}

            {/* Close Button */}
            <button
                onClick={closePopup}
                style={{
                  position: "absolute",
                  top: "25px",
                  left: "8%",
                  background: "#000000",
                  border: "1px solid #FCA505",
                  fontSize: "20px",
                  padding: "8px", 
                  borderRadius: "5px",
                  cursor: "pointer",
                  color: "#fff", 
                }}
              >
                <FaArrowLeft /> 
              </button>
          </div>
        </div>
      )}
    </div>

                       {/* FAQ Section */}
 <div className="faq-section" style={{ margin: '50px auto', padding: '20px', background: '#FFFFFF', borderRadius: '10px', width: '80%' }}>
            <h4 style={{ textAlign: 'center', fontWeight: 'bold', color: '#007BFF', marginBottom: '20px' }}>FAQs</h4>
            <p style={{ textAlign: 'center', fontWeight: '500', marginBottom: '30px' }}>Need help? Contact us for any queries related to us</p>
            <div className="faq-list">
                {faqs.map((faq, index) => (
                    <div key={index} className="faq-item" style={{ marginBottom: '10px' }}>
                        <button
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '100%',
                                padding: '10px 20px',
                                border: '1px solid #007BFF',
                                borderRadius: '5px',
                                background: '#F9F9F9',
                                fontWeight: '500',
                                cursor: 'pointer',
                            }}
                            onClick={() => handleToggle(index)}
                        >
                            {faq.question}
                            <span style={{ fontWeight: 'bold', fontSize: '16px', marginLeft: '10px' }}>
                                {openIndex === index ? '▲' : '▼'}
                            </span>
                        </button>
                        {openIndex === index && (
                            <div
                                style={{
                                    marginTop: '10px',
                                    padding: '10px 20px',
                                    background: '#F3F3F3',
                                    borderRadius: '5px',
                                    color: '#333',
                                }}
                            >
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>

        </div>
    );
};

export default TwoWheeler;
